import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import dayjs from 'dayjs';
import errorStore from 'src/stores/errorStore';
import RaffleService from 'src/services/raffle';
import { getEditorContent } from 'src/utils';

export default class RaffleEventViewModel {
  fileList = [];
  editorState = null;
  @observable isLoaded = false;
  @observable isSaving = false;
  @observable raffleId = null;
  @observable form = {
    isShow: false,
    isDrawn: false,
    title: '',
    dates: [null, null],
    content: undefined,
    defaultFileList: [],
    memo: ''
  };
  @observable key = dayjs().toString();

  constructor() {
    makeObservable(this);
  }

  didMount = async (raffleId) => {
    if (raffleId) {
      try {
        const result = await RaffleService.getOne(raffleId, true);
        const imgs = result.images.map((img, index) => ({
          uid: `default-${index}`,
          name: 'image',
          status: 'done',
          url: img
        }));

        runInAction(() => {
          this.raffleId = raffleId;
          this.form.isShow = result.isShow;
          this.form.isDrawn = result.isDrawn;
          this.form.title = result.title;
          this.form.memo = result.memo;
          this.form.dates = [dayjs(result.startAt), dayjs(result.endAt)];
          this.form.content = result.editorContent;
          this.form.defaultFileList = imgs;
          this.fileList = imgs;
          this.isLoaded = true;
        });

      } catch {
        errorStore.show('發生錯誤，無法取得資料');
        runInAction(() => { this.isLoaded = true; });
      }


    } else {
      runInAction(() => { this.isLoaded = true; });
    }
  };


  create = async () => {
    try {
      const newImages = this.fileList.map((file) => file.originFileObj).filter((file) => !!file);
      const { plainTextContent, editorContent } = this.editorState ? getEditorContent(this.editorState) : {};

      if (this.form.title.length > 50) {
        message.error('標題不得超過50字！');
        return;
      }

      if (!this.form.title
        || !editorContent
        || newImages.length === 0
        || !this.form.dates[0]
        || !this.form.dates[1]
      ) {
        message.error('標題、報名時間、內文及圖片必填！');
        return;
      }

      runInAction(() => { this.isSaving = true; });

      const res = await RaffleService.create({
        title: this.form.title,
        startAt: this.form.dates[0].toISOString(),
        endAt: this.form.dates[1].toISOString(),
        isShow: this.form.isShow,
        isDrawn: this.form.isDrawn,
        memo: this.form.memo,
        images: newImages,
        plainTextContent,
        editorContent
      });

      const imgs = res.images.map((img, index) => ({
        uid: `default-${index}`,
        name: 'image',
        status: 'done',
        url: img
      }));

      runInAction(() => {
        this.raffleId = res.id;
        this.form.defaultFileList = imgs;
        this.fileList = imgs;
        this.key = dayjs().toString();
      });

      window.history.replaceState(null, null, `/backstage/raffle/${res.id}`);

      message.open({
        type: 'success',
        content: '已新增獎勵活動'
      });

    } catch (err) {
      errorStore.show('發生錯誤，無法新增獎勵活動');
    } finally {
      runInAction(() => { this.isSaving = false; });
    }
  };


  update = async () => {
    try {
      const newImages = this.fileList.map((file) => file.originFileObj).filter((file) => !!file);
      const oldImageUrls = this.fileList.map((file) => file.url).filter((file) => !!file);
      const { plainTextContent, editorContent } = this.editorState ? getEditorContent(this.editorState) : {};

      if (this.form.title.length > 50) {
        message.error('標題不得超過50字！');
        return;
      }

      if (!this.form.title
        || (newImages.length === 0 && oldImageUrls.length === 0)
        || !this.form.dates[0]
        || !this.form.dates[1]
      ) {
        message.error('標題、報名時間、內文及圖片必填！');
        return;
      }

      runInAction(() => { this.isSaving = true; });

      const deletedImages = [];
      this.form.defaultFileList.forEach((file) => {
        if (!oldImageUrls.includes(file.url)) {
          deletedImages.push(file.url);
        }
      });

      await RaffleService.update(this.raffleId, {
        title: this.form.title,
        content: this.form.content,
        startAt: this.form.dates[0].toISOString(),
        endAt: this.form.dates[1].toISOString(),
        isShow: this.form.isShow,
        isDrawn: this.form.isDrawn,
        images: newImages,
        memo: this.form.memo,
        deletedImages,
        plainTextContent,
        editorContent
      });

      const res = await RaffleService.getOne(this.raffleId, true);
      const imgs = res.images.map((img, index) => ({
        uid: `default-${index}`,
        name: 'image',
        status: 'done',
        url: img
      }));

      runInAction(() => {
        this.form.defaultFileList = imgs;
        this.fileList = imgs;
        this.key = dayjs().toString();
      });

      message.open({
        type: 'success',
        content: '活動詳情已更新'
      });

    } catch (err) {
      errorStore.show('發生錯誤，無法更新獎勵活動');

    } finally {
      runInAction(() => { this.isSaving = false; });
    }
  };


  onSave = () => {
    if (this.raffleId) {
      this.update();
    } else {
      this.create();
    }
  };

  @action onisShowChange = (checked) => {
    this.form.isShow = checked;
  };
  @action onIsDrawnChange = (checked) => {
    this.form.isDrawn = checked;
  };

  @action onTitleChange = (e) => {
    this.form.title = e.target.value;
  };

  @action onDatesChange = (dates) => {
    this.form.dates = dates;
  };

  handleUploadChange = ({ file, fileList }) => {
    this.fileList = fileList;
  };

  @action onEditorChange = (state) => {
    this.editorState = state;
  };

  @action onMemoChange = (e) => {
    this.form.memo = e.target.value;
  };

}
