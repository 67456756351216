export const Action = {
  UpdateProfile: 'UpdateProfile',
  UpdateUnreadCount: 'UpdateUnreadCount'
};

export const actions = (dispatch) => ({
  updateProfile: (profile) => dispatch({
    type: Action.UpdateProfile,
    payload: profile
  }),
  updateUnreadCount: (count) => dispatch({
    type: Action.UpdateUnreadCount,
    payload: count
  })
});
