import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { Input, Button, message } from 'antd';

import useAnchorAPI from 'src/hooks/useAnchorAPI';
import useDidMount from 'src/hooks/useDidMount';
import MessageService from 'src/services/message';
import Box from 'src/components/MessageBox';
import { ReactComponent as MessageIcon } from 'src/assets/message.svg';

import styles from './styles.module.scss';

const Limit = 20;

const Message = (props) => {
  const { userId } = props;

  const [keywordInput, setKeywordInput] = useState('');
  const [keyword, setKeyword] = useState('');

  const navigate = useNavigate();

  const { next, list, loading } = useAnchorAPI(
    (anchor) => MessageService.getBackstageBoxes(userId, keyword, Limit, anchor),
    (reset, prev, result) => {
      if (reset) { return result.next; }
      if (result.newest.length) {
        return [...prev, ...result.next, ...result.newest]
          .sort((a, b) => a.lastMessage.createdAt - b.lastMessage.createdAt > 0 ? -1 : 1);
      }
      return [...prev, ...result.next];
    },
    (err) => {
      message.open({ type: 'error', content: '發生錯誤，請稍後再試' });
    }
  );

  const onKeywordChange = useCallback((ev) => setKeywordInput(ev.target.value), []);
  const onSearchClick = useCallback(
    () => {
      setKeyword(keywordInput);

      // ! Note: make sure keyword is updated before calling next
      requestAnimationFrame(() => {
        next(true);
      });
    },
    [keywordInput, next]
  );
  const onKeywordInputKeyDown = useCallback(
    (ev) => {
      if (ev.key === 'Enter') {
        onSearchClick();
      }
    },
    [onSearchClick]
  );
  const computeItemKey = useCallback((idx, item) => item.id, []);
  const onBoxClick = useCallback((item) => navigate(`/message/${item.id}`), [navigate]);
  const renderBox = useCallback(
    (idx, item) => (
      <Box
        className={styles.box}
        item={item}
        isLast={idx === list.length - 1}
        onClick={onBoxClick}
      />
    ),
    [list, onBoxClick]
  );

  useDidMount(next);

  return (
    <div className={styles.tab}>
      <div className={styles.actions}>
        <Input
          className={styles.keyword}
          placeholder="請輸入出/認養人姓名"
          onChange={onKeywordChange}
          onKeyDown={onKeywordInputKeyDown}
          value={keywordInput}
        />
        <Button
          className={styles.search}
          size="large"
          onClick={onSearchClick}
        >
          搜尋
        </Button>
      </div>

      {
        loading || list.length ? (
          <Virtuoso
            className={styles.list}
            useWindowScroll
            data={list}
            endReached={() => next()}
            computeItemKey={computeItemKey}
            itemContent={renderBox}
          />
        ) : (
          <div className={styles.empty}>
            <div className={styles.iconWrapper}>
              <MessageIcon className={styles.icon} />
            </div>
            <div className={styles.hint}>沒有留言 ~</div>
          </div>
        )
      }
    </div>
  );
};

Message.propTypes = {
  userId: PropTypes.string.isRequired
};

export default Message;
