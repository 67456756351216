import { message } from 'antd';
import Cookies from 'js-cookie';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { Router, VerificationType } from 'src/constants';
import UserService from 'src/services/user';
import infoStore from 'src/stores/infoStore';
import { passwordValidator } from 'src/utils';

export default class ResetPwdVM {
  @observable isLoading = false;
  @observable password;
  @observable passwordConfirm;
  router;

  constructor(router) {
    makeObservable(this);
    this.router = router;
  }

  didMount = () => {};


  resetPwd = async () => {
    const isValid = passwordValidator(this.password);
    if (!isValid) {
      infoStore.show({
        message: '密碼至少八碼，英文字母及數字至少各一碼',
        type: 'warn'
      });
      return;
    }


    if (this.password !== this.passwordConfirm) {
      message.error('密碼不一致，請重新確認');
      return;
    }

    const token = Cookies.get('reset-password');
    if (!token) {
      infoStore.show({
        message: '驗證碼已過期，請重新驗證',
        type: 'warn'
      });
      this.router.navigate(`${Router.Auth.Verify}?type=${VerificationType.RestPwd}`);
      return;
    }

    try {
      runInAction(() => { this.isLoading = true; });
      await UserService.resetPasswordWithSms(token, this.password);
      runInAction(() => { this.isLoading = false; });

      message.success('密碼重設成功！');
      this.router.navigate(Router.Auth.Login);


    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      let _message;
      switch (err?.response?.status) {
        case 404:
          _message = '帳號不存在';
          break;
        case 401:
          _message = '驗證碼無效，請重新驗證';
          this.router.navigate(`${Router.Auth.Verify}?type=${VerificationType.RestPwd}`);
          break;
        case 400:
          _message = '帳號至少八碼，英文字母及數字至少各一碼';
          break;
        default:
          _message = '發生錯誤';
      }
      infoStore.show({ message: _message, type: 'warn' });
    }
  };

  @action onPasswordChange = (e) => {
    this.password = e.target.value;
  };
  @action onPasswordConfirmChange = (e) => {
    this.passwordConfirm = e.target.value;
  };
}
