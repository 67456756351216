import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import ApplicationService from 'src/services/application';
import infoStore from 'src/stores/infoStore';

export default class ApplicationListVM {
  limit = 40;
  anchor = null;
  @observable list = [];
  @observable isLoading = false;
  @observable isPageLoading = false;

  constructor() {
    makeObservable(this);
  }

  @computed get hasCat() {
    return this.list.length > 0;
  }

  didMount = async () => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      const { list, anchor } = await ApplicationService.getMyApplication({
        limit: this.limit
      });

      runInAction(() => {
        this.list = list;
        this.anchor = anchor;
        this.isPageLoading = false;
      });
    } catch (err) {
      runInAction(() => { this.isPageLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };


  onNextPage = async () => {
    if (this.isLoading || !this.anchor) return;

    runInAction(() => { this.isLoading = true; });

    try {
      const { list, anchor } = await ApplicationService.getMyApplication({
        anchor: this.anchor,
        limit: this.limit
      });

      runInAction(() => {
        this.list = this.list.concat(list);
        this.anchor = anchor;
        this.isLoading = false;
      });
    } catch {
      runInAction(() => { this.isLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };
}
