import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import cn from 'classnames';
import styles from './TabBar.module.scss';

function TabBar(props) {
  const {
    items,
    onChange = () => {},
    activeTab
  } = props;

  const [activeKey, setActiveKey] = useState(items?.[0]?.key ?? null);

  return (
    <div className={styles.tab}>
      <div className={styles.line} />
      <div className={styles.box}>
        {items.map((item) => (
          <Button
            key={item.key}
            type="text"
            className={cn(styles.item, { [styles.active]: item.key === (activeTab || activeKey) })}
            onClick={() => {
              setActiveKey(item.key);
              onChange(item.key);
            }}
          >
            {item.label}
          </Button>
        ))}
      </div>
    </div>
  );
}

TabBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  activeTab: PropTypes.string
};

export default TabBar;
