import { useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppStateContext } from 'src/stores';
import UserService from 'src/services/user';
import useDidMount from 'src/hooks/useDidMount';
import { Router } from 'src/constants';

export default function useProfile(isRequired = true, isAdmin = false) {
  const { state, actions } = useContext(AppStateContext);
  const mountRef = useRef(false);

  const navigate = useNavigate();

  useDidMount(async () => {
    try {
      if (mountRef.current) {
        return;
      }

      const p = await UserService.profile();
      actions.updateProfile(p);

      if (isAdmin && !p.isAdmin) {
        navigate(Router.Client.Home);
      }
    } catch (err) {
      actions.updateProfile(null);
      UserService.logout();

      if (isRequired) {
        navigate(Router.Auth.Login);
      }

    } finally {
      mountRef.current = true;
    }
  });

  return state.profile;
}
