import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table, Input, Select, Button } from 'antd';
import dayjs from 'dayjs';

import {
  Router,
  TaiwanCountyTxt,
  ObservationFrequencyTxt,
  StabilityFrequencyTxt
} from 'src/constants/index';
import { parseDate } from 'src/utils';
import withProfile from 'src/components/withProfile';
import ReportViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class ReportPage extends React.Component {
  constructor() {
    super();
    this.vm = new ReportViewModel();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  columns = [
    {
      title: '貓咪名字',
      key: 'name',
      render: (data) => data.name
    },
    {
      title: '出養人',
      key: 'fosterPerson',
      render: (data) => data.fosterPerson
    },
    {
      title: '認養人',
      key: 'adopter',
      render: (data) => data.adopter
    },
    {
      title: '地區',
      key: 'region',
      width: 75,
      render: (data) => TaiwanCountyTxt[data.region] ?? ''
    },
    {
      title: '觀察期回報頻率',
      key: 'ObservationFrequency',
      width: 130,
      render: (data) => ObservationFrequencyTxt[data.observationFrequency] ?? '-'
    },
    {
      title: '穩定期回報頻率',
      key: 'stabilityReportFrequency',
      width: 130,
      render: (data) => StabilityFrequencyTxt[data.stabilityFrequency] ?? '-'
    },
    {
      title: '最近更新時間',
      key: 'lastReportingAt',
      width: 150,
      render: (data) => {
        const isLate = !!data.nextReportingAt && dayjs(data.lastReportingAt).isAfter((dayjs(data.nextReportingAt)));
        return <div>{`${parseDate(data.lastReportingAt, '-')}${isLate ? '已遲交' : ''}`}</div>;
      }
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      render: (data) => (
        <Button
          type="link"
          href={`${Router.Client.Report_ROOT}/${data.id}`}
          target="_blank"
          className={styles.link}
        >
          查看回報
        </Button>
      )
    }
  ];

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.header}>幸福回報管理</div>

        <div className={styles.search}>
          <div className={styles.flex}>
            <div className={styles.title}>地區：</div>
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="請選擇"
              allowClear
              style={{ minWidth: 300 }}
              onChange={this.vm.onRegionChange}
              options={
                Object.keys(TaiwanCountyTxt).map((TaiwanCounty) => (
                  { value: TaiwanCounty, label: TaiwanCountyTxt[TaiwanCounty] }
                ))
              }
            />
          </div>

          <Input.Search
            placeholder="請輸入案件編號、認養人姓名或貓咪名字"
            enterButton="搜尋"
            className={styles.input}
            onSearch={this.vm.onKeywordSearch}
          />
        </div>

        <Table
          columns={this.columns}
          dataSource={this.vm.pager.section}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: this.vm.pager.limit,
            total: this.vm.pager.total,
            position: ['topRight'],
            showSizeChanger: true,
            pageSizeOptions: ['20', '50', '100'],
            onShowSizeChange: this.vm.pager.onLimitChange
          }}
          onChange={this.vm.pager.onPageChange}
          scroll={{ y: 'calc(100vh - 248px)', x: 1280 }}
        />
      </div>
    );
  }
}

ReportPage.propTypes = {

};

export default withProfile(ReportPage, true, true);
