import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Card from 'src/components/Card';
import { Router } from 'src/constants';
import { parseDate, intersectionObserverOption } from 'src/utils';

import styles from '../styles.module.scss';

@observer
class NewsTab extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.props.vm.onNewsNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.observer.observe(this.ref.current);
  }

  render() {
    return (
      <div className={styles.card_box}>
        {this.props.vm.news.list.map((n) => (
          <Card
            key={n.id}
            isImage3to2
            to={`${Router.Client.NewsItem_ROOT}/${n.id}`}
            image={n?.images?.[0]}
            title={n?.title ?? ''}
            content={(
              <div className={styles.card_content}>
                {n?.plainTextContent ?? ''}
              </div>
                    )}
            footer={(
              <div className={styles.row}>
                <div className={styles.date}>{parseDate(n.showAt, '', true)}</div>
                <div className={cn(styles.tag, styles.tagNews)}>繼續閱讀</div>
              </div>
                    )}
          />
        ))}
        <div ref={this.ref} style={{ height: 10 }} />
      </div>
    );
  }
}

NewsTab.propTypes = {
  vm: PropTypes.object.isRequired
};

export default NewsTab;
