import React from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import Loading from 'src/components/Loading';
import HomeVM from './viewModel';
import BannerSection from './components/BannerSection';
import InfoSection from './components/InfoSection';

@observer
class Home extends React.Component {
  constructor() {
    super();
    this.vm = new HomeVM();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>喵加人認養媒合平台</title>
          <link rel="canonical" href="https://meowohana.com/" />
        </Helmet>

        {this.vm.isPageLoading
          ? <Loading />
          : (
            <>
              <BannerSection vm={this.vm} />
              <InfoSection vm={this.vm} />
            </>
          )}
      </>
    );
  }
}

Home.propTypes = {

};

export default Home;
