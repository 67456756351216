import React, { lazy, Suspense } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import Loading from 'src/components/Loading';
import FaqVM from './viewModel';

import styles from './styles.module.scss';

const Editor = lazy(() => import('src/components/Editor'));

@observer
class FAQ extends React.Component {
  constructor() {
    super();
    this.vm = new FaqVM();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>常見問題</title>
          <meta name="description" content="This is 常見問題 です。" />
          <link rel="canonical" href="https://meowohana.com/faq" />
        </Helmet>

        {this.vm.isPageLoading
          ? <Loading />
          : (
            <div className={styles.main}>
              <div className={styles.box}>
                {this.vm.isLoaded && (
                <div className={clsx(styles.editorBox, 'editor-shell')}>
                  <Suspense>
                    <Editor
                      initialEditorState={this.vm.content}
                      onChange={() => {}}
                      editable={false}
                    />
                  </Suspense>
                </div>
                ) }
              </div>
            </div>
          )}
      </>
    );
  }
}

FAQ.propTypes = {

};

export default FAQ;
