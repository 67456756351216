import { makeObservable, observable, action, runInAction } from 'mobx';
import { message } from 'antd';

import AntdTablePager from 'src/models/AntdTablePager';
import NewsService from 'src/services/news';
import errorStore from 'src/stores/errorStore';
import { backstageConfirmStore } from 'src/stores/confirmStore';
import { getEditorContent } from 'src/utils';

export default class NewsViewModel {
  fileList = [];
  editorState = null;

  pager;

  @observable modal = {
    isShow: false,
    data: null,
    isSaving: false
  };

  @observable form = {
    title: '',
    content: undefined,
    image: null,
    defaultFileList: [],
    deletedImages: null
  };

  constructor() {
    makeObservable(this);

    this.pager = new AntdTablePager(this.query, 20);
  }

  didMount = async () => {
    await this.pager.next(true);
  };

  @action openModel = (data) => {
    this.modal = { isShow: true, data };
    this.editorState = null;

    if (data) {
      this.form.title = data.title;
      this.form.content = data.editorContent || undefined;

      const images = data.images.map((image, index) => ({
        uid: index,
        name: 'image',
        status: 'done',
        url: image
      }));
      this.form.defaultFileList = images;
      this.fileList = images;
    }
  };

  @action closeModel = () => {
    this.modal = {
      isShow: false,
      data: null,
      isSaving: false
    };
    this.form = {
      title: '',
      content: undefined,
      image: null,
      defaultFileList: [],
      deletedImages: null
    };
    this.fileList = [];
  };

  @action onTitleChange = (e) => {
    this.form.title = e.target.value;
  };

  @action handleUploadChange = ({ file, fileList }) => {
    this.fileList = fileList;
  };

  @action onEditorChange = (state) => {
    this.editorState = state;
  };

  query = async (limit, anchor) => {
    try {
      const { list, anchor: nextAnchor } = await NewsService.getList({ limit, anchor, isBackstage: true });
      return { list, anchor: nextAnchor };

    } catch (err) {
      errorStore.show('發生錯誤，無法取得資訊');
      return { list: [], anchor: null };
    }
  };


  create = async () => {
    try {
      const newImages = this.fileList.map((file) => file.originFileObj).filter((file) => !!file);
      const { plainTextContent, editorContent } = this.editorState ? getEditorContent(this.editorState) : {};

      if (!this.form.title
        || !editorContent
        || newImages.length === 0
      ) {
        message.error('標題、內文及圖片必填！');
        return;
      }

      runInAction(() => { this.modal.isSaving = true; });

      const res = await NewsService.create({
        title: this.form.title,
        plainTextContent,
        editorContent,
        images: newImages
      });

      this.pager.list.unshift(res);
      this.closeModel();

    } catch (err) {
      errorStore.show('發生錯誤，無法新增最新消息');
      this.closeModel();
    }
  };

  update = async () => {
    try {
      const newImages = this.fileList.map((file) => file.originFileObj).filter((file) => !!file);
      const oldImageUrls = this.fileList.map((file) => file.url).filter((file) => !!file);

      if (!this.form.title
        || (newImages.length === 0 && oldImageUrls.length === 0)
      ) {
        message.error('標題、內文及圖片必填！');
        return;
      }

      runInAction(() => { this.modal.isSaving = true; });

      const deletedImages = [];
      this.form.defaultFileList.forEach((file) => {
        if (!oldImageUrls.includes(file.url)) {
          deletedImages.push(file.url);
        }
      });

      const { plainTextContent, editorContent } = this.editorState ? getEditorContent(this.editorState) : {};

      const res = await NewsService.update(this.modal.data.id, {
        title: this.form.title,
        plainTextContent,
        editorContent,
        images: newImages,
        deletedImages
      });

      this.pager.update(res.id, res);

      message.open({
        type: 'success',
        content: '最新消息已更新'
      });
      this.closeModel();

    } catch (err) {
      errorStore.show('發生錯誤，無法更新最新消息');
      this.closeModel();
    }
  };

  action = async () => {
    if (this.modal.data) return this.update();
    return this.create();
  };


  delete = (id) => {
    backstageConfirmStore.show({
      message: '確定刪除？',
      callBack: async () => {
        try {
          await NewsService.delete(id);
          this.pager.remove(id);
        } catch {
          errorStore.show('發生錯誤，無法刪除最新消息');
        }
      }
    });
  };

  toggle = async (data, isShow) => {
    try {

      await NewsService.toggleIsShow(data.id, isShow);

      if (isShow) {
        this.pager.update(data.id, { ...data, isShow, showAt: Date.now() });
      } else {
        this.pager.update(data.id, { ...data, isShow });
      }

    } catch {
      errorStore.show('發生錯誤，無法更新最新消息裝態');
    }
  };
}
