import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import withRouter from 'src/components/withRouter';
import { Router } from 'src/constants';

import styles from './styles.module.scss';

const ErrorPage = (props) => {
  const error = useRouteError();

  return (
    <div className={styles.page}>
      <div className={styles.main}>
        {isRouteErrorResponse(error)
          ? '404 - 頁面不存在'
          : (
            <div className={styles.col}>
              發生錯誤
              <Button
                className={styles.btn}
                onClick={() => props.router.navigate(Router.Client.Home)}
              >
                返回首頁
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(ErrorPage);
