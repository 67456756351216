import dayjs from 'dayjs';
import Papa from 'papaparse';
import isMobile from 'ismobilejs';
import { $getRoot } from 'lexical';
import Config from 'src/config';
import { request, getHost } from 'src/services/api';
import AuthService from 'src/services/auth';

export const intersectionObserverOption = {
  root: null,
  rootMargin: '0px',
  threshold: 0.9
};

export const parseDate = (date, fallback = '', dateOnly = false) => {
  return date ? dayjs(date).format(dateOnly ? 'YYYY/MM/DD' : 'YYYY/MM/DD HH:mm') : fallback;
};

export const parseMinguoDate = (date) => {
  if (!date) return '-';
  const _date = dayjs(date).format('YYYY-MM-DD');
  const [year, month, day] = _date.split('-');

  return `${year - 1911}年${month}月${day}日`;
};

export const parseAge = (date) => {
  return date ? dayjs().diff(dayjs(date), 'year') : '-';
};

/**
 * for antd Upload component, customRequest attribute
 */
export const customUploadRequest = (params) => {
  setTimeout(() => {
    params.onSuccess('ok');
  }, 0);
};

/**
 *
 * for antd Upload component, getValueFromEvent attribute
 */
export const normFile = (e) => {
  if (Array.isArray(e)) return e;
  return e && e.fileList;
};

/**
 *
 * @returns {Object} promise object
 */
export const checkImgFileSize = (file, targetWidth, targetHeight) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement('img');
      image.src = _loadedImageUrl;
      const fileSize = file.size / 1024 / 1024; // MB


      image.addEventListener('load', () => {
        const { width, height } = image;
        if (width === targetWidth && height === targetHeight && fileSize < 1) {
          resolve();
        } else {
          reject();
        }
      });
    });
  });
};

/**
 *
 * @param {Object} file file object
 * @returns {string} file's url
 */
export const readFileAsUrl = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const url = reader.result;
      resolve(url);
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
  });
};

/**
*
* array to csv file
* @param {Object[]} list
*/
export const downloadFile = (list, fileName = null) => {

  const downloadList = list.length === 0 ? [{ 尚無資料: '' }] : list;

  const csv = Papa.unparse(downloadList, Config.csv.export);

  const element = document.createElement('a');
  const file = new Blob(['\ufeff', csv], { type: 'text/csv' });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.csv` || 'export.csv';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};


/**
 *
 * get plain text and stringified version of the EditorState
 * @param {Object} state Lesical Editor State
 */
export const getEditorContent = (state) => {
  return ({
    plainTextContent: state.read(() => $getRoot().getTextContent()).replace(/(?:\\[rn]|[\r\n]+)+/g, ''),
    editorContent: JSON.stringify(state.toJSON())
  });
};



export const passwordValidator = (value) => {
  const passwordRex = /^(?=.*?[A-Za-z])(?=.*?[0-9])(?!.*?[#?!@$ %^&*-]).{8,}$/;
  return passwordRex.test(value);
};


export const resizeImageApi = async (file) => {
  const formData = new FormData();
  formData.append('image', file);

  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/img-resize`,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${AuthService.getToken()}`
    },
    data: formData,
    responseType: 'blob'
  };

  const res = await request(options);
  return res.data;
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const isIphone = () => {
  const userAgent = isMobile(window.navigator);
  return userAgent.apple.phone || userAgent.apple.tablet || userAgent.apple.ipod;
};

export const resizeImg = async (file) => {
  const isNeedResize = isIphone();

  if (isNeedResize) {
    const buffer = await resizeImageApi(file);
    const _file = new File([buffer], file.name, { type: file.type });
    return _file;
  }

  return file;
};

export const isNumber = (value) => {
  return typeof value === 'number';
};

export const isValidUrl = (string) => {
  try {
    const url = new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export const group = (arr, cnt) => Array.from({ length: Math.ceil(arr.length / cnt) }, (_, i) => arr.slice(i * cnt, i * cnt + cnt));
