import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import Cookies from 'js-cookie';
import { message } from 'antd';
import { Router, VerificationType } from 'src/constants';
import infoStore from 'src/stores/infoStore';
import UserService from 'src/services/user';


export default class RegisterVM {
  form;
  router;
  avatar;
  @observable isChecked = false;
  @observable isLoading = false;

  constructor(form, router) {
    makeObservable(this);
    this.form = form;
    this.router = router;
  }

  didMount = () => {
    const phone = this.router?.location?.state?.phone ?? null;
    this.form.setFieldsValue({ phone });

  };

  register = async () => {
    const token = Cookies.get(VerificationType.Register);
    if (!token) {
      infoStore.show({
        message: '驗證碼已過期，請重新驗證',
        type: 'warn'
      });
      this.router.navigate(`${Router.Auth.Verify}?type=${VerificationType.Register}`);
      return;
    }

    if (!this.isChecked) {
      message.error('請閱讀並同意服務條款');
      return;
    }

    try {
      await this.form.validateFields();
    } catch {
      message.open({ type: 'error', content: '必填欄位未填' });
      return;
    }

    try {
      runInAction(() => { this.isLoading = true; });
      const profile = this.form.getFieldsValue();

      await UserService.register({
        token,
        password: profile.password,
        name: profile.name,
        nickname: profile.nickname,
        region: profile.region,
        email: profile.email,
        contactNumber: profile.contactNumber,
        idNumber: profile.idNumber,
        avatar: this.avatar
      });

      runInAction(() => { this.isLoading = false; });
      message.open({ type: 'success', content: '註冊成功' });
      this.router.navigate(Router.Auth.Login);

    } catch (err) {
      switch (err?.response?.status) {
        case 401:
          infoStore.show({
            message: '驗證碼無效，請重新驗證',
            type: 'warn'
          });
          this.router.navigate(`${Router.Auth.Verify}?type=${VerificationType.Register}`);
          break;
        case 409:
          infoStore.show({
            message: '帳號已存在',
            type: 'warn'
          });
          break;
        default:
          infoStore.show({
            message: '發生錯誤',
            type: 'warn'
          });
      }
      runInAction(() => { this.isLoading = false; });
    }
  };

  @action handleUploadChange = ({ file }) => {
    this.avatar = file.originFileObj;
  };
  @action onCheckChange = (e) => {
    this.isChecked = e.target.checked;
  };
}
