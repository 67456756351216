import { useEffect, useRef } from 'react';

import is from '../utils/is';

function useDidMount(callback) {
  const mountRef = useRef(false);
  const callbackRef = useRef(callback);

  callbackRef.current = callback; // keep latest

  useEffect(
    () => {
      if (is.func(callbackRef.current) && !mountRef.current) {
        mountRef.current = true;
        callbackRef.current();
      }
    },
    []
  );
}

export default useDidMount;
