import { makeObservable, action, observable, runInAction, computed } from 'mobx';
import axios, { CanceledError } from 'axios';
import { saveAs } from 'file-saver';

import MessageService from 'src/services/message';

export default class Attachment {
  userId = null;
  /**
   * from api (for update file)
   *
   * @type {string | null}
   */
  id = null;
  /**
   * plain file
   *
   * @type {File}
   */
  file = null;
  /**
   * editmode -> dataURL for preview (image only)
   * viewmode -> url for download
   */
  url = null;
  /**
   * for abort axios
   */
  abortCtrl = null;
  /**
   * clear button
   */
  clearable = false;
  /**
   * 是否可點擊 (下載 or preview)
   */
  clickable = false;


  /**
   * 'pending' | 'uploading' | 'done' | 'error'
   *
   * @type {string}
   */
  @observable status = 'pending';

  @computed get key() {
    if (this.file) { return this.file.name; }
    if (this.url) { return this.url; }
    return this.id ?? '-';
  }

  @computed get name() {
    if (this.file) { return this.file.name; }
    if (this.url) { return this.url.split('/').pop(); }
    return '-';
  }

  @computed get loading() {
    return this.status === 'uploading';
  }

  constructor(params) {
    this.userId = params.userId;
    this.id = params.id;
    this.file = params.file;
    this.url = params.url;
    this.status = params.status ?? 'pending';
    this.clearable = params.clearable ?? false;
    this.clickable = params.clickable ?? false;

    makeObservable(this);
  }

  start = async () => {
    if (!this.userId || this.status !== 'pending') {
      return; // do nothing
    }

    try {
      runInAction(() => {
        this.status = 'uploading';
      });

      /**
       * get presigned urls
       *
       * [{ id: string, url: string }]
       */
      this.abortCtrl = new AbortController();
      const [meta] = await MessageService.getUploadUrls(
        this.abortCtrl.signal,
        this.userId,
        [{
          name: encodeURIComponent(this.file.name),
          size: this.file.size,
          mimetype: this.file.type
        }]
      );
      this.id = meta.id;

      /**
       * upload to gcs
       */
      this.abortCtrl = new AbortController();
      await axios({
        signal: this.abortCtrl.signal,
        method: 'put',
        url: meta.url,
        headers: {
          'Content-Type': this.file.type,
          'X-Upload-Content-Length': this.file.size
        },
        withCredentials: false,
        data: this.file
      });

      runInAction(() => {
        this.status = 'done';
      });
    } catch (err) {
      console.log('error', err.message ?? err.response?.data);
      if (!(err instanceof CanceledError)) { // ignore canceled error
        runInAction(() => {
          this.status = 'error';
        });
      }
    }
  };

  @action stop = () => {
    if (this.status !== 'uploading') { return; }
    this.status = 'pending';
    this.abortCtrl?.abort();
  };

  download = () => {
    if (!this.clickable) { return; }
    saveAs(this.url, this.name);
  };

  /**
   * @param {string} userId
   * @param {File} file
   * @param {string} dataURL for image preview (image only)
   */
  static editmode(userId, file, dataURL = null) {
    return new Attachment({ userId, file, url: dataURL, clearable: true });
  }

  static viewmode(url) {
    return new Attachment({ url, status: 'done', clickable: true });
  }
}
