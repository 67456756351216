import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tabs, Button, Upload, Input, Switch, DatePicker } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import { customUploadRequest } from 'src/utils';
import { Router } from 'src/constants';
import RaffleEventViewModel from './viewModel';
import styles from './styles.module.scss';

const Editor = lazy(() => import('src/components/Editor'));

@observer
class RaffleEventPage extends React.Component {
  constructor() {
    super();
    this.vm = new RaffleEventViewModel();
  }

  componentDidMount() {
    const raffleId = this.props.router.params.raffleId;
    this.vm.didMount(raffleId);
  }

  renderBreadcrumbItem = (item) => {
    return item.url ? (
      <Link to={item.url}>{ item.title }</Link>
    ) : (<span>{ item.title }</span>);
  };

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.header}>
          <Breadcrumb
            items={[
              { title: '獎勵活動管理', url: Router.Backstage.Raffle },
              { title: '活動詳情' }
            ]}
            itemRender={this.renderBreadcrumbItem}
          />
          <div className={styles.titleBox}>
            <div className={styles.title}>活動詳情</div>
            <Button
              type="primary"
              onClick={this.vm.onSave}
              loading={this.vm.isSaving}
            >
              <SaveOutlined />
              儲存
            </Button>
          </div>
        </div>


        <Tabs
          defaultActiveKey="1"
          centered
          items={[
            {
              key: '1',
              label: '獎勵活動內容設定',
              children: (
                <div className={styles.tabContainer}>
                  <Switch
                    checkedChildren="發布"
                    unCheckedChildren="隱藏"
                    checked={this.vm.form.isShow}
                    onChange={(checked) => this.vm.onisShowChange(checked)}
                  />
                  <div className={styles.spacer} />

                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <div className={styles.formTitle}>開獎狀態：</div>
                    <Switch
                      checkedChildren="已開獎"
                      unCheckedChildren="未開獎"
                      checked={this.vm.form.isDrawn}
                      onChange={(checked) => this.vm.onIsDrawnChange(checked)}
                    />
                  </div>

                  <div className={styles.formTitle}>活動標題：</div>
                  <Input
                    value={this.vm.form.title}
                    onChange={this.vm.onTitleChange}
                  />
                  <div className={styles.spacer} />

                  <div className={styles.formTitle}>報名時間：</div>
                  <DatePicker.RangePicker
                    value={this.vm.form.dates}
                    onChange={this.vm.onDatesChange}
                  />
                  <div className={styles.spacer} />

                  <div className={styles.formTitle}>上傳圖片：</div>
                  <div className={styles.extra}>建議圖片比例：3*2，以5張為上限</div>
                  {this.vm.isLoaded && (
                    <Upload
                      listType="picture-card"
                      customRequest={customUploadRequest}
                      accept="image/*"
                      maxCount={5}
                      onChange={this.vm.handleUploadChange}
                      defaultFileList={this.vm.form.defaultFileList}
                      key={this.vm.key}
                    >
                      <div>Upload</div>
                    </Upload>
                  )}
                  <div className={styles.spacer} />

                  <div className={styles.formTitle}>內文：</div>
                  {this.vm.isLoaded && (
                    <Suspense>
                      <Editor
                        initialEditorState={this.vm.form.content}
                        onChange={this.vm.onEditorChange}
                        height={600}
                        isTextOnly
                      />
                    </Suspense>
                  )}

                </div>
              )
            },
            {
              key: '2',
              label: '備註',
              children: <Input.TextArea
                showCount
                maxLength={1000}
                rows={30}
                value={this.vm.form.memo}
                onChange={this.vm.onMemoChange}
              />
            }
          ]}
        />
      </div>
    );
  }
}







RaffleEventPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(withProfile(RaffleEventPage, true, true));
