import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import Loading from 'src/components/Loading';
import TabBar from 'src/components/TabBar';
import withRouter from 'src/components/withRouter';

import EventsVM from './viewModel';
import NewsTab from './components/NewTab';
import EventTab from './components/EventTab';
import styles from './styles.module.scss';

@observer
class EventsPage extends React.Component {
  constructor() {
    super();
    this.vm = new EventsVM();
  }

  componentDidMount() {
    this.vm.didMount(this.props.router);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>最新活動</title>
          <link rel="canonical" href="https://meowohana.com/events" />
        </Helmet>

        {this.vm.isPageLoading
          ? <Loading />
          : (
            <div className={styles.main}>
              <div className={styles.box}>

                <TabBar
                  activeTab={this.vm.activeTab}
                  onChange={this.vm.onTabChange}
                  items={[
                    { key: 'news', label: '公告訊息' },
                    { key: 'campaigns', label: '獎勵活動' }
                  ]}
                />

                {this.vm.activeTab === 'news' && <NewsTab vm={this.vm} />}
                {this.vm.activeTab === 'campaigns' && <EventTab vm={this.vm} />}

              </div>
            </div>
          )}
      </>
    );
  }
}

EventsPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(EventsPage);
