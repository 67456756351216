import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function Completeness(props) {

  const getText = (percentage) => {
    if (percentage >= 85) return '超完整！';
    if (percentage >= 60) return '完整';
    return '尚未完成';
  };
  const getColor = (percentage) => {
    if (percentage >= 85) return '#00A5B8';
    if (percentage >= 60) return '#D97706';
    return '#B91C1C';
  };

  const color = getColor(props.percentage);

  return (
    <div className={styles.main}>
      <div className={styles.left}>
        資料完整指數
        <span className={styles.percentage} style={{ color }}>{props.percentage}</span>
        %
      </div>

      <div className={styles.right} style={{ color }}>
        <svg width="16" height="14" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 6.83203H10.6L8.8 12.332L5.2 1.33203L3.4 6.83203H1" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        {getText(props.percentage)}
      </div>
    </div>
  );
}

Completeness.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Completeness;
