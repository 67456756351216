import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import infoStore from 'src/stores/infoStore';
import { parseDate, intersectionObserverOption } from 'src/utils';
import { ApplicationStatusTxt, ApplicationStatus } from 'src/constants';
import styles from '../styles.module.scss';

@observer
class ApplicationTab extends React.Component {

  constructor() {
    super();
    this.ref = React.createRef();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.props.vm.onApplicationNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.observer.observe(this.ref.current);
  }

  isCanceled(status) {
    const isCanceled = [
      ApplicationStatus.ApplicationCanceled,
      ApplicationStatus.ApplicationDeclined,
      ApplicationStatus.CancelFoster,
      ApplicationStatus.CancelAdoption,
      ApplicationStatus.Expired
    ].includes(status);
    return isCanceled;
  }

  renderCard(a) {
    const isNew = this.props.vm.foster?.unreadApplicationIds?.includes(a.id) ?? false;

    return (
      <div className={styles.applicationBox}>
        <div className={styles.row}>
          <div className={styles.name}>{`申請人：${a.applicant}`}</div>
          <div className={cn(styles.tag, { [styles.tagCancel]: this.isCanceled(a.status) })}>
            {isNew && (<div className={styles.redDot} />)}
            {(a.status === ApplicationStatus.CancelFoster || a.status === ApplicationStatus.CancelAdoption)
              ? '媒合取消'
              : ApplicationStatusTxt[a.status]}
          </div>
        </div>
        <div className={styles.date}>{`提交申請時間：${parseDate(a.submitAt)}`}</div>
        <div className={styles.date}>
          狀態變更時間：
          <span className={styles.primary}>{parseDate(a.statusUpdateAt)}</span>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.vm.application.list.length === 0) {
      return (
        <div ref={this.ref} className={styles.noCats}>無認養申請</div>
      );
    }


    return (
      <>
        <div className={styles.explain}>
          嘿～這裡是向你申請認養貓咪的資料列表，可以點選以下認養人資料，查看認養申請內容。
          <br />
          <br />
          若有覺得合適的認養資料，可點選「有意願進行聯繫，請保持聯絡」，並盡快回覆認養人喔。
          若從申請資料中即覺得不合適，也可以點選「婉拒申請」，讓認養人知道您的意願。
        </div>

        <div className={styles.applicationTab}>

          {this.props.vm.application.list.map((a) => {
            if (this.props.vm.isUnavailable && !this.props.profile.isAdmin) {
              return (
                <div
                  key={a.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    infoStore.show({
                      message: '貓咪尚未刊登，將貓咪上架即可查閱申請資訊'
                    });
                  }}
                >
                  {this.renderCard(a)}
                </div>
              );
            }

            return (
              <Link
                key={a.id}
                to={`/user/foster/${this.props.vm.fosterId}/application/${a.id}`}
                style={{ textDecoration: 'none' }}
                onClick={() => this.props.vm.readApplication(a.id, this.props.profile.id)}
              >
                {this.renderCard(a)}
              </Link>
            );
          })}

          <div ref={this.ref} style={{ height: 10 }} />
        </div>
      </>
    );
  }
}

ApplicationTab.propTypes = {
  vm: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

export default ApplicationTab;
