import { message } from 'antd';
import { makeObservable, observable, action, computed } from 'mobx';

export default class CommentInputVM {
  @observable positiveRating = 0;
  @observable positiveComment;
  @observable negativeRating = 0;
  @observable negativeComment;
  onClickProp;

  constructor(onClickProp) {
    makeObservable(this);
    this.onClickProp = onClickProp;
  }


  onClick = () => {
    if (!(this.positiveComment || this.negativeComment)) {
      message.open({
        type: 'error',
        content: '評分至少需擇一填寫'
      });
      return;
    }


    this.onClickProp({
      positiveRating: this.positiveRating,
      positiveComment: this.positiveComment,
      negativeRating: this.negativeRating,
      negativeComment: this.negativeComment
    });
  };

  @action onPositiveRatingChange = (e) => {
    this.positiveRating = e;
  };
  @action onPositiveCommentChange = (e) => {
    this.positiveComment = e.target.value;
  };
  @action onNegativeRatingChange = (e) => {
    this.negativeRating = e;
  };
  @action onNegativCommentChange = (e) => {
    this.negativeComment = e.target.value;
  };
}
