import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import catImg from 'src/assets/6adb505479956b354dbafd8fdd1e5696.svg';
import styles from './Footer.module.scss';

function Footer(props) {
  const {
    mode = 'dark',
    className
  } = props;

  return (
    <div className={cn(styles.content_box4Content, 'footer', { [styles.light]: mode === 'light' }, className)}>
      <div className={styles.container}>
        <img
          className={styles.image}
          src={catImg}
          alt=""
        />

        <div className={styles.flex_row}>
          <div className={styles.unnamed}>
            <h5 className={styles.highlight2}>喵加人認養媒合平台</h5>
            <div className={styles.text1_box}>
              <span className={styles.text1}>
                <span className={styles.text1_span0}>Email： </span>
                <span className={styles.text1_span1}>service@puzzlecat.org.tw</span>
              </span>
            </div>
          </div>

          <div className={styles.text11_box}>
            <span className={styles.text11}>
              <span className={styles.text11_span0}>MeowOhana＠ All Rights Reserved </span>
              <a className={styles.text11_span1} href="/privacy-policy" target="_blank">
                隱私權政策
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string
};

export default Footer;
