import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

function PanelHeader(props) {
  return (
    <div className={cn(styles.root, 'panel-header')}>
      <div className={styles['left-parent']}>
        <h5 className={styles.title}>{props.title}</h5>
        <div className={styles.info}>{props.info}</div>
      </div>

      <div className={styles['more-button']}>
        <Link className={styles['button-text']} to={props.to}>
          MORE
        </Link>
      </div>
    </div>
  );
}

PanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default PanelHeader;
