import PropTypes from 'prop-types';
import QRcode from 'src/assets/QRcode.png';
import {
  NotificationDeadlineTxt,
  ObservationDurationTxt,
  trialPeriodTxt
} from 'src/constants';
import { v1 as contract } from 'src/constants/contract';
import { parseMinguoDate } from 'src/utils';

import styles from './styles.module.scss';

const V1 = (props) => {
  const foster = props.foster;

  return (
    <div className={styles.box}>

      <div className={styles.title}>動保法</div>
      <div className={styles.content}>
        甲方已年滿20歲，認養人與全體同居人（家人、室友、房東）皆取得飼養共識，並共同承諾遵守動保法之相關規定。
        <div>1.  須依法絕育(5-25萬)並植入晶片(3000-15000)、施打狂犬疫苗(3-15萬)。</div>
        <div>2.  認養動物受傷或生病時，必須及時請獸醫給予醫療，禁止私餵成藥。</div>
        <div>3.  甲方願依規定妥善照管認養動物並採取適當防護措施，善盡保護認養動物之責，如有違反願依動保法處新台幣3-15萬元。</div>
        <div>4.  甲方願善待認養動物，並提供適當之食物、乾淨之飲水、適當之運動空間，不長期關籠、栓綁在戶外或狹小之空間飼養。</div>

        <img src={QRcode} alt="QRcode" style={{ width: 100, display: 'block', marginBottom: 10, marginTop: 10 }} />
        動保法全文QR Code
      </div>

      <div className={styles.title}>幸福回報頻率</div>
      <div className={styles.content}>
        {
        foster?.needReport
          ? (
            <>
              為協助乙方了解動物生活情形，甲方願配合以下幸福回報規則：
              <br />
              自媒合成功並完成簽約日起，每
              {contract.ObservationFrequencyTxt[foster.observationFrequency] ?? '-'}
              於平台分享認養動物生活照片一次，媒合成功並完成簽約日起
              {ObservationDurationTxt[foster.observationDuration] ?? '-'}
              後，
              {foster.stabilityFrequency ? `每${contract.StabilityFrequencyTxt[foster.stabilityFrequency] ?? '-'}於平台分享認養動物生活照片一次。` : '不定期於平台分享認養動物生活照片。'}

            </>
          )
          : '甲方願依平台規定，不定期上傳分享認養動物生活照片。'
      }
      </div>

      <div className={styles.title}>動物死亡、轉讓或遺失處理方式</div>
      <div className={styles.content}>
        {foster?.canRehome ? '當認養動物轉讓、走失或死亡時' : '認養動物嚴禁轉讓，當認養動物走失或死亡時'}
        ，甲方需
        {foster?.deathOrLossNotification ? `於${NotificationDeadlineTxt[foster.deathOrLossNotification] ?? '-'}通知乙方知悉，並依法辦理相關登記。` : '依法辦理相關登記。'}
        {foster?.deathOrLossUnlimitedLiability ? '甲方同意承擔一切責任，並支付乙方在協尋、訴訟或醫療期間包含但不限於律師費、裁判費、醫療費、火化費等一切相關費用。' : ''}
        <br />

      </div>

      <div className={styles.title}>適應期（試養期）及相關規則</div>
      <div className={styles.content}>
        {foster?.trialPeriod ? `約定適應期${trialPeriodTxt[foster.trialPeriod] ?? '-'}。` : ''}
        認養後若不擬續養，甲方需盡速通知乙方，經雙方議定同意後，依約定方式將認養動物交還乙方。部分疾病因有潛伏期感染，動物認養後七天內出現生病情形，可帶回交由乙方請獸醫治療。
      </div>

      {foster?.needNeutering && (
      <>
        <div className={styles.title}>限定結紮完成時間</div>
        <div className={styles.content}>
          認養時如未完成絕育手術，本人願於
          {parseMinguoDate(foster?.neuteringDeadLine)}
          前協助動物完成絕育手術，違者可處5-25萬罰緩。如因動物體質、健康等問題尚無法進行絕育手術者，應由獸醫提出證明，並於適當情形下完成絕育手術或依據動保法申請免節育。
        </div>
      </>
      )}

      <div className={styles.bold_txt}>本人於認養動物後，如有醫療行為或發生照顧不週致動物死亡、受傷、傷人等情事，願自行負擔一切法律責任，並不得主張任何權力。</div>

      {foster?.others && (
        <>
          <div className={styles.title}>出養方自訂補充條款</div>
          <div className={styles.content}>{foster.others}</div>
        </>
      )}

    </div>
  );
};

V1.propTypes = {
  foster: PropTypes.object.isRequired
};

export default V1;

