import { request, getHost } from './api';
import AuthService from './auth';

export default class RaffleService {

  /**
   * [B, C]
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.isBackstage
   */
  static async getList(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/raffle`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [B, C]
   * @param {boolean} isBackstage
   */
  static async getOne(raffleId, isBackstage) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/raffle/${raffleId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        isBackstage
      }
    };
    const result = await request(options);
    return result.data;
  }

  /**
   * [B]
   */
  static async toggleIsShow(raffleId, isShow) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/raffle/${raffleId}/toggle`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        isShow
      }
    };
    return request(options);
  }

  /**
   * [B] 下載抽獎活動兌換名單
   */
  static async download(raffleId, { limit, anchor }) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/raffle/${raffleId}/download`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        limit,
        anchor
      }
    };
    const result = await request(options);
    return result.data;
  }

  /**
   * [B] 刪除抽獎活動
   */
  static async delete(raffleId) {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/raffle/${raffleId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   *
   * [B] 更新抽獎活動
   *
   * @param {Object} data
   * @param {string} data.title
   * @param {string} data.plainTextContent
   * @param {string} data.editorContent
   * @param {string} data.memo
   * @param {string} data.startAt
   * @param {string} data.endAt
   * @param {boolean} data.isShow
   * @param {boolean} data.isDrawn
   * @param {File[]} data.images
   * @param {string[]} data.deletedImages
   */
  static async update(raffleId, data) {
    const formData = new FormData();
    if (data.title) formData.append('title', data.title);
    if (data.startAt) formData.append('startAt', data.startAt);
    if (data.endAt) formData.append('endAt', data.endAt);
    if (data.memo) formData.append('memo', data.memo);
    if (data.plainTextContent) formData.append('plainTextContent', data.plainTextContent);
    if (data.editorContent) formData.append('editorContent', data.editorContent);
    if (data.isShow !== undefined || data.isShow !== null) formData.append('isShow', data.isShow);
    if (data.isDrawn !== undefined || data.isDrawn !== null) formData.append('isDrawn', data.isDrawn);
    if (Array.isArray(data.deletedImages) && data.deletedImages.length > 0) formData.append('deletedImages', data.deletedImages);

    if (Array.isArray(data.images) && data.images.length > 0) {
      data.images.forEach((img) => { formData.append('images', img); });
    }

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/raffle/${raffleId}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };

    return request(options);
  }

  /**
   *
   * [B] 新增抽獎活動
   *
   * @param {Object} data
   * @param {string} data.title
   * @param {string} data.plainTextContent
   * @param {string} data.editorContent
   * @param {string} data.memo
   * @param {string} data.startAt
   * @param {string} data.endAt
   * @param {boolean} data.isShow
   * @param {boolean} data.isDrawn
   * @param {File[]} data.images
   */
  static async create(data) {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('plainTextContent', data.plainTextContent);
    formData.append('editorContent', data.editorContent);
    formData.append('startAt', data.startAt);
    formData.append('endAt', data.endAt);
    formData.append('isShow', data.isShow);
    formData.append('isDrawn', data.isDrawn);
    if (data.memo) formData.append('memo', data.memo);

    data.images.forEach((img) => {
      formData.append('images', img);
    });

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/raffle`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };

    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 取得獲取抽獎券紀錄
   */
  static async getMyEventRecord(params) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/raffle/events`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params
    };
    const result = await request(options);
    return result.data;
  }

  /**
   * [C] 取得抽獎紀錄
   */
  static async getMyTicketRecord(params) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/raffle/tickets`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params
    };
    const result = await request(options);
    return result.data;
  }


  /**
   * [C] 取得抽獎活動已投入的抽獎券張數
   */
  static async getMyRedeemTicketCountByEvent(raffleId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/raffle/${raffleId}/count`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const result = await request(options);
    return result.data.count;
  }


  /**
   * [C] 參加抽獎活動
   */
  static async redeem(raffleId) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/raffle/${raffleId}/redeem`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const result = await request(options);
    return result.data;
  }


}
