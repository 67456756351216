import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Table, Input } from 'antd';

import withProfile from 'src/components/withProfile';
import { Router } from 'src/constants/index';
import UserViewModel from './viewModel';
import styles from './styles.module.scss';


@observer
class UserListPage extends React.Component {
  constructor() {
    super();
    this.vm = new UserViewModel();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  columns = [
    {
      title: '手機號碼',
      key: 'phone',
      width: 120,
      render: (data) => data.phone.trim()
    },
    {
      title: '姓名／組織名稱',
      key: 'name',
      width: 180,
      render: (data) => data.name
    },
    {
      title: '暱稱',
      key: 'nickname',
      width: 200,
      render: (data) => data.nickname
    },
    {
      title: 'email',
      key: 'email',
      render: (data) => data.email
    },
    {
      title: '出養案件數量',
      key: 'fosterCount',
      width: 116,
      render: (data) => data.fosterCount
    },
    {
      title: '申請認養數量',
      key: 'applicationCount',
      width: 116,
      render: (data) => data.applicationCount
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      render: (data) => (
        <Link to={`${Router.Backstage.User}/${data.id}`} className={styles.link}>
          會員詳情
        </Link>
      )
    }
  ];

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.header}>會員列表</div>
        <Input.Search
          placeholder="請輸入手機號碼或姓名"
          enterButton="搜尋"
          className={styles.input}
          onSearch={this.vm.onSearch}
        />
        <Table
          columns={this.columns}
          dataSource={this.vm.pager.section}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: this.vm.pager.limit,
            total: this.vm.pager.total,
            position: ['topRight'],
            showSizeChanger: true,
            pageSizeOptions: ['20', '50', '100'],
            onShowSizeChange: this.vm.pager.onLimitChange
          }}
          onChange={this.vm.pager.onPageChange}
          scroll={{ y: 'calc(100vh - 250px)', x: 1280 }}
        />

      </div>
    );
  }
}

export default withProfile(UserListPage, true, true);
