import { makeObservable, observable, runInAction, computed } from 'mobx';

import FosterService from 'src/services/foster';

export default class Foster {
  /**
   * foster id
   */
  id = null;
  /**
   * clear button
   */
  clearable = false;
  /**
   * 是否可點擊連結
   */
  linkable = false;

  /**
   * foster data
   */
  @observable data = null;
  /**
   * 'loading' | 'done' | 'error'
   *
   * @type {string}
   */
  @observable status = 'loading';

  @computed get loading() {
    return this.status === 'loading';
  }

  constructor(params = {}) {
    this.id = params.id;
    this.data = params.data;
    this.status = params.status ?? 'loading';
    this.clearable = params.clearable ?? false;
    this.linkable = params.linkable ?? false;

    makeObservable(this);
  }

  fetch = async () => {
    try {
      if (this.status === 'done') { return; }

      const data = await FosterService.getFosterById(this.id);

      runInAction(() => {
        this.status = 'done';
        this.data = data;
      });
    } catch (err) {
      console.log('error', err.response?.data);
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  static editmode(id) {
    return new Foster({ id, status: 'loading', clearable: true });
  }

  static viewmode(data) {
    return new Foster({ id: data.id, data, status: 'done', linkable: true });
  }
}
