import { request, getHost } from './api';
import AuthService from './auth';

export default class BannerService {

  /**
   * [B, C]
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.isBackstage
   */
  static async getList(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/banner`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [B]
   */
  static async getOne(bannerId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/banner/${bannerId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [B]
   * @param {Object} data
   * @param {string} data.title
   * @param {string} data.image
   * @param {string} data.url
   */
  static async create(data) {

    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('image', data.image);
    formData.append('url', data.url);

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/banner`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };

    const res = await request(options);
    return res.data;
  }

  /**
   * [B]
   * @param {Object} data
   * @param {string} data.title
   * @param {string} data.image
   * @param {string} data.url
   */
  static async update(bannerId, data) {

    const formData = new FormData();
    if (data.title) {
      formData.append('title', data.title);
    }
    if (data.image) {
      formData.append('image', data.image);
    }
    if (data.url) {
      formData.append('url', data.url);
    }

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/banner/${bannerId}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };

    const res = await request(options);
    return res.data;
  }

  /**
   * [B]
   */
  static async delete(bannerId) {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/banner/${bannerId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   * [B]
   */
  static async toggleIsShow(bannerId, isShow) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/banner/${bannerId}/toggle`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        isPublish: isShow
      }
    };
    return request(options);
  }
}
