/* eslint-disable global-require */
import React from 'react';
import { observer } from 'mobx-react';
import { Button, Collapse, Input, Select } from 'antd';
import { Helmet } from 'react-helmet';

import Card from 'src/components/Card';
import { parseDate, parseAge, intersectionObserverOption } from 'src/utils';
import { ColorationTxt, TaiwanCountyTxt, GenderTxt, Router } from 'src/constants';
import Loading from 'src/components/Loading';
import CatListVM from './viewModel';
import styles from './styles.module.scss';

@observer
class CatListPage extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.vm = new CatListVM();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.vm.onNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.vm.didMount();
    this.observer.observe(this.ref.current);
  }

  ageOption = [
    { value: '0', label: '0-1' },
    { value: '1', label: '1-3' },
    { value: '3', label: '3-5' },
    { value: '5', label: '5-8' },
    { value: '8', label: '8以上' }
  ];

  getRibbon(percentage) {
    if (percentage >= 85) {
      return {
        label: '超完整',
        color: '#0D9488',
        shadowColor: '#115E59'
      };
    }
    if (percentage >= 60) {
      return {
        label: '完整',
        color: '#D97706',
        shadowColor: '#92400E'
      };
    }
    return {
      label: '尚未完成',
      color: '#BE185D',
      shadowColor: '#831843'
    };
  }

  renderFilter() {
    return (
      <div className={styles.filter_innerBox}>
        <div className={styles.filter_item}>
          <div className={styles.title}>貓咪年齡：</div>
          <Select
            allowClear
            placeholder="請選擇"
            onChange={this.vm.onAgeChange}
            style={{ minWidth: 120 }}
            className={styles.input}
            options={this.ageOption}
          />
        </div>
        <div className={styles.filter_item}>
          <div className={styles.title}>地區：</div>
          <Select
            mode="multiple"
            optionFilterProp="label"
            allowClear
            placeholder="請選擇"
            value={this.vm.region}
            onChange={this.vm.onRegionChange}
            style={{ minWidth: 120 }}
            className={styles.input}
            options={Object.keys(TaiwanCountyTxt).map((key) => ({
              value: key,
              label: TaiwanCountyTxt[key]
            }))}
          />
        </div>
        <div className={styles.filter_item}>
          <div className={styles.title}>花色：</div>
          <Select
            mode="multiple"
            showSearch={false}
            allowClear
            placeholder="請選擇"
            value={this.vm.coloration}
            onChange={this.vm.onColorationChange}
            style={{ minWidth: 120 }}
            className={styles.input}
            options={Object.keys(ColorationTxt).map((key) => ({
              value: key,
              label: ColorationTxt[key]
            }))}
          />
        </div>
        <div className={styles.filter_item}>
          <div className={styles.title}>性別：</div>
          <Select
            placeholder="請選擇"
            allowClear
            value={this.vm.gender}
            onChange={this.vm.onRenderChange}
            style={{ minWidth: 90 }}
            className={styles.input}
            options={Object.keys(GenderTxt).map((key) => ({
              value: key,
              label: GenderTxt[key]
            }))}
          />
        </div>
        <div className={styles.filter_item}>
          <div className={styles.title}>關鍵字：</div>
          <Input
            placeholder="請輸入出養人姓名或貓咪名字"
            onChange={this.vm.onKeywordChange}
            value={this.vm.keyword}
            className={styles.keywordInput}
          />
        </div>
        <Button className={styles.btn} onClick={this.vm.onSearch}>
          套用篩選
        </Button>
      </div>
    );
  }

  renderNoCat() {
    return (
      <div className={styles.noCatBox}>
        <div className={styles.noCat}>目前沒有符合條件的貓咪</div>
      </div>
    );
  }

  renderCatList() {
    return (
      <div className={styles.card_box}>
        {this.vm.list.map((i) => (
          <Card
            key={i.id}
            to={`${Router.Client.CatList}/${i.id}`}
            image={i?.images?.[0]}
            title={i.title || '無標題'}
            ribbon={this.getRibbon(i.completeness)}
            content={(
              <div className={styles.card_content}>
                <div>{i.name}</div>
                <div>
                  {i.gender && <span>{`${GenderTxt[i.gender]}, `}</span>}
                  {i.birthday && <span>{`${parseAge(i.birthday)}歲, `}</span>}
                  <span>{i.isNeutered ? '已結紮' : '未結紮'}</span>
                </div>
                {i.fosterPerson.region && <div>{TaiwanCountyTxt[i.fosterPerson.region]}</div>}
                <div>{parseDate(i.updateAt, '-', true)}</div>
                {
                  i.applicationOngoingCount ? (
                    <div className={styles.card_ongoing_count}>{ `目前有 ${i.applicationOngoingCount} 筆認養申請中` }</div>
                  ) : null
                }
              </div>
            )}
            footer={(
              <div className={styles.footer}>
                <div className={styles.name}>{i.fosterPerson.nickname}</div>
                { i.inContract ? (<div className={styles.contract_flag}>簽約中</div>) : null }
              </div>
            )}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>貓咪列表</title>
          <link rel="canonical" href="https://meowohana.com/cats" />
        </Helmet>

        <div className={styles.main}>
          <div className={styles.box}>

            <div className={styles.filter_outerBox_pc}>
              {this.renderFilter()}
            </div>

            <Collapse
              className={styles.filter_outerBox_mobile}
              ghost
              items={[{
                key: '1',
                label: '篩選條件',
                children: this.renderFilter()
              }]}
            />

            {this.vm.isPageLoading
              ? <Loading />
              : (
                <div>
                  {this.vm.hasCat ? this.renderCatList() : this.renderNoCat()}
                </div>
              )}
            <div ref={this.ref} style={{ height: 10 }} />
          </div>
        </div>
      </>
    );
  }
}

CatListPage.propTypes = {

};

export default CatListPage;
