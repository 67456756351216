import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import FosterService from 'src/services/foster';
import { FosterStatus } from 'src/constants';
import infoStore from 'src/stores/infoStore';

export default class FosterListVM {
  limit = 30;
  @observable activeTab = FosterStatus.Available;
  @observable unavailable = {
    isLoading: false,
    isLoaded: false,
    list: [],
    anchor: null,
    keyword: undefined
  };
  @observable available = {
    isLoading: false,
    isLoaded: false,
    list: [],
    anchor: null,
    keyword: undefined
  };
  @observable adopted = {
    isLoading: false,
    isLoaded: false,
    list: [],
    anchor: null,
    keyword: undefined
  };

  constructor() {
    makeObservable(this);
  }


  didMount = async () => {
    this.loadData(FosterStatus.Available, true);
  };


  @action onTabChange = async (key) => {
    this.activeTab = key;

    const _key = key.toLowerCase();
    if (!this[_key].isLoaded) {
      this.loadData(key);
    }
  };


  @action onKeywordChange = (e, status) => {
    const key = status.toLowerCase();
    this[key].keyword = e.target.value;
  };


  loadData = async (status, reset = false) => {
    const key = status.toLowerCase();
    runInAction(() => { this[key].isLoading = true; });

    try {
      const result = await FosterService.getMyFosters({
        status,
        keyword: this[key].keyword,
        anchor: this[key].anchor,
        limit: this.limit
      });

      runInAction(() => {
        this[key].list = reset ? result.list : this[key].list.concat(result.list);
        this[key].anchor = result.anchor;
        this[key].isLoaded = true;
        this[key].isLoading = false;
      });

    } catch {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };



  onSearch = async (status) => {
    const key = status.toLowerCase();

    runInAction(() => {
      this[key].anchor = null;
    });

    const result = await FosterService.getMyFosters({
      status,
      keyword: this[key].keyword,
      limit: this.limit
    });

    runInAction(() => {
      this[key].list = result.list;
      this[key].anchor = result.anchor;
    });
  };



  onNextPage = async (status) => {
    const key = status.toLowerCase();
    if (this[key].isLoading || !this[key].anchor) return;

    try {
      runInAction(() => { this[key].isLoading = true; });

      const result = await FosterService.getMyFosters({
        status,
        keyword: this[key].keyword,
        anchor: this[key].anchor,
        limit: this.limit
      });

      runInAction(() => {
        this[key].list = this[key].list.concat(result.list);
        this[key].anchor = result.anchor;
        this[key].isLoading = false;
      });

    } catch {
      runInAction(() => { this[key].isLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };
}
