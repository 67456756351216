import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

@observer
class Comment extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const comment = this.props.comment;

    return (
      <div className={styles.main}>
        <div className={styles.title}>{this.props.title}</div>

        <div className={cn(styles.subtitle, styles.blue)}>
          <div>正面評價</div>
          <div>
            {[1, 2, 3, 4, 5].map((index) => (
              <SmileOutlined
                key={index}
                style={{ marginLeft: 5 }}
                className={cn({ [styles.unSelect]: comment ? (index > comment.positiveRating) : true })}
              />
            ))}
          </div>
        </div>
        <div className={styles.content}>{comment?.positiveComment ?? '-'}</div>


        <div className={cn(styles.subtitle, styles.orange)}>
          <div>負面評價</div>
          <div>
            {[1, 2, 3, 4, 5].map((index) => (
              <FrownOutlined
                key={index}
                style={{ marginLeft: 5 }}
                className={cn({ [styles.unSelect]: comment ? (index > comment.negativeRating) : true })}
              />
            ))}
          </div>
        </div>
        <div className={styles.content}>{comment?.negativeComment ?? '-'}</div>

        {(comment && this.props.commentPairId) && (
          <div className={styles.btnBox}>
            <Link
              className={styles.btn}
              to={`/user/${this.props.userId}/comment/${this.props.commentPairId}/${comment._id}`}
            >
              {`查看留言(${comment.replyCount}) >`}
            </Link>
          </div>
        )}

      </div>
    );
  }
}

Comment.propTypes = {
  comment: PropTypes.object,
  title: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  commentPairId: PropTypes.string
};

export default Comment;
