import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const CatPaw = (props) => {
  const {
    style = {}
  } = props;

  return (
    <div className={styles.pawWrapper} style={style}>
      <div className={styles.paw}>
        <svg viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path d="M13.4705 3.18698C13.5421 -0.787387 17.5523 -0.178691 17.2658 3.33021C16.7646 6.12301 13.4705 6.19461 13.4705 3.18698Z" fill="#3F3F46" />
            <path d="M10.0713 5.85189C9.49839 10.972 2.76701 4.24065 6.20431 2.84425C7.99456 2.41459 10.0355 3.99003 10.0713 5.85189Z" fill="#3F3F46" />
            <path d="M23.7466 15.2175C23.5676 18.5474 22.1711 20.087 19.0561 20.6957C16.3349 21.8772 13.2915 21.8415 10.5703 20.5883C9.63936 20.3018 9.20969 19.4783 9.03066 18.5474C7.99282 17.5688 8.13374 16.3939 8.27382 15.2258C8.30289 14.9834 8.33192 14.7414 8.35038 14.5014C8.2879 13.7101 8.83098 13.3063 9.37386 12.9027C9.76442 12.6124 10.1549 12.3221 10.3197 11.8876C11.3222 7.87746 14.8311 7.98489 17.2658 9.48871C17.8968 9.8305 18.6415 10.1037 19.3981 10.3811C21.7323 11.2373 24.1792 12.1348 23.7466 15.2175Z" fill="#3F3F46" />
            <path d="M2.19415 10.5987C4.09182 10.5629 8.81808 12.1741 6.20431 14.5014C3.26829 17.4374 -3.10501 10.8493 2.19415 10.5987Z" fill="#3F3F46" />
            <path d="M20.7435 7.81032C21.1212 9.73639 23.0038 8.97382 23.5656 7.49115C24.0371 6.88816 23.8382 6.10115 23.4955 5.28043C21.9062 2.89175 20.3311 6.10803 20.7435 7.81032Z" fill="#3F3F46" />
          </g>
        </svg>
      </div>
    </div>
  );
};

CatPaw.propTypes = {
  style: PropTypes.object
};

export default CatPaw;
