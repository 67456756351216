import { useCallback, useRef, useState, useMemo } from 'react';

/**
 *
 * @param {function} query call api
 * @param {function?} handler list update handler
 * @param {function?} onError error callback
 * @returns {{list: any[], next: function, loading: boolean}}
 */
export default function useAnchorAPI(query, handler, onError) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const hasNext = useRef(true);
  const queryRef = useRef(query);
  const handlerRef = useRef(handler);
  const onErrorRef = useRef(onError);

  queryRef.current = query;
  handlerRef.current = handler;
  onErrorRef.current = onError;

  const next = useCallback(
    async (reset) => {
      try {
        if (reset !== undefined && typeof reset !== 'boolean') {
          throw new Error('reset must be a boolean or undefined');
        }

        if (!reset && !hasNext.current) { return; }
        setLoading(true);

        const result = await queryRef.current(reset ? null : anchor);

        if (typeof handlerRef.current === 'function') {
          setList((prev) => handlerRef.current(reset, prev, result));
        } else {
          // default update list method (handler not set)
          setList((prev) => reset ? result.list : [...prev, ...result.list]);
        }

        setAnchor(result.anchor);
        hasNext.current = !!result.anchor;
      } catch (err) {
        console.warn('api error', err);
        onErrorRef.current && onErrorRef.current(err);

      } finally {
        setLoading(false);
      }
    },
    [anchor]
  );

  return useMemo(
    () => ({ list, next, loading }),
    [next, list, loading]
  );
}
