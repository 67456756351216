import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import FosterService from 'src/services/foster';
import { Router } from 'src/constants';
import infoStore from 'src/stores/infoStore';

export default class CatApplicationVM {
  fosterId = null;
  navigate = null;
  @observable isPageLoading = false;
  @observable foster = null;
  @observable currentStep = 0;
  @observable isModalShow = false;
  @observable message;

  constructor({ fosterId, navigate }) {
    makeObservable(this);
    this.fosterId = fosterId;
    this.navigate = navigate;
  }

  didMount = async () => {
    try {
      runInAction(() => { this.isPageLoading = true; });
      const foster = await FosterService.getFosterById(this.fosterId);

      runInAction(() => {
        this.foster = foster;
        this.isPageLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isPageLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };

  @action nextStep = () => {
    this.currentStep += 1;
  };

  @action onMessageChange = (e) => {
    this.message = e.target.value;
  };

  apply = async () => {
    if (!this.message) {
      message.open({
        type: 'error',
        content: '留言必填'
      });
      return;
    }

    try {
      const res = await FosterService.apply(this.fosterId, this.message);
      this.navigate(`${Router.Client.ApplicationList}/${res.id}`);

      // eslint-disable-next-line quotes
      infoStore.show({ message: `成功送出申請！請耐心等待出養人的回覆^^ \n若出養人回覆需求，您會收到訊息通知～` });


    } catch (err) {
      let _message = '';
      switch (err?.response?.status) {
        case 400:
          _message = '操作失敗！此貓咪暫時不接受認養申請';
          break;
        case 404:
          _message = '操作失敗！找不到此貓咪';
          break;
        case 409:
          _message = '操作失敗！您已對此貓咪送出過認養申請，請至「會員中心-認養申請」中查看申請詳情';
          break;
        case 419:
          _message = '操作失敗！您無法認養自己出養的貓咪';
          break;
        default:
          _message = '發生錯誤';
      }
      infoStore.show({ message: _message, type: 'warn' });
      this.navigate(`${Router.Client.CatList}/${this.fosterId}`);
    }

  };

  @action toggleModal = (boolean) => {
    this.isModalShow = boolean;
  };
}
