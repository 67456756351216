import React, { lazy, Suspense } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import Loading from 'src/components/Loading';
import HelpVM from './viewModel';

import styles from './styles.module.scss';

const Editor = lazy(() => import('src/components/Editor'));

@observer
class Help extends React.Component {
  constructor() {
    super();
    this.vm = new HelpVM();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.isPageLoading
      ? <Loading />
      : (
        <div className={styles.main}>
          <div className={styles.box}>
            {this.vm.isLoaded && (
            <div className={clsx(styles.editorBox, 'editor-shell')}>
              <Suspense>
                <Editor
                  initialEditorState={this.vm.content}
                  onChange={() => {}}
                  editable={false}
                />
              </Suspense>
            </div>
            ) }
          </div>
        </div>
      );
  }
}

Help.propTypes = {

};

export default Help;
