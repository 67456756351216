export default class CompletenessService {
  static partOne = [
    'name',
    'gender',
    'birthday',
    'title',
    'introduction',
    'coloration',
    'images'
  ];

  static partTwo = [
    'chipNumber',
    'avatar',
    'isNeutered',
    'AIDStest',
    'firstVaccine',
    'secondVaccine',
    'rabiesVaccine',
    'hasBloodTest',
    'firstDewormer',
    'secondDewormer',
    'healthCondition',
    'behaviorAssessment'
  ];

  static partThree = [
    'needReport',
    'canRehome',
    'deathOrLossNotification',
    'deathOrLossUnlimitedLiability',
    'trialPeriod',
    'needNeutering',
    'needHomeVisit',
    'needWindowSecurity'
  ];

  static getScore(data, array) {
    const score = array.reduce((accumulator, currentValue) => {
      const ObjValue = data[currentValue];

      if (Array.isArray(ObjValue)) {
        if (ObjValue.length > 0) return accumulator + 1;
        return accumulator;
      }

      if (ObjValue !== '' && ObjValue !== undefined && ObjValue !== null) return accumulator + 1;
      return accumulator;
    }, 0);
    return score;
  }


  static getCompleteness(data) {
    const [scoreOne, scoreTwo, scoreThree] = [
      CompletenessService.partOne,
      CompletenessService.partTwo,
      CompletenessService.partThree
    ].map((array) => CompletenessService.getScore(data, array));

    return {
      partOne: CompletenessService.getPercentage(scoreOne, CompletenessService.partOne.length),
      partTwo: CompletenessService.getPercentage(scoreTwo, CompletenessService.partTwo.length),
      partThree: CompletenessService.getPercentage(scoreThree, CompletenessService.partThree.length),
      all: CompletenessService.getPercentage((scoreOne + scoreTwo + scoreThree), (CompletenessService.partOne.length + CompletenessService.partTwo.length + CompletenessService.partThree.length))
    };
  }


  static getPercentage(score, length) {
    return ((score / length) * 100).toFixed(0);
  }


  static getColor = (percentage) => {
    if (percentage >= 85) return '#00A5B8';
    if (percentage >= 60) return '#D97706';
    return '#B91C1C';
  };
}
