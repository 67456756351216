import { ConfigProvider } from 'antd';
import PagesRoot from 'src/pages';
import { AppStateProvider } from 'src/stores';

import styles from './App.module.css';

function App() {
  return (
    <div className={styles.app}>
      <AppStateProvider>
        <ConfigProvider
          theme={{
            token: {
              borderRadius: 0,
              colorPrimary: '#00A5B8',
              colorPrimaryHover: '#00A5B8',
              colorError: '#CC3300'
            },
            components: {
              Button: {
                defaultShadow: 'none',
                primaryShadow: 'none',
                colorPrimaryHover: 'none',
                colorPrimaryBgHover: '#00A5B8'
              },
              Select: {
                controlHeight: 40,
                borderRadiusSM: 6
              },
              Input: {
                controlHeight: 40
              },
              DatePicker: {
                borderRadiusSM: 6,
                controlHeight: 40
              },
              Modal: {
                contentBg: '#F5F5F5',
                headerBg: '#F5F5F5',
                footerBg: '#F5F5F5'
              },
              Steps: {
                colorPrimary: '#3F3F46',
                colorText: '#3F3F46',
                colorTextDescription: '#A8A29E',
                customIconFontSize: 300
              }
            }
          }}
        >
          <PagesRoot />
        </ConfigProvider>
      </AppStateProvider>
    </div>
  );
}

export default App;
