import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { parseDate, intersectionObserverOption } from 'src/utils';

import styles from '../styles.module.scss';

@observer
class TicketTab extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.props.vm.onTicketNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.observer.observe(this.ref.current);
  }

  render() {
    return (
      <>
        <div className={styles.tickets}>

          {this.props.vm.ticket.list.map((t) => (
            <div className={styles.item} key={t.id}>
              <div className={styles.key}>{t.name}</div>
              <div className={styles.value}>{parseDate(t.createAt, '-')}</div>
            </div>
          ))}

        </div>
        <div ref={this.ref} style={{ height: 10 }} />
      </>
    );
  }
}

TicketTab.propTypes = {
  vm: PropTypes.object.isRequired
};

export default TicketTab;
