import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { ReactComponent as CatPaw } from 'src/assets/catpaw-blue.svg';
import ShareButton from 'src/components/ShareButton';

import styles from './styles.module.scss';

const ReactionRow = observer((props) => {
  const { item, onReaction, sharePath } = props;

  const reaction = useCallback((ev) => onReaction(ev, item.id), [item, onReaction]);

  if (!item) { return null; }

  return (
    <div className={cn(styles.reaction, props.className)}>
      <div
        className={styles.wrapper}
        onClick={reaction}
      >
        <CatPaw className={cn(styles.icon, item.isLike && styles.active)} />
      </div>
      <div className={styles.label}>{ item.reactions }</div>
      <ShareButton
        className={styles.share}
        iconClassName={styles.shareIcon}
        type="text"
        path={sharePath}
      />
    </div>
  );
});

ReactionRow.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  onReaction: PropTypes.func.isRequired,
  sharePath: PropTypes.string.isRequired
};

export default ReactionRow;
