import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Input } from 'antd';
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';

import CommentInputVM from './viewModel';
import styles from './styles.module.scss';

@observer
class CommentInput extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CommentInputVM(props.onClick);
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.title}>{this.props.title}</div>

        <div className={cn(styles.subtitle, styles.blue)}>
          <div>正面評價</div>
          <div>
            {[1, 2, 3, 4, 5].map((index) => (
              <SmileOutlined
                key={index}
                style={{ marginRight: 5 }}
                className={cn({ [styles.unSelect]: index > this.vm.positiveRating })}
                onClick={() => this.vm.onPositiveRatingChange(index)}
              />
            ))}
          </div>
        </div>
        <Input.TextArea
          placeholder="請輸入評價"
          maxLength={100}
          showCount
          onChange={this.vm.onPositiveCommentChange}
        />

        <div className={styles.divider} />

        <div className={cn(styles.subtitle, styles.orange)}>
          <div>負面評價</div>
          <div>
            {[1, 2, 3, 4, 5].map((index) => (
              <FrownOutlined
                key={index}
                style={{ marginLeft: 5 }}
                className={cn({ [styles.unSelect]: index > this.vm.negativeRating })}
                onClick={() => this.vm.onNegativeRatingChange(index)}
              />
            ))}
          </div>
        </div>
        <Input.TextArea
          placeholder="請輸入評價"
          maxLength={100}
          showCount
          onChange={this.vm.onNegativCommentChange}
        />

        <div className={styles.btnBox}>
          <Button className={styles.btn} onClick={this.vm.onClick}>送出評價</Button>
        </div>
      </div>
    );
  }
}

CommentInput.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CommentInput;
