import { makeObservable, observable, action, runInAction } from 'mobx';
import { resizeImg, fileToBase64 } from 'src/utils';
import infoStore from 'src/stores/infoStore';

export default class CropperModalVM {
  @observable cropPixel = { width: 0, height: 0, x: 0, y: 0 };
  @observable crop = { x: 0, y: 0 };
  @observable zoom = 1;
  @observable modalImage = null;
  @observable file = null;

  constructor() {
    makeObservable(this);
  }

  @action onCropComplete = (area, pixel) => {
    this.cropPixel = pixel;
  };
  @action onCropChange = (crop) => {
    this.crop = crop;
  };
  @action onZoomChange = (zoom) => {
    this.zoom = zoom;
  };

  @action onCancel = () => {
    this.modalImage = null;
  };

  getCropCanvas = (target) => {
    return new Promise((resolve) => {

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const context = target?.getRootNode?.();
      const imgSource = context.querySelector('.PREFIX-media');

      const {
        width: cropWidth,
        height: cropHeight,
        x: cropX,
        y: cropY
      } = this.cropPixel;

      canvas.width = cropWidth;
      canvas.height = cropHeight;
      // ctx.fillStyle = fillColor;
      ctx.fillRect(0, 0, cropWidth, cropHeight);

      ctx.drawImage(
        imgSource,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );


      canvas.toBlob(
        async (blob) => {
          const newFile = new File([blob], this.file.name, { type: this.file.type });
          resolve(newFile);
        },
        this.file.type,
        0.6
      );

    });

  };


  setImage = async (img) => {
    try {
      runInAction(() => { this.file = img; });
      const file = await resizeImg(img);
      const base64 = await fileToBase64(file);
      runInAction(() => { this.modalImage = base64; });

    } catch (err) {
      switch (err?.response?.status) {
        case 408:
          infoStore.show({ message: '圖檔過大', type: 'warn' });
          break;
        default:
          infoStore.show({ message: '發生錯誤，無法上傳圖片', type: 'warn' });
      }
    }
  };

  @action resetImage = () => {
    this.modalImage = null;
    this.file = null;
    this.cropPixel = { width: 0, height: 0, x: 0, y: 0 };
    this.crop = { x: 0, y: 0 };
    this.zoom = 1;
  };
}
