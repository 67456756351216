import { useEffect, useRef } from 'react';

import is from '../utils/is';

function useUnMount(callback) {
  const unmountRef = useRef(false);
  const callbackRef = useRef(callback);

  callbackRef.current = callback; // keep latest

  useEffect(
    () => {
      return () => {
        if (is.func(callbackRef.current) && !unmountRef.current) {
          unmountRef.current = true;
          callbackRef.current();
        }
      };
    },
    []
  );
}

export default useUnMount;
