/* eslint-disable no-throw-literal */

export default class FileSizeValidator {
  sizes = {};
  defaultMaxSize = 0;

  constructor(defaultMaxSize, sizes) {
    const maxSize = Number(defaultMaxSize);
    this.defaultMaxSize = Number.isNaN(maxSize) ? 0 : maxSize;
    this.sizes = sizes && typeof sizes === 'object' ? sizes : {};
  }

  validateBeforeParsing = async (config, plainFiles) => {
    for (const file of plainFiles) {
      const s = Number(this.sizes[file.type]);
      const maxSize = Number.isNaN(s) ? this.defaultMaxSize : s;

      if (file.size > maxSize) {
        throw ({
          name: 'FileSizeValidatorError',
          reason: `檔案 "${file.name}" 超過 ${maxSize / 1024 / 1024} MB`
        });
      }
    }
  };

  validateAfterParsing = async (config, file, reader) => {

  };

  onFilesSelected = async (data) => {

  };

  onFilesRejected = async (data) => {

  };

  onFileRemoved = async (file, idx) => {

  };

  onClear = async () => {

  };

  onFilesSuccessfullySelected = (data) => {

  };
}
