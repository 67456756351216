import { useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import clsx from 'clsx';

import DefaultAvatar from 'src/assets/avatar.svg';

import styles from './styles.module.scss';

const getMessageSummary = (message) => {
  if (message.text?.length) { return message.text; }
  if (message.image?.length) { return '[圖片]'; }
  if (message.files?.length) { return '[檔案]'; }
  return '';
};

const MessageBox = (props) => {
  const {
    item,
    className,
    onClick,
    isLast = false
  } = props;

  const onClickProxy = useCallback(() => onClick && onClick(item), [item, onClick]);

  if (!item || !item.user) {
    return null;
  }

  return (
    <div
      className={clsx(styles.box, isLast ? null : styles.separator, className)}
      onClick={onClickProxy}
    >
      <div className={styles.avatarWrapper}>
        <img
          className={item.user.avatar ? styles.avatar : styles.defaultAvatar}
          src={item.user.avatar || DefaultAvatar}
          alt="avatar"
        />
        { item.unread ? (<div className={styles.unread} />) : null }
      </div>

      <div className={styles.content}>
        <div className={styles.name}>{ item.user.nickname }</div>
        <div className={clsx(styles.message, item.unread ? null : styles.readed)}>
          { getMessageSummary(item.lastMessage) }
        </div>
      </div>

      <div className={styles.timestamp}>
        { dayjs(item.lastMessage.createAt).fromNow() }
      </div>
    </div>
  );
};

MessageBox.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isLast: PropTypes.bool
};

export default MessageBox;
