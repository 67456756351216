import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import PictureSet from 'src/components/PictureSet';
import withRouter from 'src/components/withRouter';
import { Router } from 'src/constants';

import NewsItemVM from './viewModel';

import styles from './styles.module.scss';

const Editor = lazy(() => import('src/components/Editor'));

@observer
class NewsItem extends React.Component {
  constructor(props) {
    super(props);
    const newsId = this.props.router.params.newsId;
    this.vm = new NewsItemVM(newsId);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <Breadcrumb
          items={[
            { title: '最新活動', link: Router.Client.EventList },
            { title: '公告詳情' }
          ]}
        />

        {this.vm.isPageLoading
          ? <Loading />
          : (
            <div className={styles.main}>
              <div className={styles.box}>

                <div className={styles.leftCol}>
                  <h1 className={styles.title}>{this.vm.news?.title ?? ''}</h1>
                  <PictureSet url={this.vm.news?.images ?? []} isImage3to2 />
                </div>

                <div className={styles.rightCol}>
                  <h1 className={styles.title}>{this.vm.news?.title ?? ''}</h1>
                  <div>
                    {
                      this.vm.news && (
                        <Suspense>
                          <Editor
                            editable={false}
                            onChange={() => {}}
                            initialEditorState={this.vm.news.editorContent}
                          />
                        </Suspense>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

NewsItem.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(NewsItem);
