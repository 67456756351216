import React from 'react';
import Cropper from 'react-easy-crop';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Upload } from 'antd';
import cn from 'classnames';
import { customUploadRequest } from 'src/utils';

import VM from './viewModel';
import styles from './styles.module.scss';


@observer
class ImgCrop extends React.Component {
  constructor() {
    super();
    this.vm = new VM();
    this.onOk = React.createRef();
    this.onCancel = React.createRef();
  }

  render() {

    const UploadComponent = this.props.type === 'dragger'
      ? Upload.Dragger
      : Upload;


    const beforeUpload = (file, fileList) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {

        this.vm.setImage(file);

        this.onCancel.current = () => {
          this.vm.resetImage();
          resolve(Upload.LIST_IGNORE);
        };

        this.onOk.current = async (event) => {
          const result = await this.vm.getCropCanvas(event.target);
          this.vm.resetImage();
          resolve(result);
        };
      });
    };


    return (
      <>
        <UploadComponent
          customRequest={customUploadRequest}
          beforeUpload={beforeUpload}
          accept="image/png,image/jpeg,image/bmp"
          showUploadList={{
            showPreviewIcon: false
          }}
          {...this.props}
          className={cn(styles.upload, this.props.className)}
        >
          <div>+</div>
          <p className="ant-upload-hint">上傳圖片</p>
        </UploadComponent>


        <Modal
          open={this.vm.modalImage}
          title="Edit image"
          styles={{ body: { position: 'relative' } }}
          centered
          onOk={this.onOk.current}
          onCancel={this.onCancel.current}
          zIndex={1000}
        >
          <Cropper
            image={this.vm.modalImage}
            crop={this.vm.crop}
            zoom={this.vm.zoom}
            aspect={1 / 1}
            onCropComplete={this.vm.onCropComplete}
            onCropChange={this.vm.onCropChange}
            onZoomChange={this.vm.onZoomChange}
            classes={{
              containerClassName: styles.cropContainer,
              mediaClassName: 'PREFIX-media'
            }}
          />
        </Modal>

      </>
    );
  }
}

ImgCrop.propTypes = {
  type: PropTypes.oneOf(['dragger', undefined]),
  className: PropTypes.string
};

export default ImgCrop;



