import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { observer, Observer } from 'mobx-react';
import { Button, Table, Switch } from 'antd';

import { parseDate } from 'src/utils';
import { Router } from 'src/constants';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';

import RaffleListViewModel from './viewModel';
import styles from './styles.module.scss';

const statusTxt = (startAt, endAt) => {
  const now = dayjs();
  if (now.isBefore(dayjs(startAt))) return '尚未開始';
  if (now.isAfter(dayjs(endAt))) return '已截止';
  return '報名中';
};

@observer
class RaffleListPage extends React.Component {
  constructor() {
    super();

    this.vm = new RaffleListViewModel();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  columns = [
    {
      title: '上架狀態',
      key: 'isShow',
      width: 88,
      render: (data) => (
        <Observer>
          {
            () => (
              <Switch
                checkedChildren="發布"
                unCheckedChildren="隱藏"
                checked={data.isShow}
                onChange={(checked) => this.vm.toggle(data, checked)}
              />
            )
          }
        </Observer>
      )
    },
    {
      title: '活動編號',
      key: 'serialNumber',
      width: 125,
      render: (data) => data.serialNumber ?? '-'
    },
    {
      title: '報名時間',
      key: 'period',
      width: 200,
      render: (data) => `${parseDate(data.startAt, '', true)} - ${parseDate(data.endAt, '', true)}`
    },
    {
      title: '活動狀態',
      key: 'status',
      width: 90,
      render: (data) => statusTxt(data.startAt, data.endAt)
    },
    {
      title: '開獎狀態',
      key: 'isDrawn',
      width: 90,
      render: (data) => data.isDrawn ? '已開獎' : '未開獎'
    },
    {
      title: '標題',
      key: 'title',
      render: (data) => data.title
    },
    {
      title: '報名人數',
      key: 'count',
      width: 90,
      render: (data) => data.count
    },
    {
      title: '備註',
      key: 'memo',
      ellipsis: true,
      render: (data) => data.memo ?? '-'
    },
    {
      title: '操作',
      key: 'action',
      width: 320,
      render: (data) => (
        <>
          <Button type="link" onClick={() => this.props.router.navigate(`${Router.Backstage.Raffle}/${data.id}`)}>詳情設定</Button>
          <Button type="link" onClick={() => this.vm.download(data.id)}>下載兌換名單</Button>
          <Button type="link" onClick={() => this.vm.delete(data.id)} className={styles.redTxt}>刪除</Button>
        </>
      )
    }
  ];


  render() {
    return (
      <div className={styles.main}>
        <div className={styles.headerBox}>
          <div className={styles.header}>獎勵活動管理</div>
          <Button
            type="primary"
            onClick={() => this.props.router.navigate(Router.Backstage.CreateRaffleEvent)}
          >
            ＋新增
          </Button>
        </div>

        <Table
          columns={this.columns}
          dataSource={this.vm.pager.section}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: this.vm.pager.limit,
            total: this.vm.pager.total,
            position: ['topRight'],
            showSizeChanger: true,
            pageSizeOptions: ['20', '50', '100'],
            onShowSizeChange: this.vm.pager.onLimitChange
          }}
          onChange={this.vm.pager.onPageChange}
          scroll={{ y: 'calc(100vh - 200px)', x: 1280 }}
        />
      </div>
    );
  }
}


export default withRouter(withProfile(RaffleListPage, true, true));
