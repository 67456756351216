import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Observer } from 'mobx-react';

import Card from 'src/components/Card';
import { parseDate, parseAge } from 'src/utils';
import { TaiwanCountyTxt, GenderTxt, Router } from 'src/constants';

import PanelHeader from '../PanelHeader';
import InfoItem from '../InfoItem';
import styles from './styles.module.scss';

const getRibbon = (percentage) => {
  if (percentage >= 85) {
    return {
      label: '超完整',
      color: '#0D9488',
      shadowColor: '#115E59'
    };
  }
  if (percentage >= 60) {
    return {
      label: '完整',
      color: '#D97706',
      shadowColor: '#92400E'
    };
  }
  return {
    label: '尚未完成',
    color: '#BE185D',
    shadowColor: '#831843'
  };
};

function InfoSection(props) {


  return (
    <Observer>
      {() => (
        <section className={styles['info-section']}>
          <div className={styles['info-content']}>
            <div className={styles['panel-left']}>
              <div className={styles.header}>
                <PanelHeader info="Pet Profile" title="最新刊登" to={Router.Client.CatList} />
              </div>

              <div className={styles['profile-grid-container']}>
                {props.vm.foster.map((i) => (
                  <div className={styles['profile-item']} key={i.id}>
                    <Card
                      to={`${Router.Client.CatList}/${i.id}`}
                      image={i?.images?.[0]}
                      title={i.title || '無標題'}
                      ribbon={getRibbon(i.completeness)}
                      content={(
                        <div className={styles.card_content}>
                          <div>{i.name}</div>
                          <div>
                            {i.gender && <span>{`${GenderTxt[i.gender]}, `}</span>}
                            {i.birthday && <span>{`${parseAge(i.birthday)}歲, `}</span>}
                            <span>{i.isNeutered ? '已結紮' : '未結紮'}</span>
                          </div>
                          {i.fosterPerson.region && <div>{TaiwanCountyTxt[i.fosterPerson.region]}</div>}
                          <div>{parseDate(i.updateAt, '-', true)}</div>
                          {
                            i.applicationOngoingCount ? (
                              <div className={styles.card_ongoing_count}>{ `目前有 ${i.applicationOngoingCount} 筆認養申請中` }</div>
                            ) : null
                          }
                        </div>
                      )}
                      footer={(
                        <div className={styles.footer}>
                          <div className={styles.name}>{i.fosterPerson.nickname}</div>
                          { i.inContract ? (<div className={styles.contract_flag}>簽約中</div>) : null }
                        </div>
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className={styles['panel-right']}>
              <div className={styles.header1}>
                <PanelHeader info="News" title="公告訊息" to={`${Router.Client.EventList}?tab=news`} />
              </div>

              <div className={styles['content-container']}>
                {props.vm.news.map((n) => (
                  <Link
                    className={styles['content-item']}
                    key={n.id}
                    to={`${Router.Client.NewsItem_ROOT}/${n.id}`}
                  >
                    <InfoItem date={parseDate(n.showAt, '-', true)} message={n.title} />
                  </Link>
                ))}
              </div>

              {
                props.vm.raffle.length ? (
                  <>
                    <div className={styles.header1}>
                      <PanelHeader info="Campaigns" title="獎勵活動" to={`${Router.Client.EventList}?tab=campaigns`} />
                    </div>

                    <div className={styles['content-container']}>
                      {
                        props.vm.raffle.map((n) => {
                          const start = parseDate(n.startAt, null, true);
                          const end = parseDate(n.endAt, null, true);
                          const date = start && end ? `${start} ~ ${end}` : start || end;
                          return (
                            <Link
                              className={styles['content-item']}
                              key={n.id}
                              to={`${Router.Client.EventList}/${n.id}`}
                            >
                              <InfoItem date={date} message={n.title} />
                            </Link>
                          );
                        })
                      }
                    </div>
                  </>
                ) : null
              }
            </div>
          </div>
        </section>
      )}
    </Observer>
  );
}

InfoSection.propTypes = {
  vm: PropTypes.object.isRequired
};

export default InfoSection;
