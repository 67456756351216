import { makeObservable, observable, action, runInAction } from 'mobx';
import { message } from 'antd';

import AntdTablePager from 'src/models/AntdTablePager';
import BannerService from 'src/services/banner';
import errorStore from 'src/stores/errorStore';
import { backstageConfirmStore } from 'src/stores/confirmStore';
import { isValidUrl } from 'src/utils';

export default class BannerViewModel {
  pager;

  @observable modal = {
    isShow: false,
    data: null,
    isSaving: false
  };

  @observable form = {
    title: '',
    image: null,
    url: '',
    defaultFileList: []
  };

  constructor() {
    makeObservable(this);

    this.pager = new AntdTablePager(this.query, 20);
  }

  didMount = async () => {
    await this.pager.next(true);
  };

  @action openModel = (data) => {
    this.modal = { isShow: true, data };

    if (data) {
      this.form.title = data.title;
      this.form.url = data.url;
      this.form.defaultFileList = [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: data.image
        }
      ];
    }
  };

  @action closeModel = () => {
    this.modal = {
      isShow: false,
      data: null,
      isSaving: false
    };
    this.form = {
      title: '',
      url: '',
      image: null,
      defaultFileList: []
    };
  };

  @action onTitleChange = (e) => {
    this.form.title = e.target.value;
  };

  @action onUrlChange = (e) => {
    this.form.url = e.target.value;
  };

  @action handleUploadChange = ({ file }) => {
    this.form.image = file.originFileObj;
  };

  query = async (limit, anchor) => {
    try {
      const { list, anchor: nextAnchor } = await BannerService.getList({ isBackstage: true, limit, anchor });
      return { list, anchor: nextAnchor };

    } catch (err) {
      errorStore.show('發生錯誤，無法取得資訊');
      return { list: [], anchor: null };
    }
  };

  create = async () => {
    try {
      if (!this.form.title || !this.form.image) {
        message.error('名稱及圖片必填！');
        return;
      }

      if (!isValidUrl(this.form.url)) {
        message.error('請填入有效連結！');
        return;
      }

      runInAction(() => { this.modal.isSaving = true; });

      const res = await BannerService.create({
        title: this.form.title,
        image: this.form.image,
        url: this.form.url
      });

      this.pager.list.unshift(res);
      this.closeModel();

    } catch {
      errorStore.show('發生錯誤，無法新增Banner');
      this.closeModel();
    }
  };

  update = async () => {
    try {
      if (!this.form.title) {
        message.error('名稱必填！');
        return;
      }

      if (!isValidUrl(this.form.url)) {
        message.error('請填入有效連結！');
        return;
      }

      runInAction(() => { this.modal.isSaving = true; });

      const res = await BannerService.update(this.modal.data.id, {
        title: this.form.title,
        image: this.form.image,
        url: this.form.url
      });

      this.pager.update(res.id, res);

      this.closeModel();

    } catch (err) {
      errorStore.show('發生錯誤，無法更新Banner');
      this.closeModel();
    }
  };

  action = async () => {
    if (this.modal.data) return this.update();
    return this.create();
  };


  delete = (id) => {
    backstageConfirmStore.show({
      message: '確定刪除？',
      callBack: async () => {
        try {
          await BannerService.delete(id);
          this.pager.remove(id);
        } catch {
          errorStore.show('發生錯誤，無法刪除Banner');
        }
      }
    });
  };

  toggle = async (data, isShow) => {
    try {

      await BannerService.toggleIsShow(data.id, isShow);
      this.pager.update(data.id, { isShow });
    } catch {
      errorStore.show('發生錯誤，無法更新Banner裝態');
    }
  };
}

