import { message } from 'antd';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { Router } from 'src/constants';
import UserService from 'src/services/user';
import AuthService from 'src/services/auth';
import infoStore from 'src/stores/infoStore';

export default class LoginVM {
  router;
  @observable isLoading = false;
  @observable phone;
  @observable password;

  constructor(router) {
    makeObservable(this);
    this.router = router;
  }

  didMount = () => {};


  checkProfile = (profile) => {
    if (AuthService.hasToken() && profile) {
      this.router.navigate(Router.Client.Home);
    }
  };


  singin = async () => {
    if (!this.phone || !this.password) {
      infoStore.show({
        message: '請輸入帳號/密碼',
        type: 'warn'
      });
      return;
    }

    try {
      runInAction(() => { this.isLoading = true; });
      await UserService.login(this.phone, this.password);

      runInAction(() => { this.isLoading = false; });
      this.router.navigate(Router.Client.User);


    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      let _message = '';
      switch (err?.response?.status) {
        case 404:
        case 401:
          _message = '帳號或密碼錯誤';
          break;
        default:
          _message = '發生錯誤';
      }
      infoStore.show({ message: _message, type: 'warn' });
    }
  };

  @action onPhoneChange = (e) => {
    this.phone = e.target.value;
  };
  @action onPasswordChange = (e) => {
    this.password = e.target.value;
  };
}
