import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';

import styles from './styles.module.scss';

function InfoItem(props) {
  return (
    <Observer>
      {() => (
        <div className={styles.infoItem}>
          <div className={styles.message}>{props.message}</div>
          <div className={styles.date}>{props.date}</div>
        </div>
      )}

    </Observer>
  );
}

InfoItem.propTypes = {
  message: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};

export default InfoItem;
