import { makeObservable, observable, action } from 'mobx';
import UserService from 'src/services/user';
import errorStore from 'src/stores/errorStore';
import AntdTablePager from 'src/models/AntdTablePager';

export default class UserViewModel {
  pager;

  @observable keyword = '';

  constructor() {
    makeObservable(this);

    this.pager = new AntdTablePager(this.query, 20);
  }

  didMount = async () => {
    await this.pager.next(true);
  };

  query = async (limit, anchor) => {
    try {
      const { users, anchor: nextAnchor } = await UserService.getList({
        limit,
        anchor,
        keyword: this.keyword || undefined
      });

      return { list: users, anchor: nextAnchor };

    } catch (err) {
      errorStore.show('發生錯誤，無法取得資料');
      return { list: [], anchor: null };
    }
  };

  @action onSearch = async (keyword) => {
    this.keyword = keyword;
    await this.pager.next(true);
  };
}
