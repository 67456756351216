import { makeObservable, observable, runInAction, action } from 'mobx';

import FosterService from 'src/services/foster';
import UserService from 'src/services/user';
import CompletenessService from 'src/services/completeness';
import infoStore from 'src/stores/infoStore';
import { Router } from 'src/constants';

export default class CatItemVM {
  fosterId = null;
  @observable isModalOpen = false;
  @observable isPageLoading = false;
  @observable foster = null;
  @observable fosterPerson = null;
  @observable completeness = {
    partOne: 0,
    partTwo: 0,
    partThree: 0,
    all: 0
  };

  constructor(fosterId) {
    makeObservable(this);
    this.fosterId = fosterId;
  }

  didMount = async (router) => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      const foster = await FosterService.getFosterById(this.fosterId);

      if (foster.status !== 'Available') {
        throw new Error('status');
      }

      const fosterPerson = await UserService.getUserById(foster.fosterPerson.id);
      const completeness = CompletenessService.getCompleteness(foster);

      runInAction(() => {
        this.foster = foster;
        this.fosterPerson = fosterPerson;
        this.completeness = completeness;
        this.isPageLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isPageLoading = false; });

      if (err?.message === 'status') {
        infoStore.show({ message: '此筆出養已下架，歡迎查看其他貓貓喔^^' });
        router.navigate(Router.Client.CatList);
      } else {
        infoStore.show({ message: '發生錯誤，無法取得資訊' });
      }
    }
  };


  @action showModal = () => {
    this.isModalOpen = true;
  };
  @action closeModal = () => {
    this.isModalOpen = false;
  };
}
