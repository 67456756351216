import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Button, Spin } from 'antd';

import { ReactComponent as XIcon } from 'src/assets/x.svg';
import AttachmentIcon from 'src/assets/attachment.svg';

import styles from './styles.module.scss';

const getDescription = (item, isLengthLimitReached) => {
  if (isLengthLimitReached) { return '檔案數量已達上限'; }
  if (item.status === 'error') { return '上傳失敗'; }
  return '點擊下載 >';
};

const FileCard = observer((props) => {
  const {
    className,
    item,
    isLengthLimitReached,
    onClear = () => {}
  } = props;

  const onClearProxy = useCallback(() => onClear(item), [onClear, item]);

  useEffect(
    () => {
      if (!isLengthLimitReached) {
        item.start();
      }

      return () => {
        item.stop();
      };
    },
    [item, isLengthLimitReached]
  );

  const isFailed = isLengthLimitReached || item.status === 'error';

  return (
    <div
      className={clsx(styles.card, item.clickable && styles.pointer, item.clearable && styles.clearable, className)}
      onClick={item.download}
    >
      <img
        className={clsx(item.loading && styles.opacity20)}
        src={AttachmentIcon}
        alt=""
      />
      <div className={clsx(styles.info, item.loading && styles.opacity20)}>
        <div className={clsx('wrap', styles.name, isFailed && styles.failed)}>{ item.name }</div>
        <div className={clsx(styles.text, isFailed && styles.failed)}>
          { getDescription(item, isLengthLimitReached) }
        </div>
      </div>
      {
        item.clearable ? (
          <Button
            className={styles.clear}
            onClick={onClearProxy}
            icon={<XIcon className={styles.x} />}
            ghost
          />
        ) : null
      }
      <Spin
        className={styles.spin}
        spinning={!isFailed && item.loading}
      />
    </div>
  );
});

FileCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  isLengthLimitReached: PropTypes.bool,
  onClear: PropTypes.func
};

export default FileCard;
