import React from 'react';
import cn from 'classnames';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Carousel } from 'antd';
import styles from './bannerSection.module.scss';


function BannerItem(props) {
  return (
    <Observer>
      {() => (
        <div>
          {props.url
            ? (
              <a href={props.url} target="_blank" rel="noreferrer">
                <img src={props.src} className={styles['main-image']} alt="banner" />
              </a>
            )
            : (
              <div>
                <img src={props.src} className={styles['main-image']} alt="banner" />
              </div>
            )}
        </div>

      )}
    </Observer>
  );
}

BannerItem.propTypes = {
  src: PropTypes.string.isRequired,
  url: PropTypes.string
};

function BannerSection(props) {
  return (
    <Observer>
      {() => (
        <section className={styles['banner-section']}>
          <div className={styles['banner-container']}>
            <div className={styles.carousal_container}>
              <div className={styles.carousal}>
                <Carousel
                  autoplay
                  dots={{ className: styles.dot }}
                >
                  {props.vm.banner.map((b, i) => (
                    <div key={b.id}>
                      <BannerItem src={b.image} url={b.url} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </section>
      )}

    </Observer>
  );
}

BannerSection.propTypes = {
  vm: PropTypes.object.isRequired
};

export default BannerSection;
