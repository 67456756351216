import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import BannerService from 'src/services/banner';
import FosterService from 'src/services/foster';
import NewsService from 'src/services/news';
import RaffleService from 'src/services/raffle';
import infoStore from 'src/stores/infoStore';


export default class HomeVM {
  @observable isPageLoading = false;
  @observable news = [];
  @observable raffle = [];
  @observable banner = [];
  @observable foster = [];

  constructor() {
    makeObservable(this);
  }

  didMount = async () => {
    try {
      runInAction(() => {
        this.isPageLoading = true;
      });

      const [newsResult, fosterResult, bannerResult, raffleResult] = await Promise.all([
        NewsService.getList({ limit: 5 }),
        FosterService.getFrontendList({ limit: 8 }),
        BannerService.getList(),
        RaffleService.getList({ limit: 5 })
      ]);

      runInAction(() => {
        this.news = newsResult.list;
        this.foster = fosterResult.list;
        this.banner = bannerResult.list;
        this.raffle = raffleResult.list;
        this.isPageLoading = false;
      });

    } catch {
      runInAction(() => { this.isPageLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };
}
