import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import NewsService from 'src/services/news';
import infoStore from 'src/stores/infoStore';

export default class NewsItemVM {
  newsId;
  @observable news;
  @observable isPageLoading = false;

  constructor(newsId) {
    makeObservable(this);
    this.newsId = newsId;
  }

  didMount = async () => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      const news = await NewsService.getOne(this.newsId);

      runInAction(() => {
        this.news = news;
        this.isPageLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isPageLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };
}
