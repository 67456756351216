import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './NavItem.module.scss';

function NavItem(props) {
  return (
    <div type="text" className={cn(styles.unnamed, 'nav-item')}>
      <h5 className={cn(styles.title, { [styles.activeTitle]: props.isActive })}>
        {props.title}
      </h5>
      <div className={cn(styles.subTitle, { [styles.activeSubtitle]: props.isActive })}>
        {props.subTitle}
      </div>
    </div>
  );
}

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired
};

export default NavItem;
