import { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Avatar } from 'antd';

import MessageService from 'src/services/message';
import useProfile from 'src/hooks/useProfile';
import { ReactComponent as AvatarIcon } from 'src/assets/avatar.svg';
import { ReactComponent as MessageIcon } from 'src/assets/message.svg';
import { ApplicationStatusTxt, ApplicationStatus } from 'src/constants';
import { group } from 'src/utils';

import styles from './styles.module.scss';

const ApplicationHeaderBox = (props) => {
  const { person, namePrefix, msgText, status, infos, fosterId } = props;

  const navigate = useNavigate();
  const profile = useProfile();

  const onCommentClick = useCallback(() => person && navigate(`/user/${person.id}/comment`), [navigate, person]);
  const onMessageClick = useCallback(
    async () => {
      try {
        if (!person) { return; }

        const box = await MessageService.upsertBox(person.id);
        navigate(`/message/${box.id}${fosterId ? `?foster=${fosterId}` : ''}`);
      } catch (err) {
        // ignore
      }
    },
    [navigate, person, fosterId]
  );

  const grouped = group(infos ?? [], 2);

  const tag = [ApplicationStatus.CancelFoster, ApplicationStatus.CancelAdoption].includes(status)
    ? '媒合取消'
    : ApplicationStatusTxt[status] ?? '';

  const isGrayTag = [
    ApplicationStatus.CancelFoster,
    ApplicationStatus.CancelAdoption,
    ApplicationStatus.Expired
  ].includes(status);

  const showInfos = !!grouped.length;
  const showMessageButton = profile && person && person.id !== profile.id;
  const showCommentButton = !!person?.commentCount;

  return (
    <div className={styles.header}>
      <div className={styles.row1}>
        <Avatar
          className={styles.avatar}
          icon={<AvatarIcon className={styles.icon} />}
          src={person?.avatar}
        />
        <div className={styles.name}>{ `${namePrefix}：${person?.nickname || '-'}` }</div>
        <div className={clsx(styles.tag, isGrayTag && styles.grayTag)}>
          { tag }
        </div>
      </div>

      {
        showInfos || showMessageButton || showCommentButton ? (
          <>
            <div className={styles.separator} />

            <div className={styles.row2}>
              {
                showInfos ? (
                  <div className={styles.groups}>
                    { grouped.map((gp) => (<div className={styles.group}>{ gp }</div>)) }
                  </div>
                ) : null
              }
              {
                showMessageButton || showCommentButton ? (
                  <div className={styles.buttons}>
                    {
                      showMessageButton ? (
                        <Button
                          className={clsx(styles.button, styles.message)}
                          icon={<MessageIcon className={styles.icon} />}
                          onClick={onMessageClick}
                        >
                          { msgText }
                        </Button>
                      ) : null
                    }
                    {
                      showCommentButton ? (
                        <Button
                          className={clsx(styles.button, styles.comment)}
                          onClick={onCommentClick}
                        >
                          { `閱讀 ${person.commentCount} 則評論` }
                        </Button>
                      ) : null
                    }
                  </div>
                ) : null
              }
            </div>
          </>
        ) : null
      }
    </div>
  );
};

ApplicationHeaderBox.propTypes = {
  /**
   * { id, avatar, nickname, commentCount }
   */
  person: PropTypes.object,
  namePrefix: PropTypes.string.isRequired,
  msgText: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  infos: PropTypes.arrayOf(PropTypes.node),
  fosterId: PropTypes.string
};

export default ApplicationHeaderBox;
