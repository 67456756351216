import { makeObservable, observable, action, computed } from 'mobx';

class ConfirmStore {
  @observable isShow = false;
  @observable message = '';
  @observable title = '';
  /**
   * 自訂 render content
   *
   * ! Note: render 優先於 message
   */
  render = null;
  callBack = () => {};

  constructor() {
    makeObservable(this);
  }

  @action
    show = ({ message, callBack, title = '再想一下', render }) => {
      this.isShow = true;
      this.message = message;
      this.title = title;
      this.render = render;
      this.callBack = callBack;
    };

  @action
    onOk = () => {
      this.callBack();
      this.onCancel();
    };

  @action
    onCancel = () => {
      this.isShow = false;
      this.message = '';
      this.callBack = () => {};
    };
}

export const clientConfirmStore = new ConfirmStore();
export const backstageConfirmStore = new ConfirmStore();
