import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Modal, Button } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import infoStore from 'src/stores/infoStore';
import { clientConfirmStore } from 'src/stores/confirmStore';
import { Router } from 'src/constants';

import styles from './styles.module.scss';

/**
 * footer mode = light
 */
const LightFooter = [
  Router.Auth.Login,
  Router.Auth.Register,
  Router.Auth.RestPwd,
  Router.Auth.Verify,
  Router.Client.PrivacyPolicy
];

const ClientLayout = observer((props) => {

  const location = useLocation();

  const isLightFooter = LightFooter.includes(location.pathname);

  return (
    <>
      <Helmet>
        <title>喵加人認養媒合平台</title>
      </Helmet>

      <div className={styles.client_layout}>
        <Header />
        <div className={styles.main}>
          <Outlet />
        </div>
        <Footer mode={isLightFooter ? 'light' : 'dark'} />
      </div>

      <Modal
        open={infoStore.isShow}
        title={infoStore.title}
        onOk={infoStore.onClose}
        onCancel={infoStore.onClose}
        closeIcon={null}
        footer={null}
      >
        <div className={styles.box}>
          {
          infoStore.type === 'warn'
            ? <ExclamationCircleFilled className={styles.warnIcon} />
            : <CheckCircleFilled className={styles.icon} />
          }
          <div>{infoStore.message}</div>
          <Button onClick={infoStore.onClose} className={styles.btn}>
            確認
          </Button>
        </div>
      </Modal>

      <Modal
        closeIcon={null}
        onCancel={clientConfirmStore.onCancel}
        open={clientConfirmStore.isShow}
        title={(
          <div className={clsx(styles.row, styles.title)}>
            <ExclamationCircleFilled className={styles.infoIcon} />
            <div>{clientConfirmStore.title}</div>
          </div>
        )}
        footer={(
          <div className={clsx(styles.row, styles.footer)}>
            <Button className={styles.noBtn} onClick={clientConfirmStore.onCancel}>否</Button>
            <Button className={styles.yesBtn} onClick={clientConfirmStore.onOk}>是</Button>
          </div>
        )}
      >
        {
          typeof clientConfirmStore.render === 'function'
            ? clientConfirmStore.render()
            : (<div className={styles.content}>{clientConfirmStore.message}</div>)
        }
      </Modal>

      <ScrollRestoration />
    </>
  );
});

export default ClientLayout;
