import Cookies from 'js-cookie';

const AUTH_TOKEN_KEY = 'auth';

export default class Auth {
  static hasToken() {
    if (Cookies.get(AUTH_TOKEN_KEY) === 'undefined') {
      Auth.removeToken();
    }
    return !!Cookies.get(AUTH_TOKEN_KEY);
  }

  static getToken() {
    return Cookies.get(AUTH_TOKEN_KEY);
  }

  static removeToken() {
    Cookies.remove(AUTH_TOKEN_KEY);
  }

  static setToken(token) {
    Cookies.set(AUTH_TOKEN_KEY, token);
  }
}
