import { request, getHost } from './api';
import AuthService from './auth';

export default class NewsService {

  /**
   * [B, C]
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.isBackstage
   */
  static async getList(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/news`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [B, C]
   * @param {string} newsId
   */
  static async getOne(newsId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/news/${newsId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [B]
   * @param {Object} data
   * @param {string} data.title
   * @param {string} data.plainTextContent
   * @param {string} data.editorContent
   * @param {File[]} data.images
   */
  static async create(data) {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('plainTextContent', data.plainTextContent);
    formData.append('editorContent', data.editorContent);
    data.images.forEach((img) => { formData.append('images', img); });

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/news`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };

    const res = await request(options);
    return res.data;
  }

  /**
   * [B]
   * @param {Object} data
   * @param {string} data.title
   * @param {string} data.plainTextContent
   * @param {string} data.editorContent
   * @param {File[]} data.images
   * @param {string[]} data.deletedImages
   */
  static async update(newsId, data) {

    const formData = new FormData();
    if (data.title) formData.append('title', data.title);
    if (data.plainTextContent) formData.append('plainTextContent', data.plainTextContent);
    if (data.editorContent) formData.append('editorContent', data.editorContent);
    if (Array.isArray(data.deletedImages) && data.deletedImages.length > 0) {
      formData.append('deletedImages', data.deletedImages);
    }
    if (Array.isArray(data.images) && data.images.length > 0) {
      data.images.forEach((img) => { formData.append('images', img); });
    }

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/news/${newsId}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };

    const res = await request(options);
    return res.data;
  }

  /**
   * [B]
   */
  static async delete(newsId) {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/news/${newsId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   * [B]
   */
  static async toggleIsShow(newsId, isShow) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/news/${newsId}/toggle`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        isShow
      }
    };
    return request(options);
  }

}
