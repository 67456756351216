import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import FosterService from 'src/services/foster';
import UserService from 'src/services/user';
import infoStore from 'src/stores/infoStore';
import { Router } from 'src/constants';

export default class UserViewModel {

  router;

  @observable catList = [];

  constructor(router) {
    makeObservable(this);
    this.router = router;
  }

  @computed get hasCat() {
    return this.catList.length > 0;
  }

  didMount = async () => {
    try {
      const cats = await FosterService.getMyAdoptions();

      runInAction(() => {
        this.catList = cats;
      });

    } catch (err) {
      infoStore.show({ message: '無法取得會員中心資訊', type: 'warn' });
    }
  };

  logout = async () => {
    await UserService.logout();
    this.router.navigate(Router.Auth.Login);
  };
}
