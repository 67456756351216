import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Select, Form, Input } from 'antd';

import Breadcrumb from 'src/components/Breadcrumb';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import UploadWithCrop from 'src/components/UploadWithCrop';
import { Router, TaiwanCountyTxt } from 'src/constants';
import ProfileVM from './viewModel';

import styles from './styles.module.scss';

@observer
class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ProfileVM(this.props.form, this.props.router);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '帳號管理' }
          ]}
        />

        <div className={styles.main}>
          <div className={styles.box}>
            <Form
              form={this.vm.form}
              layout="vertical"
            >
              <Form.Item
                label="手機號碼(未來將作為帳號登入使用)"
                name="phone"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={(
                  <div>
                    <div>姓名／組織名稱</div>
                    <div className={styles.extra}>欲修改請洽平臺客服信箱</div>
                  </div>
                )}
                name="name"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={(
                  <div>
                    <div>身份證字號／護照號碼／統一編號</div>
                    <div className={styles.extra}>欲修改請洽平臺客服信箱</div>
                  </div>
                )}
                name="idNumber"
              >
                <Input disabled />
              </Form.Item>
              <div className={styles.fieldGap}>個人大頭貼</div>
              {this.vm.isMounted && (
                <UploadWithCrop
                  listType="picture-card"
                  maxCount={1}
                  onChange={this.vm.handleUploadChange}
                  defaultFileList={this.vm.defaultAvatar}
                />
              )}
              <div className={styles.formGap} />
              <Form.Item
                label="暱稱"
                name="nickname"
                rules={[{ required: true, message: '暱稱必填' }]}
              >
                <Input placeholder="請輸入暱稱" maxLength={20} />
              </Form.Item>
              <Form.Item
                label="地區"
                name="region"
                rules={[{ required: true, message: '地區必填' }]}
              >
                <Select
                  placeholder="請選擇所在地區"
                  options={
                  Object.keys(TaiwanCountyTxt).map((TaiwanCounty) => (
                    { value: TaiwanCounty, label: TaiwanCountyTxt[TaiwanCounty] }
                  ))
              }
                />
              </Form.Item>
              <Form.Item
                label="email"
                name="email"
                rules={[{ required: true, message: 'email必填' }]}
              >
                <Input placeholder="請輸入email" />
              </Form.Item>
              <Form.Item
                label="備用聯絡電話"
                name="contactNumber"
              >
                <Input placeholder="請輸入聯絡電話" />
              </Form.Item>
              <Form.Item
                label={(
                  <div>
                    <div>簡介</div>
                    <div className={styles.extra}>
                      此欄位主要為「
                      <span className={styles.bold}>出養人</span>
                      」使用。簡介內容將會出現在您刊登出養的貓咪資料下方，讓認養人知道貓咪屬的出養人/單位。
                    </div>
                  </div>
                )}
                name="selfIntro"
              >
                <Input.TextArea
                  placeholder="若您想要在此平台進行貓咪出養，建議可以仔細填寫您的個人介紹或單位介紹，讓認養人更理解您對出養貓咪的需求及理念。"
                  maxLength={300}
                  rows={6}
                  showCount
                />
              </Form.Item>
            </Form>

            <div className={styles.row}>
              <Button className={styles.secondary} onClick={this.vm.logout}>登出</Button>
              <Button className={styles.secondary} onClick={this.vm.resetPwd}>重設密碼</Button>
              <Button className={styles.primary} onClick={this.vm.update}>儲存</Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ProfilePage.propTypes = {
  form: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  profile: PropTypes.object
};

const ProfilePageHOC = (props) => {
  const [form] = Form.useForm();

  return (
    <ProfilePage
      form={form}
      {...props}
    />
  );
};



export default withRouter(withProfile(ProfilePageHOC, true));
