import { useState, useCallback, useMemo } from 'react';

export default function useModal() {
  const [data, setData] = useState(null);

  const close = useCallback(() => { setData(null); }, []);
  const open = useCallback((d = true) => { setData(d); }, []);

  return useMemo(() => ({ data, close, open }), [data, close, open]);
}
