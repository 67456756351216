import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import dayjs from 'dayjs';
import { message } from 'antd';
import { clientConfirmStore } from 'src/stores/confirmStore';
import infoStore from 'src/stores/infoStore';
import FosterService from 'src/services/foster';
import ApplicationService from 'src/services/application';
import UserService from 'src/services/user';
import { ApplicationAction, ApplicationStatus } from 'src/constants';

export default class FosterItemApplicationVM {
  fosterId;
  applicationId;
  profile;
  @observable foster;
  @observable application;
  @observable applicant;
  @observable commentPairId;
  @observable matchedComment;
  @observable canceledComment;
  @observable showCancelInput = false;
  @observable isPageLoading = false;
  @observable hasPermission = false;


  constructor({ fosterId, applicationId, profile }) {
    makeObservable(this);

    this.fosterId = fosterId;
    this.applicationId = applicationId;
    this.profile = profile;
  }

  didMount = async () => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      const [foster, application, commentPair] = await Promise.all([
        FosterService.getFosterById(this.fosterId),
        ApplicationService.getByApplicationId(this.applicationId),
        ApplicationService.getComment(this.applicationId)
      ]);

      // check user permission
      if (this.profile.isAdmin || this.profile.id === foster.fosterPerson.id) {
        runInAction(() => { this.hasPermission = true; });
      } else {
        runInAction(() => { this.isPageLoading = false; });
        return;
      }

      const applicant = await UserService.getApplicant(application.applicantId);

      runInAction(() => {
        this.foster = foster;
        this.application = application;
        this.applicant = applicant;
        this.matchedComment = commentPair?.matchedComment ?? null;
        this.canceledComment = commentPair?.canceledComment ?? null;
        this.commentPairId = commentPair?._id ?? null;
        this.isPageLoading = false;
      });

    } catch {
      runInAction(() => { this.isPageLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }

  };

  @computed get text() {
    switch (this.application?.status) {

      case ApplicationStatus.CancelAdoption:
      case ApplicationStatus.CancelAdoptionConfirmation:
        return '退養';

      case ApplicationStatus.CancelFoster:
      case ApplicationStatus.CancelFosterConfirmation:
        return '取消出養';

      case ApplicationStatus.Ended:
        return '死亡';

      default:
        return '';
    }
  }

  @computed get canCreateMatchedComment() {
    if (!this.application || !this.application.matchedAt) return false;
    return !this.matchedComment && dayjs().isBefore(dayjs(this.application.matchedAt).add(14, 'days').endOf('D'));
  }
  @computed get canCreateCanceledComment() {
    if (!this.application || !this.application.canceledAt) return false;
    return !this.canceledComment && dayjs().isBefore(dayjs(this.application.canceledAt).add(14, 'days').endOf('D'));
  }
  @computed get isMatchedCommentVisible() {
    if (!this.application || !this.application.matchedAt) return false;
    if (dayjs().isBefore(dayjs(this.application.matchedAt).add(14, 'days').endOf('D'))) return !!this.matchedComment;
    return true;
  }
  @computed get isCanceledVisible() {
    if (!this.application || !this.application.canceledAt) return false;
    if (dayjs().isBefore(dayjs(this.application.canceledAt).add(14, 'days').endOf('D'))) return !!this.canceledComment;
    return true;
  }

  @computed get canDeleteContract() {
    return this.application.status === ApplicationStatus.InContract;
  }

  refresh = async () => {
    const application = await ApplicationService.getByApplicationId(this.applicationId);
    runInAction(() => { this.application = application; });
  };

  uploadContract = async ({ file, fileList }) => {
    if (file.status !== 'done') return;

    if (this.application.contracts.length >= 10) {
      infoStore.show({ message: '簽約檔案數量已達上限', type: 'warn' });
      return;
    }

    try {
      await ApplicationService.uploadContract(this.applicationId, file.originFileObj, file.name);
      await this.refresh();

    } catch (err) {
      let _message = '';
      switch (err?.response?.status) {
        case 401:
          _message = '您尚未登入';
          break;
        case 403:
          _message = '您沒有權限執行這個動作';
          break;
        case 404:
          _message = '認養申請不存在';
          break;
        case 406:
          _message = '簽約檔案數量已達上限';
          break;
        case 419:
          _message = '當前狀態不允許上傳檔案';
          break;
        default:
          _message = '發生錯誤，無法上傳檔案';
      }
      infoStore.show({ message: _message, type: 'warn' });
    }
  };

  deleteContract = async (fileName) => {
    try {
      await ApplicationService.deleteContract(this.applicationId, fileName);
      await this.refresh();

    } catch (err) {
      let _message = '';
      switch (err?.response?.status) {
        case 401:
          _message = '您尚未登入';
          break;
        case 403:
          _message = '您沒有權限執行這個動作';
          break;
        case 404:
          _message = '認養申請不存在';
          break;
        case 419:
          _message = '當前狀態不允許刪除檔案';
          break;
        default:
          _message = '發生錯誤，無法刪除檔案';
      }
      infoStore.show({ message: _message, type: 'warn' });
    }
  };

  contact = async () => {
    clientConfirmStore.show(({
      title: '確定要通知申請人嗎？',
      message: '系統將會以簡訊通知，告知認養申請人，你即將在近期進行電話聯繫。確認要發出通知嗎？',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.Contact }
          );

          await this.refresh();
          message.open({ type: 'success', content: '已通知申請人保持聯繫' });
        } catch (err) {
          this.errorHandler(err);
        }

      }
    }));
  };

  designation = async (render) => {
    clientConfirmStore.show(({
      title: '確定鎖定此認養人進行簽約嗎？',
      render,
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.Designation }
          );
          await this.refresh();
          message.open({ type: 'success', content: '已通知申請人您欲與他進行簽約洽談' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  confirmApplication = async () => {
    clientConfirmStore.show(({
      title: '確定要出養，並請申請人核對＂雙方已簽名＂的合約文件嗎？',
      message: '系統將會以簡訊通知，提醒認養申請人至平台核對雙方已簽名之認養合約，您將等候認養申請人的核對結果。送出後不可收回，確認要繼續嗎？',
      callBack: async () => {
        try {
          if (!this.application.contracts || this.application.contracts.length === 0) {
            infoStore.show({
              message: '缺少簽約檔案，無法出養',
              type: 'warn'
            });
            return;
          }
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.ConfirmApplication }
          );
          await this.refresh();
          message.open({ type: 'success', content: '已通知申請人核對合約文件' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  declineApplication = async () => {
    clientConfirmStore.show(({
      title: '確定要婉拒申請嗎？',
      message: '系統將會以簡訊通知申請人，婉拒後不可收回。確認要繼續嗎？\n（備註：申請人被拒絕後仍有權再次提出申請）',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.DeclineApplication }
          );
          await this.refresh();
          message.open({ type: 'success', content: '已婉拒申請！' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  cancelFoster = async ({ cancelOption, cancelDescription }) => {
    clientConfirmStore.show(({
      title: '確定要送出取消出養申請嗎？',
      message: '系統將會以簡訊通知，提醒申請人至平台確認是否同意您的出養取消申請，您將等候申請人的回覆。\n送出後不可收回，確認要繼續嗎？',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            {
              action: ApplicationAction.CancelFoster,
              cancelOption,
              cancelDescription
            }
          );
          runInAction(() => { this.showCancelInput = false; });
          await this.refresh();
          message.open({ type: 'success', content: '已通知申請人確認出養取消申請' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  refuseCancelAdoption = async () => {
    clientConfirmStore.show(({
      title: '確定要送出拒絕退養申請嗎？',
      message: '系統將會以簡訊通知認養人您已拒絕退養申請，確認要繼續嗎？\n（備註：認養人被拒絕後仍有權再次提出申請）',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.RefuseCancelAdoption }
          );
          await this.refresh();
          message.open({ type: 'success', content: '已拒絕退養申請' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  confirmCancelAdoption = async () => {
    clientConfirmStore.show(({
      title: '確定要送出同意退養申請嗎？',
      message: '同意後不可收回，確認要繼續嗎？',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.ConfirmCancelAdoption }
          );
          await this.refresh();
          message.open({ type: 'success', content: '已同意退養申請' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  createComment = async ({
    positiveRating,
    positiveComment,
    negativeRating,
    negativeComment
  }, type) => {
    try {
      await ApplicationService.createComment(
        this.applicationId,
        {
          positiveRating,
          positiveComment,
          negativeRating,
          negativeComment,
          type
        }
      );

      const comments = await ApplicationService.getComment(this.applicationId);
      message.open({ type: 'success', content: '已送出評價！' });

      runInAction(() => {
        this.matchedComment = comments?.matchedComment ?? null;
        this.canceledComment = comments?.canceledComment ?? null;

        const commentCount = this.applicant?.commentCount ?? undefined;
        if (commentCount !== undefined) {
          this.applicant.commentCount += 1;
        }
      });

    } catch (err) {
      infoStore.show({ message: '發生錯誤，無法新增評價', type: 'warn' });
    }
  };

  errorHandler = (err) => {
    let _message = '';
    switch (err?.response?.status) {
      case 400:
        _message = '認養申請不允許變更狀態';
        break;
      case 401:
        _message = '您尚未登入';
        break;
      case 403:
        _message = '您沒有權限執行這個動作';
        break;
      case 404:
        _message = '認養申請不存在';
        break;
      default:
        _message = '發生錯誤';
    }
    infoStore.show({ message: _message, type: 'warn' });
  };

  @action OnShowCancelInputChange = () => {
    this.showCancelInput = true;
  };
}
