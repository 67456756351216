import React from 'react';
import PropTypes from 'prop-types';
import styles from './GridTable.module.scss';


function Item(props) {
  return (
    <div className={styles.item}>
      <div className={styles.key}>{props.title}</div>
      <div className={styles.value}>{props.value ?? '-'}</div>
    </div>
  );
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};


function GridTable(props) {
  return (
    <div className={styles.table}>

      {props.shortList && (
        <div className={styles.box}>
          {props.shortList.map((item) => (
            <Item key={item.id || item.key} title={item.key} value={item.value} />
          ))}
        </div>
      )}

      {props.longList && (
        <div>
          {props.longList.map((item) => (
            <Item key={item.id || item.key} title={item.key} value={item.value} />
          ))}
        </div>
      )}

    </div>
  );
}


GridTable.propTypes = {
  shortList: PropTypes.array,
  longList: PropTypes.array
};

export default GridTable;
