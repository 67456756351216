import { request, getHost } from './api';
import AuthService from './auth';

export default class ApplicationService {

  /**
   * [B] 後台＿會員詳情＿認養申請列表
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.userId
   */
  static async getApplicationByUser(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/application/user`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }



  /**
   * [C] 前台＿會員中心＿申請列表
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   */
  static async getMyApplication(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/application`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿會員中心＿申請詳情
   * @param {string} applicationId
   */
  static async getByApplicationId(applicationId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/application/${applicationId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] 前台＿會員中心＿申請詳情＿更新申請狀態
   * @param {string} applicationId
   * @param {object} data
   * @param {string} data.action enum: ApplicationAction
   * @param {string} data.cancelOption
   * @param {string} data.cancelDescription
   */
  static async updateApplicationStatus(applicationId, data) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/application/${applicationId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] 前台＿會員中心＿申請詳情＿寄提醒簡訊
   * @param {string} applicationId
   */
  static async resendSms(applicationId) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/application/${applicationId}/resend-sms`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   * [C] 前台＿會員中心＿申請詳情＿上傳合約
   * @param {string} applicationId
   * @param {object} contract
   * @param {string} assignedFileName
   */
  static async uploadContract(applicationId, contract, assignedFileName) {
    const formData = new FormData();
    formData.append('contract', contract);
    formData.append('assignedFileName', assignedFileName);

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/application/${applicationId}/upload-contract`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿會員中心＿申請詳情＿刪除合約
   * @param {string} applicationId
   * @param {string} fileName
   */
  static async deleteContract(applicationId, fileName) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/application/${applicationId}/delete-contract`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        fileName
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] 前台＿會員中心＿申請詳情＿新增留言
   * @param {string} applicationId
   * @param {object} data
   * @param {number} data.positiveRating
   * @param {string} data.positiveComment
   * @param {number} data.negativeRating
   * @param {string} data.negativeComment
   * @param {string} data.type enum:matchedComment,canceledComment
   */
  static async createComment(applicationId, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/application/${applicationId}/comment`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] 前台＿會員中心＿申請詳情＿取得留言
   * @param {string} applicationId
   */
  static async getComment(applicationId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/application/${applicationId}/comment`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }
}
