
export const v1 = {
  GenderTxt: {
    Boy: '是男森',
    Girl: '是女森',
    Unknown: '還不確定'
  },
  ObservationFrequencyTxt: {
    7: '七天',
    14: '十四天',
    30: '三十天'
  },
  StabilityFrequencyTxt: {
    0: '自發回報',
    14: '十四天',
    30: '三十天',
    60: '六十天',
    90: '九十天'
  }
};

export const v2 = {
  GenderTxt: {
    Boy: '男',
    Girl: '女',
    Unknown: '還不確定'
  },
  ObservationFrequencyTxt: {
    7: '7',
    14: '14',
    30: '30'
  },
  StabilityFrequencyTxt: {
    0: '自發回報',
    14: '14日',
    30: '30日',
    60: '60日',
    90: '90日'
  }
};
