import { request, getHost } from './api';
import AuthService from './auth';

export default class EditorContentService {

  static async get(type) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/content`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: { type }
    };
    const res = await request(options);
    return res.data.content;
  }


  static async update(type, content) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/content`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        type,
        content
      }
    };
    const res = await request(options);
    return res.data;
  }

}
