import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Router } from 'src/constants';
import withProfile from 'src/components/withProfile';
import Breadcrumb from 'src/components/Breadcrumb';
import TabBar from 'src/components/TabBar';

import EventTab from './components/EventTab';
import TicketTab from './components/TicketTab';
import UserEventVM from './viewModel';
import styles from './styles.module.scss';

@observer
class EventPage extends React.Component {
  constructor() {
    super();
    this.vm = new UserEventVM();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '我的抽獎券' }
          ]}
        />

        <div className={styles.header}>
          <div className={styles.head_box}>

            <div className={styles.sticker}>
              <div>抽獎券</div>
              <div className={styles.primary}>{this.props.profile.ticketCount ?? ''}</div>
              <div className={styles.light}>張</div>
            </div>

          </div>
        </div>


        <div className={styles.main}>
          <div className={styles.box}>

            <TabBar
              onChange={this.vm.onTabChange}
              items={[
                { key: 'event', label: '抽獎紀錄' },
                { key: 'ticket', label: '獲取紀錄' }
              ]}
            />

            {this.vm.activeTab === 'event' && <EventTab vm={this.vm} />}
            {this.vm.activeTab === 'ticket' && <TicketTab vm={this.vm} />}

          </div>
        </div>

      </>
    );
  }
}

EventPage.propTypes = {
  profile: PropTypes.object.isRequired
};

export default withProfile(EventPage, true);
