import { makeObservable, observable, action, computed } from 'mobx';

class ErrorStore {
  @observable isShow = false;
  @observable messages = [];

  constructor() {
    makeObservable(this);
  }

  @action
    show = (message) => {
      this.isShow = true;
      this.messages.push(message);
    };

  @action
    close = () => {
      this.isShow = false;
      this.messages = [];
    };
}

export default new ErrorStore();
