import { message } from 'antd';
import Cookies from 'js-cookie';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { Router, VerificationType } from 'src/constants';
import UserService from 'src/services/user';
import infoStore from 'src/stores/infoStore';

export default class VerifyVM {
  router;
  @observable type = VerificationType.RestPwd;
  @observable phone;
  @observable code;
  @observable isLoading = false;
  @observable isVerifying = false;
  @observable isCodeSent = false;

  constructor(type, router) {
    makeObservable(this);
    this.router = router;

    runInAction(() => { this.type = type; });
  }

  didMount = () => {};

  getVerificationCode = async () => {
    if (!this.phone) {
      message.error('手機號碼必填');
      return;
    }

    if (!/09\d{8}/g.test(this.phone)) {
      infoStore.show({
        message: '請填入有效的手機號碼',
        type: 'warn'
      });
      return;
    }

    try {
      runInAction(() => { this.isLoading = true; });

      await UserService.getVerificationCode(this.phone, this.type);

      runInAction(() => {
        this.isCodeSent = true;
        this.isLoading = false;
      });

      message.success('驗證碼已寄送');

    } catch (err) {
      let _message = '';
      switch (err?.response?.status) {
        case 404:
          _message = '帳號不存在';
          break;
        case 406:
          _message = '簡訊服務發生錯誤，無法寄出簡訊';
          break;
        case 409:
          _message = '帳號已存在';
          break;
        default:
          _message = '發生錯誤';
      }
      infoStore.show({ message: _message, type: 'warn' });
      runInAction(() => { this.isLoading = false; });
    }
  };


  VerifyCode = async () => {
    if (!this.code) {
      message.error('驗證碼必填');
      return;
    }

    if (this.code.length !== 6) return;

    try {
      runInAction(() => { this.isVerifying = true; });

      const token = await UserService.verifySmsCode(this.phone, this.type, this.code);
      Cookies.set(this.type, token, { expires: 1 });

      runInAction(() => { this.isVerifying = false; });

      if (this.type === VerificationType.Register) {
        this.router.navigate(Router.Auth.Register, { state: { phone: this.phone } });
      } else {
        this.router.navigate(Router.Auth.RestPwd);
      }

    } catch (err) {
      let _message = '';
      switch (err?.response?.status) {
        case 404:
          _message = '驗證碼不存在';
          break;
        case 409:
          _message = '驗證碼錯誤';
          break;
        default:
          _message = '發生錯誤';
      }
      infoStore.show({ message: _message, type: 'warn' });
      runInAction(() => { this.isVerifying = false; });
    }
  };

  @action toModifyPhone = () => {
    this.isCodeSent = false;
  };

  @action onPhoneChange = (e) => {
    this.phone = e.target.value;
  };

  @action onCodeChange = (newCode) => {
    this.code = newCode;
  };

}
