import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import errorStore from 'src/stores/errorStore';
import UserService from 'src/services/user';
import ApplicationService from 'src/services/application';
import CommentService from 'src/services/comment';

export default class UserProfileViewModel {
  form = null; // antd form instance
  userId = null;
  @observable user = null;
  @observable applications = {
    isLoading: false,
    list: [],
    anchor: null,
    limit: 20
  };
  @observable comments = {
    isLoading: false,
    list: [],
    anchor: null,
    limit: 20
  };


  constructor(userId) {
    makeObservable(this);
    this.userId = userId;
  }


  profileDidMount = async (form) => {
    try {
      const user = await UserService.getUserById(this.userId, true);
      runInAction(() => { this.user = user; });

      this.form = form;
      this.form.setFieldsValue(user);

    } catch {
      errorStore.show('發生錯誤，無法取得會員資料');
    }
  };


  profileUpdate = async () => {
    const data = this.form.getFieldsValue();

    try {
      await UserService.updateUserProfile(
        this.userId,
        {
          isAdmin: data.isAdmin,
          name: data.name,
          nickname: data.nickname,
          region: data.region,
          email: data.email,
          contactNumber: data.contactNumber ?? '',
          idNumber: data.idNumber,
          selfIntro: data.selfIntro
        }
      );
      message.open({
        type: 'success',
        content: '會員資料已更新'
      });

    } catch {
      errorStore.show('發生錯誤，無法更新會員資料');
    }


  };


  applicationDidMount = async () => {
    try {
      const { list, anchor } = await ApplicationService.getApplicationByUser({
        userId: this.userId,
        limit: this.applications.limit
      });

      runInAction(() => {
        this.applications.list = list;
        this.applications.anchor = anchor;
      });

    } catch {
      errorStore.show('發生錯誤，無法取得會員認養申請資訊');
    }
  };


  applicationOnNextPage = async () => {
    if (this.applications.isLoading || !this.applications.anchor) return;

    runInAction(() => { this.applications.isLoading = true; });

    try {
      const { list, anchor } = await ApplicationService.getApplicationByUser({
        userId: this.userId,
        anchor: this.applications.anchor,
        limit: this.applications.limit
      });

      runInAction(() => {
        this.applications.list = this.applications.list.concat(list);
        this.applications.anchor = anchor;
        this.applications.isLoading = false;
      });
    } catch {
      runInAction(() => { this.applications.isLoading = false; });
      errorStore.show('發生錯誤，無法取得會員認養申請資訊');
    }
  };


  commentDidMount = async () => {
    try {
      const { list, anchor } = await CommentService.getCommentByUser({
        userId: this.userId,
        limit: this.comments.limit
      });

      runInAction(() => {
        this.comments.list = list;
        this.comments.anchor = anchor;
      });

    } catch {
      errorStore.show('發生錯誤，無法取得會員評價');
    }
  };


  commentOnNextPage = async () => {
    if (this.comments.isLoading || !this.comments.anchor) return;

    runInAction(() => { this.comments.isLoading = true; });

    try {
      const { list, anchor } = await CommentService.getCommentByUser({
        userId: this.userId,
        anchor: this.comments.anchor,
        limit: this.comments.limit
      });

      runInAction(() => {
        this.comments.list = this.comments.list.concat(list);
        this.comments.anchor = anchor;
        this.comments.isLoading = false;
      });
    } catch {
      runInAction(() => { this.comments.isLoading = false; });
      errorStore.show('發生錯誤，無法取得會員評價');
    }
  };
}
