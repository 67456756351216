import { makeObservable } from 'mobx';

import FosterService from 'src/services/foster';
import AntdTablePager from 'src/models/AntdTablePager';
import errorStore from 'src/stores/errorStore';

export default class FosterViewModel {
  pager;

  params = {
    status: undefined,
    region: undefined,
    keyword: undefined
  };

  constructor() {
    makeObservable(this);

    this.pager = new AntdTablePager(this.query, 20);
  }

  didMount = async () => {
    await this.pager.next(true);
  };

  query = async (limit, anchor) => {
    try {
      const { list, anchor: nextAnchor } = await FosterService.getBackstageList({ ...this.params, limit, anchor });
      return { list, anchor: nextAnchor };

    } catch (err) {
      errorStore.show('發生錯誤，無法取得資訊');
      return { list: [], anchor: null };
    }
  };

  onStatusChange = (value) => {
    this.params.status = value;
    this.pager.next(true);
  };

  onRegionChange = (value) => {
    this.params.region = value;
    this.pager.next(true);
  };

  onKeywordSearch = (value) => {
    this.params.keyword = value;
    this.pager.next(true);
  };
}
