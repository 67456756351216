import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Input } from 'antd';

import VerificationInput from 'src/components/VerificationInput';
import withRouter from 'src/components/withRouter';
import { Router } from 'src/constants';

import VerifyVM from './viewModel';
import styles from './styles.module.scss';

@observer
class Verify extends React.Component {
  constructor(props) {
    super(props);

    const [searchParams] = this.props.router.searchParams;
    const type = searchParams.get('type');
    this.type = type;
    this.vm = new VerifyVM(type, this.props.router);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  getPageTitle() {
    if (this.type === 'register') return '註冊';
    return '重設密碼';
  }

  getPhoneTitle() {
    if (this.type === 'register') return '手機號碼（未來將作為帳號登入使用）';
    return '手機號碼';
  }


  renderGetPhone() {
    return (
      <>
        <div className={styles.title}>{this.getPageTitle()}</div>

        <div className={styles.row}>
          <div className={styles.field}>{this.getPhoneTitle()}</div>
          <Input
            placeholder="請輸入手機號碼，ex. 0988888888"
            value={this.vm.phone}
            onChange={this.vm.onPhoneChange}
          />
        </div>

        <Button
          className={styles.primaryBtn}
          loading={this.vm.isLoading}
          onClick={this.vm.getVerificationCode}
        >
          取得驗證碼
        </Button>
        <Button
          className={styles.secondaryBrn}
          href={Router.Auth.Login}
        >
          已經是會員？前往登入
        </Button>
      </>
    );
  }
  renderGetCode() {
    return (
      <>
        <div className={styles.title}>輸入手機驗證碼</div>

        <div className={styles.row}>
          <div className={styles.field}>
            請到此手機
            <span className={styles.link}>{this.vm.phone}</span>
            收取驗證通知，並輸入驗證碼。
          </div>
          <VerificationInput
            value={this.vm.code}
            onChange={this.vm.onCodeChange}
          />
        </div>

        <Button
          className={styles.primaryBtn}
          loading={this.vm.isVerifying}
          onClick={this.vm.VerifyCode}
        >
          下一步
        </Button>

        <div className={styles.note}>
          沒有收到驗證碼嗎？
          <div>
            <Button type="link" className={styles.link} onClick={this.vm.getVerificationCode}>重新發送</Button>
            or
            <Button type="link" className={styles.link} onClick={this.vm.toModifyPhone}>修改號碼</Button>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.box}>
          <div className={styles.content}>
            {this.vm.isCodeSent
              ? this.renderGetCode()
              : this.renderGetPhone()}
          </div>
        </div>
      </div>
    );
  }
}

Verify.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(Verify);
