import dayjs from 'dayjs';
import { request, getHost } from './api';
import AuthService from './auth';

export default class FosterService {

  /**
   * [B] 後台＿出養查詢與管理
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string} data.status
   * @param {string[]} data.region
   */
  static async getBackstageList(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/foster/backstage`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿貓咪列表
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string[]} data.region
   * @param {string[]} data.coloration
   * @param {number} data.minAge
   * @param {number} data.maxAge
   * @param {string} data.gender
   * @param {string} data.sort
   */
  static async getFrontendList(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/foster/frontend`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿出養管理列表
   * @param {Object} data
   * @param {string} data.status  - enum: Ongoing, Ended
   * @param {string} data.keyword
   */
  static async getMyFosters(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/foster`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] 前台＿出養管理列表__取得上一筆出養資訊
   */
  static async getMyLatestFoster() {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/foster/latest`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿出養管理＿出養詳情
   * @param {string} fosterId
   */
  static async getFosterById(fosterId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/foster/${fosterId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * TODO 詳列data
   * [C] 前台＿出養管理＿新增出養案件
   * @param {object} data
   */
  static async createFoster(data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (Array.isArray(value) && value.length > 0) {
        for (let i = 0; i < value.length; i += 1) {
          formData.append(key, value[i]);
        }
      } else if (dayjs.isDayjs(value)) {
        formData.append(key, value.toISOString());
      } else if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/foster`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * TODO 詳列data
   * [C] 前台＿出養管理＿更新出養案件
   * @param {string} fosterId
   * @param {object} data
   */
  static async updateFoster(fosterId, data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (Array.isArray(value) && value.length > 0) {
        for (let i = 0; i < value.length; i += 1) {
          formData.append(key, value[i]);
        }
      } else if (dayjs.isDayjs(value)) {
        formData.append(key, value.toISOString());
      } else if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/foster/${fosterId}/info`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] 前台＿出養管理＿更新出養案件上下架狀態
   * @param {string} fosterId
   * @param {'Available'|'Unavailable'|'Ended'} status
   */
  static async updateFosterStatus(fosterId, status) {

    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/foster/${fosterId}/status`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        status
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿出養管理＿凍結
   * 若該出養案有任一認養申請狀態為審核中、已媒合、媒合取消確認中
   * 則無法更新出養資料
   *
   * @param {string} fosterId
   */
  static async isFosterFreeze(fosterId) {

    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/foster/${fosterId}/isFreeze`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿出養管理＿申請認養列表
   * @param {string} fosterId
   */
  static async getApplicationByFoster(fosterId, params) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/foster/${fosterId}/application`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿出養管理＿申請認養列表＿已讀
   * @param {string} fosterId
   * @param {string} applicationId
   */
  static async readApplication(fosterId, applicationId) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/foster/${fosterId}/${applicationId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿會員中心＿認養列表
   */
  static async getMyAdoptions() {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/foster/adoption`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿申請認養
   * @param {string} fosterId
   * @param {string} message
   */
  static async apply(fosterId, message) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/foster/${fosterId}/apply`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        message
      }
    };
    const res = await request(options);
    return res.data;
  }




}
