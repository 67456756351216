import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styles from './styles.module.scss';

export default function Loading(props) {
  return (
    <div className={styles.main}>
      <div className={styles.box}>
        <Spin
          indicator={(
            <LoadingOutlined
              style={{ fontSize: 60 }}
              spin
            />
          )}
        />
      </div>
    </div>
  );
}
