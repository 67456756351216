import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import infoStore from 'src/stores/infoStore';
import { Router } from 'src/constants';
import withRouter from 'src/components/withRouter';
import { intersectionObserverOption } from 'src/utils';

import styles from '../styles.module.scss';

@observer
class EventTab extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.props.vm.onEventNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.observer.observe(this.ref.current);
  }

  render() {
    return (
      <>
        <div className={styles.events}>
          {this.props.vm.event.list.map((e, index) => (
            <div key={e.id}>
              <div className={styles.row} style={{ backgroundColor: index % 2 === 1 ? '#F5F5F5' : '' }}>
                <div className={styles.partOne}>
                  <div>
                    活動編號
                    <span className={styles.sapn}>{e.raffleEvent.serialNumber}</span>
                  </div>
                  <div>
                    投入張數
                    <span className={styles.sapn}>{e.count}</span>
                  </div>
                </div>
                <div className={styles.partTwo}>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (e.raffleEvent.isDeleted) {
                        infoStore.show({
                          message: '此活動已刪除，若有疑問歡迎使用平台客服信箱，我們會盡快處理！',
                          type: 'warn'
                        });
                      } else if (!e.raffleEvent.isShow) {
                        infoStore.show({
                          message: '此活動已下架，若有疑問歡迎使用平台客服信箱，我們會盡快處理！',
                          type: 'warn'
                        });
                      } else {
                        this.props.router.navigate(`${Router.Client.EventList}/${e.raffleEvent.id}`);
                      }
                    }}
                  >
                    {e.raffleEvent.title}
                  </div>
                  <div className={cn(styles.tag, { [styles.blueTag]: e.raffleEvent.isDrawn })}>{e.raffleEvent.isDrawn ? '已開獎' : '未開獎'}</div>
                </div>
              </div>
              <div className={styles.divider} />
            </div>
          ))}
        </div>
        <div ref={this.ref} style={{ height: 10 }} />
      </>
    );
  }
}

EventTab.propTypes = {
  vm: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withRouter(EventTab);
