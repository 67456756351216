import { makeObservable, observable, action, computed } from 'mobx';

class InfoStore {
  @observable isShow = false;
  @observable message = '';
  @observable type = 'info';

  constructor() {
    makeObservable(this);
  }

  @action
    show = ({ message, type = 'info' }) => {
      this.isShow = true;
      this.message = message;
      this.type = type;
    };

  @action
    onClose = () => {
      this.isShow = false;
      this.message = '';
      this.type = 'info';
    };
}

export default new InfoStore();
