import { useCallback, useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { observer } from 'mobx-react';
import { message, Avatar } from 'antd';

import { AppStateContext } from 'src/stores';
import useAnchorAPI from 'src/hooks/useAnchorAPI';
import useDidMount from 'src/hooks/useDidMount';
import useInterval from 'src/hooks/useInterval';
import useProfile from 'src/hooks/useProfile';
import MessageService from 'src/services/message';
import UserService from 'src/services/user';
import Breadcrumb from 'src/components/Breadcrumb';
import { Router } from 'src/constants';

import CreatePanel from './components/CreatePanel';
import { Attachment, Foster, Card } from './components/Message';
import styles from './styles.module.scss';

const Limit = 20;

const MessagePage = observer(() => {
  const { actions } = useContext(AppStateContext);
  /**
   * 是否為旁觀者 (for admin)
   *
   * @type {boolean|null}
   */
  const [isBystander, setIsBystander] = useState(null);
  const [user, setUser] = useState(null);
  const [box, setBox] = useState(null);
  const [fosterId, setFosterId] = useState(null);
  const lastFetchAtRef = useRef(0);

  const profile = useProfile(true);
  const navigate = useNavigate();
  const { boxId } = useParams();
  const [searchParams] = useSearchParams();

  /**
   * mark as read & get unread count
   */
  const unreadTask = useCallback(
    async (force = false) => {
      // ! Note: isBystander maybe null
      if (force || isBystander === false) {
        const unread = await MessageService.markAsRead(boxId).then(MessageService.getUnreadCount).catch(() => ({ count: 0 }));
        actions.updateUnreadCount(unread.count);
      }
    },
    [boxId, actions, isBystander]
  );

  /**
   * init fetch
   */
  useEffect(
    () => {
      if (!profile) { return () => {}; }

      const fetch = async () => {
        try {
          const detail = await MessageService.getBoxDetail(boxId);
          const bystander = profile.isAdmin && profile.id !== detail.user1.id && profile.id !== detail.user2.id;
          const targetId = detail.user1.id === profile.id ? detail.user2.id : detail.user1.id;

          const [target] = await Promise.all([
            UserService.getUserById(targetId),
            unreadTask(bystander === false)
          ]);

          setIsBystander(bystander);
          setBox(detail);
          setUser(target);
        } catch (err) {
          message.open({ type: 'error', content: '無法取得資訊' });
          navigate(Router.Client.MessageBox);
        }
      };
      fetch();

      return () => {};
    },
    [profile, navigate, boxId, unreadTask]
  );

  const { next, list, loading } = useAnchorAPI(
    (anchor) => MessageService.getMessages(boxId, Limit, anchor),
    (reset, prev, result) => {
      lastFetchAtRef.current = Date.now();
      const parse = (msg) => ({
        id: msg.id,
        fromId: msg.fromId,
        toId: msg.toId,
        text: msg.text,
        foster: msg.foster ? Foster.viewmode(msg.foster) : null,
        files: msg.files?.map((url) => Attachment.viewmode(url)) || [],
        images: msg.images?.map((url) => Attachment.viewmode(url)) || [],
        createAt: msg.createAt
      });
      return reset ? result.list.map(parse) : [...prev, ...result.list.map(parse)];
    },
    (err) => {
      message.open({ type: 'error', content: '發生錯誤，請稍後再試' });
    }
  );

  /**
   * get first page messages & get foster from search params
   */
  useDidMount(
    () => {
      const id = searchParams.get('foster');
      if (id) {
        setFosterId(id);
      }

      next(true);
    }
  );

  /**
   * fetch timer
   */
  useInterval(
    async () => {
      try {
        if (Date.now() - lastFetchAtRef.current < 10 * 60 * 1000) { return; } // 10 mins
        lastFetchAtRef.current = Date.now();
        await next(true);
        await unreadTask();
      } catch (err) {
        // ignore
      }
    },
    1000
  );

  const computeItemKey = useCallback((idx, item) => item.id, []);
  const renderMessage = useCallback(
    (idx, item) => {
      if (!box) { return null; }

      return (
        <Card
          className={styles.message}
          user={item.fromId === box.user1.id ? box.user1 : box.user2}
          data={item}
          isLast={idx === list.length - 1}
        />
      );
    },
    [box, list]
  );

  const onMessageCreate = useCallback(
    async () => {
      try {
        await next(true);
        await unreadTask();
      } catch (err) {
        // ignore
      }
    },
    [next, unreadTask]
  );

  return (
    <>
      <Breadcrumb
        items={[
          { title: '會員中心', link: Router.Client.User },
          { title: '我的留言板', link: Router.Client.MessageBox },
          { title: user?.nickname ?? '-' }
        ]}
      />

      {
        user ? (
          <div className={styles.wrapper}>
            <div className={styles.infoBar}>
              <Avatar
                className={styles.avatar}
                icon={<img src={user.avatar} alt="" />}
              />
              <div className={styles.name}>{ user.nickname ?? '-' }</div>
              {
                user.commentCount ? (
                  <Link className={styles.comment} to={`/user/${user.id}/comment`}>
                    { `閱讀 ${user.commentCount} 則評論 >` }
                  </Link>
                ) : null
              }
            </div>

            {
              isBystander ? null : (
                <CreatePanel
                  className={styles.panel}
                  userId={user.id}
                  fosterId={fosterId}
                  onCreate={onMessageCreate}
                />
              )
            }

            <Virtuoso
              className={styles.list}
              useWindowScroll
              data={list}
              endReached={() => next()}
              computeItemKey={computeItemKey}
              itemContent={renderMessage}
            />
          </div>
        ) : null
      }
    </>
  );
});

export default MessagePage;
