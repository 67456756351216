import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import infoStore from 'src/stores/infoStore';
import withProfile from 'src/components/withProfile';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import Card from 'src/components/Card';
import { parseDate, parseAge, intersectionObserverOption } from 'src/utils';
import { TaiwanCountyTxt, GenderTxt, Router, FosterStatus, ApplicationStatusTxt, ApplicationStatus } from 'src/constants';
import ApplicationListVM from './viewModel';
import styles from './styles.module.scss';

@observer
class ApplicationListPage extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
    this.vm = new ApplicationListVM();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.vm.onNextPage();
      }
    }, intersectionObserverOption);
  }

  componentDidMount() {
    this.vm.didMount();
    this.observer.observe(this.ref.current);
  }

  getRibbon(application) {
    switch (application.status) {
      case ApplicationStatus.ApplicationCanceled:
      case ApplicationStatus.ApplicationDeclined:
      case ApplicationStatus.CancelFoster:
      case ApplicationStatus.CancelAdoption:
      case ApplicationStatus.Expired:
        return {
          label: ApplicationStatusTxt[application.status],
          color: '#A8A29E',
          shodowColor: '#27272A'
        };
      case ApplicationStatus.Ended:
        return {
          label: ApplicationStatusTxt[application.status],
          color: '#BE185D',
          shodowColor: '#831843'
        };
      default:
        return {
          label: ApplicationStatusTxt[application.status],
          color: '#00A5B8',
          shodowColor: '#27272A'
        };
    }
  }

  renderNoCat() {
    return (
      <div className={styles.noCatBox}>
        <div className={styles.noCat}>沒有認養申請的喵咪</div>
      </div>
    );
  }

  renderCatList() {
    return (
      <div className={styles.card_box}>
        {this.vm.list.map((application) => {
          const foster = application.foster;
          const isUnavailable = foster.status === FosterStatus.Unavailable;
          return (
            <div
              key={application.id}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (isUnavailable) {
                  infoStore.show({
                    message: '此貓咪因故已將刊登下架，若有疑問請於出養方或平台聯繫！'
                  });
                }
              }}
            >
              <Card
                to={isUnavailable ? null : `${Router.Client.ApplicationList}/${application.id}`}
                image={foster.images?.[0]}
                title={foster.name || '-'}
                ribbon={this.getRibbon(application)}
                content={(
                  <div className={styles.card_content}>
                    <div>
                      {`出養人：${foster.fosterPerson.nickname ?? '-'}`}
                    </div>
                    <div>
                      {foster.gender && <span>{`${GenderTxt[foster.gender]}, `}</span>}
                      {foster.birthday && <span>{`${parseAge(foster.birthday)}歲, `}</span>}
                      <span>{foster.isNeutered ? '已結紮' : '未結紮'}</span>
                    </div>
                    {foster.fosterPerson.region && <div>{`地區：${TaiwanCountyTxt[foster.fosterPerson.region]}`}</div>}
                    <div>
                      {`提交申請時間：${parseDate(application.submitAt, '-', true)}`}
                    </div>
                  </div>
                )}
                footer={(
                  <div className={styles.time}>
                    狀態變更時間：
                    <span>{parseDate(application.statusUpdateAt, '-', true)}</span>
                  </div>
                )}
              />
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '認養申請' }
          ]}
        />

        <div className={styles.main}>
          <div className={styles.box}>

            {this.vm.isPageLoading
              ? <Loading />
              : (
                <div>
                  {this.vm.hasCat ? this.renderCatList() : this.renderNoCat()}
                </div>
              )}

            <div ref={this.ref} style={{ height: 10 }} />

          </div>
        </div>
      </>
    );
  }
}

ApplicationListPage.propTypes = {
  profile: PropTypes.object
};

export default withProfile(ApplicationListPage, true);
