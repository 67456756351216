import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { ReactComponent as CatPaw } from 'src/assets/catpaw.svg';

import styles from './styles.module.scss';

const ReactionFlag = observer((props) => {
  const { item, size, onReaction } = props;

  const reaction = useCallback((ev) => onReaction(ev, item.id), [item, onReaction]);

  return (
    <div className={cn(styles.reaction, size === 'small' && styles.small, props.className)}>
      <div
        className={styles.wrapper}
        onClick={reaction}
      >
        <div className={styles.background} />
        <CatPaw className={cn(styles.icon, item.isLike && styles.active)} />
        <div className={styles.label}>{ item.reactions }</div>
      </div>
    </div>
  );
});

ReactionFlag.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  onReaction: PropTypes.func.isRequired
};

export default ReactionFlag;
