import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Card.module.scss';
import Ribbon from './Ribbon/index';
import CatPaw from '../CatPaw';

function Card(props) {
  const {
    title,
    content,
    to,
    image,
    footer,
    isImage3to2 = false,
    toNewTab = false,
    ribbon
  } = props;

  const cardContent = useMemo(
    () => (
      <div className={styles.flex_col}>
        <div className={styles.flex_col1}>
          {
            image
              ? (
                <img
                  className={styles.image}
                  src={image}
                  alt=""
                  style={{ aspectRatio: isImage3to2 ? '3 / 2' : '1 / 1' }}
                />
              )
              : <CatPaw style={{ aspectRatio: isImage3to2 && '3 / 2' }} />
          }
          <div className={styles.text}>{title}</div>
          <div className={styles.group}>{content}</div>
        </div>

        {footer && (
          <>
            <div className={styles.line} />
            { footer }
          </>
        )}
      </div>
    ),
    [title, content, image, footer, isImage3to2]
  );

  return (
    <div className={styles.outmostWrapper}>
      {!!ribbon && (
        <Ribbon {...ribbon} />
      )}
      {to
        ? (
          <Link
            className={cn(styles.root, 'card')}
            to={to}
            style={{ textDecoration: 'none' }}
            target={toNewTab ? '_blank' : undefined}
          >
            {cardContent}
          </Link>
        )
        : <div className={cn(styles.root, 'card')}>{cardContent}</div>}
    </div>
  );
}

Card.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  to: PropTypes.string,
  isImage3to2: PropTypes.bool,
  toNewTab: PropTypes.bool,
  ribbon: PropTypes.object
};

export default Card;
