import { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { Router } from 'src/constants';

import styles from './styles.module.scss';

const MemberOnlyModal = (props) => {
  const {
    open,
    onClose = () => {}
  } = props;

  const navigate = useNavigate();
  const onLoginClick = useCallback(
    () => {
      onClose();
      navigate(Router.Auth.Login);
    },
    [onClose, navigate]
  );

  return (
    <Modal
      closeIcon={null}
      onCancel={onClose}
      open={open}
      title={(
        <div className={clsx(styles.row, styles.title)}>
          <ExclamationCircleFilled className={styles.infoIcon} />
          <div>此功能為會員專屬！</div>
        </div>
      )}
      footer={(
        <div className={clsx(styles.row, styles.footer)}>
          <Button
            className={styles.noBtn}
            onClick={onClose}
          >
            留在此頁
          </Button>
          <Button
            className={styles.yesBtn}
            onClick={onLoginClick}
          >
            登入/註冊
          </Button>
        </div>
      )}
    >
      <div className={styles.content}>
        您尚未登入，要前往登入或註冊會員嗎？
      </div>
    </Modal>
  );
};

MemberOnlyModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default MemberOnlyModal;
