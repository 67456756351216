import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';
import { ApplicationStatus } from 'src/constants';
import { parseDate } from 'src/utils';

import styles from './styles.module.scss';

@observer
class CommentPair extends React.Component {
  constructor() {
    super();
  }

  renderCommentSegment(comment, status) {
    const {
      userId,
      commentPair,
      toNewTab = false
    } = this.props;


    return (
      <>
        <div className={styles.solidDivider} />

        <div className={styles.row}>
          <div className={cn(styles.tag, { [styles.blueTag]: status === ApplicationStatus.Matched })}>
            {this.getTxt(status)}
          </div>

          <Link
            className={styles.link}
            to={`/user/${userId}/comment/${commentPair.id}/${comment._id}`}
            target={toNewTab ? '_blank' : ''}
          >
            {`查看留言(${comment.replyCount}) >`}
          </Link>

        </div>

        <div className={styles.gap} />

        <div className={cn(styles.subtitle, styles.blue)}>
          <div>正面評價</div>
          <div>
            {[1, 2, 3, 4, 5].map((index) => (
              <SmileOutlined
                key={index}
                style={{ marginLeft: 5 }}
                className={cn({ [styles.unSelect]: index > comment.positiveRating })}
              />
            ))}
          </div>
        </div>

        <div className={styles.content}>{comment.positiveComment}</div>

        <div className={styles.dottedDivider} />

        <div className={cn(styles.subtitle, styles.orange)}>
          <div>負面評價</div>
          <div>
            {[1, 2, 3, 4, 5].map((index) => (
              <FrownOutlined
                key={index}
                style={{ marginLeft: 5 }}
                className={cn({ [styles.unSelect]: index > comment.negativeRating })}
              />
            ))}
          </div>
        </div>

        <div className={styles.content}>{comment.negativeComment}</div>

        <div className={styles.gap} />

        <div className={styles.row}>
          <div className={styles.content}>{parseDate(comment.createAt, '-')}</div>
          <div>{`by ${commentPair.creator.nickname}`}</div>
        </div>
      </>
    );
  }

  getTxt(status) {
    if (status === ApplicationStatus.Matched) return '媒合成功';
    if (status === ApplicationStatus.CancelAdoption) return '退養';
    if (status === ApplicationStatus.CancelFoster) return '取消出養';
    return '媒合取消';
  }

  render() {
    const {
      userId,
      commentPair
    } = this.props;

    return (
      <div className={styles.commentBox}>
        <div className={styles.title}>
          {`${userId === commentPair.application.applicantId ? '認養' : '出養'} ${commentPair.foster.name}`}
        </div>
        { commentPair.canceledComment && this.renderCommentSegment(commentPair.canceledComment, commentPair.application.status) }
        { commentPair.matchedComment && this.renderCommentSegment(commentPair.matchedComment, ApplicationStatus.Matched) }
      </div>
    );
  }
}

CommentPair.propTypes = {
  userId: PropTypes.string.isRequired,
  commentPair: PropTypes.object.isRequired,
  toNewTab: PropTypes.bool
};

export default CommentPair;
