import React from 'react';
import clsx from 'clsx';
import { observer, Observer } from 'mobx-react';
import { Button, Table, Drawer, Upload, Input, Switch } from 'antd';

import withProfile from 'src/components/withProfile';
import { customUploadRequest } from 'src/utils';
import BannerViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class BannerPage extends React.Component {
  constructor() {
    super();
    this.vm = new BannerViewModel();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  columns = [
    {
      title: '狀態',
      key: 'isShow',
      width: 88,
      render: (data) => (
        <Observer>
          {
            () => (
              <Switch
                checkedChildren="發布"
                unCheckedChildren="隱藏"
                checked={data.isShow}
                onChange={(checked) => this.vm.toggle(data, checked)}
              />
            )
          }
        </Observer>
      )
    },
    {
      title: '名稱',
      key: 'title',
      render: (data) => (<Observer>{() => data.title}</Observer>)
    },
    {
      title: '連結',
      key: 'url',
      render: (data) => (<Observer>{() => data.url}</Observer>)
    },
    {
      title: '操作',
      key: 'action',
      width: 200,
      render: (data) => (
        <>
          <Button type="link" onClick={() => this.vm.openModel(data)}>檢視與編輯</Button>
          <Button type="link" onClick={() => this.vm.delete(data.id)} className={styles.redTxt}>刪除</Button>
        </>
      )
    }
  ];

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.headerBox}>
          <div className={styles.header}>Banner</div>
          <Button
            type="primary"
            onClick={() => this.vm.openModel(null)}
          >
            ＋新增
          </Button>
        </div>

        <Table
          columns={this.columns}
          dataSource={this.vm.pager.section}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: this.vm.pager.limit,
            total: this.vm.pager.total,
            position: ['topRight'],
            showSizeChanger: true,
            pageSizeOptions: ['20', '50', '100'],
            onShowSizeChange: this.vm.pager.onLimitChange
          }}
          onChange={this.vm.pager.onPageChange}
          scroll={{ y: 'calc(100vh - 200px)', x: 768 }}
        />


        <Drawer
          title={`${this.vm.modal.data ? '編輯' : '新增'} Banner`}
          width={700}
          maskClosable={false}
          zIndex={2}
          open={this.vm.modal.isShow}
          onClose={this.vm.closeModel}
          destroyOnClose
          footer={(
            <div className={styles.footer}>
              <Button onClick={this.vm.closeModel}>取消</Button>
              <Button
                type="primary"
                onClick={this.vm.action}
                loading={this.vm.modal.isSaving}
              >
                {`${this.vm.modal.data ? '儲存' : '新增'}`}
              </Button>
            </div>
          )}
        >
          <div className={styles.formTitle}>名稱：</div>
          <Input.TextArea
            value={this.vm.form.title}
            onChange={this.vm.onTitleChange}
            maxLength={100}
            showCount
          />
          <div className={styles.formTitle}>連結：</div>
          <Input
            value={this.vm.form.url}
            onChange={this.vm.onUrlChange}
          />
          <div className={styles.spacer} />
          <div className={styles.formTitle}>上傳圖片：</div>
          <div className={styles.extra}>建議Banner尺寸：1440*600</div>
          <Upload
            listType="picture-card"
            customRequest={customUploadRequest}
            accept="image/*"
            maxCount={1}
            onChange={this.vm.handleUploadChange}
            defaultFileList={this.vm.form.defaultFileList}
          >
            <div>Upload</div>
          </Upload>
        </Drawer>
      </div>
    );
  }
}


export default withProfile(BannerPage, true, true);
