import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Input } from 'antd';

import withRouter from 'src/components/withRouter';

import ResetPwdVM from './viewModel';
import styles from './styles.module.scss';

@observer
class ResetPwd extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ResetPwdVM(this.props.router);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.main}>

        <div className={styles.box}>

          <div className={styles.content}>
            <div className={styles.title}>重設密碼</div>

            <div className={styles.row}>
              <div className={styles.field}>請輸入新的密碼</div>
              <Input.Password
                placeholder="請輸入密碼"
                value={this.vm.password}
                onChange={this.vm.onPasswordChange}
              />
            </div>

            <div className={styles.row}>
              <div className={styles.field}>再次輸入新的密碼</div>
              <Input.Password
                placeholder="請輸入密碼"
                value={this.vm.passwordConfirm}
                onChange={this.vm.onPasswordConfirmChange}
              />
            </div>


            <Button
              className={styles.primaryBtn}
              onClick={this.vm.resetPwd}
            >
              重設
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ResetPwd.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(ResetPwd);
