import { useEffect, useRef } from 'react';

import is from '../utils/is';

export default function useInterval(callback, delay) {
  const callbackRef = useRef();
  callbackRef.current = callback;

  useEffect(
    () => {
      if (!is.func(callbackRef.current) || typeof delay !== 'number' || delay < 0) {
        throw new Error('invalid params');
      }
      const id = setInterval(callbackRef.current, delay);
      return () => {
        clearInterval(id);
      };
    },
    [delay]
  );
}
