import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';

import DefaultAvatar from 'src/assets/avatar.svg';

import FileCard from './FileCard';
import FosterCard from './FosterCard';
import ImageCard from './ImageCard';
import styles from './styles.module.scss';

const MessageCard = (props) => {
  const {
    className,
    /**
     * { avatar, id, nickname }
     */
    user,
    /**
     * { id, fromId, toId, text, files, images, foster, createAt }
     */
    data,
    isLast
  } = props;

  return (
    <div className={clsx(styles.card, isLast && styles.last, className)}>
      <div className={styles.user}>
        <div className={styles.avatarWrapper}>
          <img
            className={user.avatar ? styles.avatar : styles.defaultAvatar}
            src={user.avatar || DefaultAvatar}
            alt="avatar"
          />
        </div>
        <div className={styles.name}>{ user.nickname }</div>
      </div>

      <div className={styles.content}>
        { data.text?.length ? (<div className={styles.text}>{ data.text }</div>) : null }
        {
          data.foster ? (
            <div className={styles.foster}>
              <FosterCard foster={data.foster} />
            </div>
          ) : null
        }
        {
          data.images?.length ? (
            <div className={styles.images}>
              { data.images.map((item) => <ImageCard key={item.key} item={item} />) }
            </div>
          ) : null
        }
        {
          data.files?.length ? (
            <div className={styles.files}>
              { data.files.map((item) => <FileCard key={item.key} item={item} />) }
            </div>
          ) : null
        }
        <div className={styles.timestamp}>{ dayjs(data.createAt).format('YYYY/MM/DD HH:mm:ss') }</div>
      </div>
    </div>
  );
};

MessageCard.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isLast: PropTypes.bool
};

export default MessageCard;
