import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Spin, Modal } from 'antd';

import { ReactComponent as XIcon } from 'src/assets/x.svg';
import { ReactComponent as ZoomIcon } from 'src/assets/zoom.svg';
import useUnMount from 'src/hooks/useUnMount';

import styles from './styles.module.scss';

const getDescription = (item, isLengthLimitReached) => {
  if (isLengthLimitReached) { return '檔案數量已達上限'; }
  if (item.status === 'error') { return '上傳失敗'; }
  return null;
};

const ImageCard = observer((props) => {
  const {
    className,
    item,
    isLengthLimitReached,
    onClear = () => {}
  } = props;

  const [preview, setPreview] = useState(false);
  const onClearProxy = useCallback(() => onClear(item), [onClear, item]);

  useEffect(
    () => {
      if (!isLengthLimitReached) {
        item.start();
      }

      return () => {
        item.stop();
      };
    },
    [item, isLengthLimitReached]
  );

  useUnMount(item.stop);

  const onPreview = useCallback(
    () => {
      if (item.clickable) {
        setPreview(true);
      }
    },
    [item]
  );

  const onClosePreview = useCallback(() => setPreview(false), []);

  const isFailed = isLengthLimitReached || item.status === 'error';

  return (
    <>
      <div
        className={clsx(styles.card, item.clickable && styles.pointer, className)}
        onClick={onPreview}
      >
        <img
          className={clsx(item.loading && styles.opacity20)}
          src={item.url}
          alt=""
        />
        {
          isFailed ? (
            <div className={styles.mask}>
              { getDescription(item, isLengthLimitReached) }
            </div>
          ) : null
        }
        <Button
          className={styles.button}
          onClick={item.clearable ? onClearProxy : undefined}
          icon={item.clearable ? <XIcon className={styles.x} /> : <ZoomIcon />}
        />
        <Spin
          className={styles.spin}
          spinning={!isFailed && item.loading}
        />
      </div>
      <Modal
        open={preview}
        footer={null}
        closeIcon={null}
        width="min(600px, 90vw)"
        centered
        maskClosable
        onCancel={onClosePreview}
      >
        <img className={styles.preview} src={item.url} alt="" />
      </Modal>
    </>

  );
});

ImageCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  isLengthLimitReached: PropTypes.bool,
  onClear: PropTypes.func
};

export default ImageCard;
