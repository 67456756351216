import { makeObservable, observable, action, computed, runInAction } from 'mobx';

/**
 * 僅適用於 Antd Table
 */
export default class AntdTablePager {
  @observable page = 1;
  @observable limit = 20;
  @observable list = [];
  @observable anchor = null;
  @observable isLoading = false;

  /**
   * @type {(limit: number, anchor?: string) => Promise<{ list: any[], anchor?: string }>}
   */
  _query = null;

  constructor(query, limit = 20) {
    makeObservable(this);

    runInAction(() => {
      this.limit = limit;
    });

    if (typeof query !== 'function') {
      throw new Error('query must be a function.');
    }

    this._query = query;
  }

  @computed get total() {
    return this.anchor ? this.list.length + 1 : this.list.length;
  }

  @computed get section() {
    return this.list.slice((this.page - 1) * this.limit, this.page * this.limit);
  }

  /**
   *
   * @param {boolean?} reset
   * @returns {Promise<void>}
   */
  @action next = async (reset) => {
    try {
      if (reset !== undefined && typeof reset !== 'boolean') {
        throw new Error('reset must be a boolean or undefined.');
      }

      if (!this._query) {
        throw new Error('query is not defined.');
      }

      if (this.isLoading) { return; }

      this.page = reset ? 1 : this.page + 1;

      if (!reset && (!this.anchor || this.page <= Math.ceil(this.list.length / this.limit))) {
        return;
      }

      const { list, anchor } = await this._query(this.limit, reset ? null : this.anchor);

      runInAction(() => {
        this.list = reset ? list : this.list.concat(list);
        this.anchor = anchor;
      });
    } catch (err) {
      // forward error
      throw err;

    } finally {
      runInAction(() => { this.isLoading = false; });
    }
  };

  @action update = (id, source) => {
    const found = this.list.find((item) => item.id === id);
    if (found) {
      Object.assign(found, source);
    }
  };

  @action remove = (id) => {
    this.list = this.list.filter((item) => item.id !== id);
  };

  @action onPageChange = async ({ current }) => {
    if (current > this.page) {
      await this.next();
    } else {
      this.page = current || 1;
    }
  };

  @action onLimitChange = async (current, limit) => {
    this.limit = limit;
    await this.next(true);
  };
}
