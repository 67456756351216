import React, { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Avatar } from 'antd';
import { CaretRightOutlined, UserOutlined } from '@ant-design/icons';

import useProfile from 'src/hooks/useProfile';
import useModal from 'src/hooks/useModal';
import MemberOnlyModal from 'src/components/MemberOnlyModal';
import MessageService from 'src/services/message';
import { ReactComponent as MessageIcon } from 'src/assets/message.svg';
import { TaiwanCountyTxt } from 'src/constants';
import styles from './styles.module.scss';

function FosterPerson(props) {
  const {
    fosterId,
    info = {}
  } = props;

  const navigate = useNavigate();
  const profile = useProfile(false);
  const modal = useModal();

  const onCommentClick = useCallback(() => info && navigate(`/user/${info.id}/comment`), [navigate, info]);
  const onMessageClick = useCallback(
    async () => {
      try {
        if (!info?.id) { return; }

        if (!profile) {
          modal.open();
          return;
        }

        const box = await MessageService.upsertBox(info.id);
        navigate(`/message/${box.id}${fosterId ? `?foster=${fosterId}` : ''}`);
      } catch (err) {
        // ignore
      }
    },
    [navigate, info, fosterId, profile, modal]
  );

  const showMessageButton = !profile || (info && info.id !== profile.id);
  const showCommentButton = !!info?.commentCount;

  return (
    <div className={styles.main}>

      <div className={styles.top}>
        <div className={styles.person}>
          <div className={styles.avatar}>
            <Avatar icon={<UserOutlined />} src={info?.avatar} />
          </div>
          <div>{`出養人：${info?.nickname ?? ''}`}</div>
        </div>
      </div>

      {
        showMessageButton || showCommentButton ? (
          <div className={styles.actions}>
            {
              showMessageButton ? (
                <Button
                  className={clsx(styles.button, styles.message)}
                  icon={<MessageIcon className={styles.icon} />}
                  onClick={onMessageClick}
                >
                  聯繫出養人
                </Button>
              ) : null
            }
            {
              showCommentButton ? (
                <Button
                  className={clsx(styles.button, styles.comment)}
                  onClick={onCommentClick}
                >
                  { `閱讀 ${info.commentCount} 則評論` }
                </Button>
              ) : null
            }
          </div>
        ) : null
      }

      <div className={styles.center}>
        <div className={styles.box}>
          <span className={styles.key}>姓名/組織</span>
          <CaretRightOutlined className={styles.icon} />
          <span className={styles.value}>{info?.name ?? ''}</span>
        </div>

        <div className={styles.box}>
          <span className={styles.key}>地區</span>
          <CaretRightOutlined className={styles.icon} />
          <span className={styles.value}>{TaiwanCountyTxt[info?.region] ?? '-'}</span>
        </div>
      </div>

      <div className={styles.bottom}>
        {info?.selfIntro ?? '無自介'}
      </div>

      <MemberOnlyModal
        open={!!modal.data}
        onClose={modal.close}
      />
    </div>
  );
}

FosterPerson.propTypes = {
  fosterId: PropTypes.string,
  info: PropTypes.object
};

export default FosterPerson;
