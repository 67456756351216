import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import dayjs from 'dayjs';
import { message } from 'antd';
import { clientConfirmStore } from 'src/stores/confirmStore';
import infoStore from 'src/stores/infoStore';
import ApplicationService from 'src/services/application';
import FosterService from 'src/services/foster';
import { ApplicationAction, ApplicationStatus } from 'src/constants';

export default class ApplicationItemVM {
  applicationId = null;
  profile = null;
  @observable isPageLoading = false;
  @observable application = null;
  @observable foster = null;
  @observable showCancelInput = false;
  @observable showDeathInput = false;
  @observable commentPairId = null;
  @observable matchedComment = null;
  @observable canceledComment = null;

  constructor(applicationId, profile) {
    makeObservable(this);
    this.applicationId = applicationId;
    this.profile = profile;
  }

  didMount = async () => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      const [application, commentPair] = await Promise.all([
        ApplicationService.getByApplicationId(this.applicationId),
        ApplicationService.getComment(this.applicationId)
      ]);

      // check user permission
      if (this.profile.isAdmin || this.profile.id === application.applicantId) {
        runInAction(() => { this.hasPermission = true; });
      } else {
        runInAction(() => { this.isPageLoading = false; });
        return;
      }

      const foster = await FosterService.getFosterById(application.fosterId);

      runInAction(() => {
        this.application = application;
        this.foster = foster;
        this.commentPairId = commentPair?._id ?? null;
        this.matchedComment = commentPair?.matchedComment ?? null;
        this.canceledComment = commentPair?.canceledComment ?? null;
        this.isPageLoading = false;
      });

    } catch {
      runInAction(() => { this.isPageLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }

  };

  @computed get text() {
    switch (this.application?.status) {

      case ApplicationStatus.CancelAdoption:
      case ApplicationStatus.CancelAdoptionConfirmation:
        return '退養';

      case ApplicationStatus.CancelFoster:
      case ApplicationStatus.CancelFosterConfirmation:
        return '取消出養';

      case ApplicationStatus.Ended:
        return '死亡';

      default:
        return '';
    }
  }

  @computed get canCreateMatchedComment() {
    if (!this.application || !this.application.matchedAt) return false;
    return !this.matchedComment && dayjs().isBefore(dayjs(this.application.matchedAt).add(14, 'days').endOf('D'));
  }
  @computed get canCreateCanceledComment() {
    if (!this.application || !this.application.canceledAt) return false;
    return !this.canceledComment && dayjs().isBefore(dayjs(this.application.canceledAt).add(14, 'days').endOf('D'));
  }
  @computed get isMatchedCommentVisible() {
    if (!this.application || !this.application.matchedAt) return false;
    if (dayjs().isBefore(dayjs(this.application.matchedAt).add(14, 'days').endOf('D'))) return !!this.matchedComment;
    return true;
  }
  @computed get isCanceledVisible() {
    if (!this.application || !this.application.canceledAt) return false;
    if (dayjs().isBefore(dayjs(this.application.canceledAt).add(14, 'days').endOf('D'))) return !!this.canceledComment;
    return true;
  }

  refresh = async () => {
    try {
      const application = await ApplicationService.getByApplicationId(this.applicationId);
      runInAction(() => { this.application = application; });
    } catch {
      // ignore
    }
  };

  resendSms = async () => {
    // 間隔三天之後才可以再寄簡訊
    const smsSentAt = this.application.smsSentAt;
    if (smsSentAt && (dayjs().diff(dayjs(smsSentAt), 'hour') < 72)) {
      infoStore.show({ message: '距上次提醒未滿三天，無法發送簡訊', type: 'warn' });
      return;
    }

    try {
      await ApplicationService.resendSms(this.applicationId);
      message.open({ type: 'success', content: '已發送簡訊提醒出養人，謝謝您耐心等候！' });
      runInAction(() => { this.application.smsSentAt = Date.now(); });
    } catch (err) {
      let _message = '';
      switch (err?.response?.status) {
        case 400:
          _message = '當前狀態不允許發送簡訊';
          break;
        case 401:
          _message = '您尚未登入';
          break;
        case 403:
          _message = '您沒有權限執行這個動作';
          break;
        case 404:
          _message = '認養申請不存在';
          break;
        case 406:
          _message = '距上次提醒未滿三天，無法發送簡訊';
          break;
        default:
          _message = '操作失敗，請稍後再試';
      }
      infoStore.show({ message: _message, type: 'warn' });
    }
  };

  cancelApplication = async () => {
    clientConfirmStore.show(({
      title: '確定要取消認養嗎？',
      message: '系統將會以簡訊通知申請人，取消後不可收回。確認要繼續嗎？\n（備註：申請取消後，若您仍希望認養這隻喵喵，需要再次提出申請喔！）',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.CancelApplication }
          );
          await this.refresh();
          message.open({ type: 'success', content: '已通知出養人取消認養' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  returnContract = async () => {
    clientConfirmStore.show(({
      title: '確定退回案件嗎？',
      message: '系統將會以簡訊提醒出養人重新查看上傳之認養合約，「退回」後不可收回，您將等待出養人再次送出合約給您確認，確認要繼續嗎？',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.ReturnContract }
          );
          await this.refresh();
          message.open({ type: 'success', content: '已退回合約文件！' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  confirmAdoption = async () => {
    clientConfirmStore.show(({
      title: '確定送出認養確認嗎？',
      message: '請確認您有先下載簽約檔案並仔細查看核對，確認為有出養方和認養方兩邊都簽名及填具資料的文件，再按下確定喔。請問要確定認養嗎？',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.ConfirmAdoption }
          );
          await this.refresh();
          message.open({ type: 'success', content: '謝謝你給貓貓一個幸福的家，別忘了給出養人一個評價哦！' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  cancelAdoption = async ({ cancelOption, cancelDescription }) => {
    clientConfirmStore.show(({
      title: '確定要送出退養申請嗎？',
      message: '系統將會以簡訊通知，提醒出養人至平台確認是否同意您的退養申請，您將等候出養人的回覆。\n送出後不可收回，確認要繼續嗎？',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            {
              action: ApplicationAction.CancelAdoption,
              cancelOption,
              cancelDescription
            }
          );
          runInAction(() => { this.showCancelInput = false; });
          await this.refresh();
          message.open({ type: 'success', content: '已通知出養人確認您的退養申請！' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  deathReport = async ({ cancelOption, cancelDescription }) => {
    clientConfirmStore.show(({
      title: '確定要送出死亡通報嗎？',
      message: '系統的死亡通報僅會協助通知出養方與留下原因紀錄，您仍需至動物醫院或收容所依法登記動物死亡，才算完成通報程序唷！',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            {
              action: ApplicationAction.DeathReport,
              cancelOption,
              cancelDescription
            }
          );
          runInAction(() => { this.showDeathInput = false; });
          await this.refresh();
          message.open({ type: 'success', content: '已發送死亡通知給出養方！' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  refuseCancelFoster = async () => {
    clientConfirmStore.show(({
      title: '確定要拒絕出養取消申請嗎？',
      message: '系統將會以簡訊通知出養人您已拒絕出養取消申請，確認要繼續嗎？\n（備註：出養人被拒絕後仍有權再次提出申請）',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.RefuseCancelFoster }
          );
          await this.refresh();
          message.open({ type: 'success', content: '已拒絕出養取消申請' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  confirmCancelFoster = async () => {
    clientConfirmStore.show(({
      title: '確定要同意出養取消申請嗎？',
      message: '同意後不可收回，確認要繼續嗎？',
      callBack: async () => {
        try {
          await ApplicationService.updateApplicationStatus(
            this.applicationId,
            { action: ApplicationAction.ConfirmCancelFoster }
          );
          await this.refresh();
          message.open({ type: 'success', content: '已同意出養取消申請' });
        } catch (err) {
          this.errorHandler(err);
        }
      }
    }));
  };

  createComment = async ({
    positiveRating,
    positiveComment,
    negativeRating,
    negativeComment
  }, type) => {
    try {
      await ApplicationService.createComment(
        this.applicationId,
        {
          positiveRating,
          positiveComment,
          negativeRating,
          negativeComment,
          type
        }
      );

      const comments = await ApplicationService.getComment(this.applicationId);
      message.open({ type: 'success', content: '已送出評價！' });

      runInAction(() => {
        this.matchedComment = comments?.matchedComment ?? null;
        this.canceledComment = comments?.canceledComment ?? null;

        const commentCount = this.foster?.fosterPerson?.commentCount ?? undefined;
        if (commentCount !== undefined) {
          this.foster.fosterPerson.commentCount += 1;
        }
      });

    } catch (err) {
      infoStore.show({ message: '發生錯誤，無法新增評價', type: 'warn' });
    }
  };

  errorHandler = (err) => {
    let _message = '';
    switch (err?.response?.status) {
      case 400:
        _message = '認養申請不允許變更狀態';
        break;
      case 401:
        _message = '您尚未登入';
        break;
      case 403:
        _message = '您沒有權限執行這個動作';
        break;
      case 404:
        _message = '認養申請不存在';
        break;
      default:
        _message = '發生錯誤';
    }
    infoStore.show({ message: _message, type: 'warn' });
  };

  @action OnShowCancelInputChange = () => {
    this.showCancelInput = true;
  };
  @action OnShowDeathInputChange = () => {
    this.showDeathInput = true;
  };
}
