import React from 'react';
import PropTypes from 'prop-types';

import styles from './Ribbon.module.scss';

function Ribbon(props) {
  const {
    color = '#A8A29E',
    label,
    shadowColor = '#27272A'
  } = props;

  return (
    <div className={styles.ribbon}>
      <svg width="53" height="54" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3801_71020)">
          <path d="M2.5 0L0 3H5.5L2.5 0Z" fill={shadowColor} />
          <path d="M53 50.5L50 47.5V53.5L53 50.5Z" fill={shadowColor} />
          <path d="M53 50.5L2.5 0H28.5L53 24.5V50.5Z" fill={color} />
        </g>
        <defs>
          <clipPath id="clip0_3801_71020">
            <rect width="53" height="54" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {label && (
        <div className={styles.label}>
          {label}
        </div>
      )}

    </div>
  );
}

Ribbon.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  shadowColor: PropTypes.string
};

export default Ribbon;
