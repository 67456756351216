import { message } from 'antd';
import { makeObservable, observable, action, computed } from 'mobx';

export default class CommentInputVM {
  @observable cancelOption;
  @observable cancelDescription;
  onClickProp;

  constructor(onClickProp) {
    makeObservable(this);
    this.onClickProp = onClickProp;
  }


  onClick = () => {
    if (!(this.cancelOption && this.cancelDescription)) {
      message.open({
        type: 'error',
        content: '必填欄位未填'
      });
      return;
    }

    this.onClickProp({
      cancelOption: this.cancelOption,
      cancelDescription: this.cancelDescription
    });
  };

  @action onSelect = (e) => {
    this.cancelOption = e;
  };
  @action onChange = (e) => {
    this.cancelDescription = e.target.value;
  };
}
