import { useCallback } from 'react';
import { runInAction } from 'mobx';
import { useLocalObservable, observer } from 'mobx-react';
import clsx from 'clsx';
import { useFilePicker } from 'use-file-picker';
import PropTypes from 'prop-types';
import { Button, Input, message } from 'antd';

import MessageService from 'src/services/message';
import { ReactComponent as ImageUploadIcon } from 'src/assets/image-upload.svg';
import { ReactComponent as AttachmentIcon } from 'src/assets/attachment.svg';

import FileSizeValidator from '../../FileSizeValidator';
import { FileCard, FosterCard, ImageCard, Foster, Attachment } from '../Message';
import styles from './styles.module.scss';

const MaxImages = 5;
const MaxFiles = 5;

const CreatePanel = observer((props) => {
  const { className, fosterId, userId, onCreate } = props;

  const store = useLocalObservable(() => ({
    text: '',
    foster: fosterId ? Foster.editmode(fosterId) : null,
    images: {},
    files: {},
    get sendable() {
      const images = Object.values(this.images).filter((el) => !!el);
      const files = Object.values(this.files).filter((el) => !!el);

      // 至少有一項內容
      const count = [
        Math.min(this.text.trim().length, 1),
        this.foster ? 1 : 0,
        Math.min(images.length, 1),
        Math.min(files.length, 1)
      ].reduce((p, c) => p + c, 0);

      return count > 0
        // 確保狀態皆為 done
        && (this.foster ? this.foster.status === 'done' : true)
        && (images.length ? images.every((el) => el.status === 'done') : true)
        && (files.length ? files.every((el) => el.status === 'done') : true);
    }
  }));

  const onFilesRejected = useCallback(
    ({ errors }) => {
      const [error] = errors; // only show the first error
      switch (error?.name) {
        case 'FileSizeValidatorError':
          message.open({ type: 'warning', content: error.reason });
          break;
        default:
          message.open({ type: 'warning', content: '未知錯誤' });
          break;
      }
    },
    []
  );

  const onImageSelected = useCallback(
    ({ plainFiles, filesContent }) => runInAction(() => {
      for (let idx = 0; idx < plainFiles.length; idx += 1) {
        store.images[plainFiles[idx].name] = Attachment.editmode(userId, plainFiles[idx], filesContent[idx].content);
      }
    }),
    [userId, store.images]
  );

  const onFileSelected = useCallback(
    ({ plainFiles }) => runInAction(() => {
      for (const file of plainFiles) {
        store.files[file.name] = Attachment.editmode(userId, file);
      }
    }),
    [userId, store.files]
  );

  const imagePicker = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/png', 'image/jpeg', 'image/bmp'],
    multiple: true,
    validators: [
      new FileSizeValidator(10 * 1024 * 1024)
    ],
    onFilesSelected: onImageSelected,
    onFilesRejected
  });

  const filePicker = useFilePicker({
    accept: [
      'video/mp4',
      'application/pdf',
      'application/msword', // doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
      'application/vnd.ms-excel', // xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
      'application/vnd.ms-powerpoint', // ppt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation' // pptx
    ],
    multiple: true,
    validators: [
      new FileSizeValidator(
        10 * 1024 * 1024, // default = 10MB,
        {
          'video/mp4': 25 * 1024 * 1024 // mp4 = 25MB
        }
      )
    ],
    onFilesSelected: onFileSelected,
    onFilesRejected
  });

  const onTextChange = useCallback((ev) => runInAction(() => { store.text = ev.target.value; }), [store]);
  const onFosterClear = useCallback(() => runInAction(() => { store.foster = null; }), [store]);
  const onFileRemove = useCallback((item) => runInAction(() => { store.files[item.file.name] = null; }), [store.files]);
  const onImageRemove = useCallback((item) => runInAction(() => { store.images[item.file.name] = null; }), [store.images]);

  const onSend = useCallback(
    async () => {
      if (!store.sendable) { return; }

      try {
        await MessageService.sendMessage({
          receiverId: userId,
          text: store.text.trim(),
          fosterId: store.foster?.id,
          images: Object.values(store.images).filter((el) => !!el).map((el) => ({
            size: el.file.size,
            name: el.id
          })),
          files: Object.values(store.files).filter((el) => !!el).map((el) => ({
            size: el.file.size,
            name: el.id
          }))
        });

        runInAction(() => {
          store.text = '';
          store.foster = null;
          store.images = {};
          store.files = {};
        });

        onCreate();
        message.open({ type: 'success', content: '留言已送出' });
      } catch (err) {
        message.open({ type: 'error', content: '發生錯誤，無法送出留言' });
      }
    },
    [store, userId, onCreate]
  );

  const fileList = Object.values(store.files).filter((el) => !!el);
  const imageList = Object.values(store.images).filter((el) => !!el);

  return (
    <div className={clsx(styles.panel, className)}>
      <div className={styles.actions}>
        <Button
          className={styles.button}
          size="large"
          icon={<ImageUploadIcon />}
          onClick={imagePicker.openFilePicker}
        />
        <Button
          className={styles.button}
          size="large"
          icon={<AttachmentIcon />}
          onClick={filePicker.openFilePicker}
        />
      </div>

      <div className={styles.tips}>
        *單一圖片、檔案大小上限 10 MB，影片 25 MB ; 一次留言可傳 5 張圖片和 5 個檔案
      </div>

      <Input.TextArea
        className={styles.input}
        autoSize={{ minRows: 5, maxRows: 10 }}
        count={{ show: true, max: 500 }}
        placeholder="請輸入您的留言"
        onChange={onTextChange}
        value={store.text}
      />

      {
        store.foster ? (
          <div className={styles.foster}>
            <FosterCard
              foster={store.foster}
              onClear={onFosterClear}
            />
          </div>
        ) : null
      }

      {
        imageList.length ? (
          <div className={styles.images}>
            {
              imageList.map((item, idx) => (
                <ImageCard
                  key={`image-${item.key}`}
                  item={item}
                  isLengthLimitReached={(idx + 1) > MaxImages}
                  onClear={onImageRemove}
                />
              ))
            }
          </div>
        ) : null
      }

      {
        fileList.length ? (
          <div className={styles.files}>
            {
              fileList.map((item, idx) => (
                <FileCard
                  key={`file-${item.key}`}
                  item={item}
                  isLengthLimitReached={(idx + 1) > MaxFiles}
                  onClear={onFileRemove}
                />
              ))
            }
          </div>
        ) : null
      }

      <Button
        className={clsx(styles.send, !store.sendable && styles.disabled)}
        type="primary"
        disabled={!store.sendable}
        onClick={onSend}
      >
        送出
      </Button>
    </div>
  );
});

CreatePanel.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string,
  fosterId: PropTypes.string,
  onCreate: PropTypes.func
};

export default CreatePanel;
