import { request, getHost } from './api';
import AuthService from './auth';

export default class ReportService {

  /**
   * [B]
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string[]} data.region
   */
  static async getListBackstage(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/report`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿幸福回報＿新增
   * @param {string} fosterId
   * @param {Object} data
   * @param {string} data.content
   * @param {object} data.image - file
   */
  static async create(fosterId, data) {
    const formData = new FormData();
    formData.append('content', data.content);
    formData.append('image', data.image);

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/report/${fosterId}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] 前台＿幸福回報＿取得列表（by foster）
   * @param {string} fosterId
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   */
  static async getList(fosterId, data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/report/${fosterId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 前台＿幸福回報總覽(overview)
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string[]} data.coloration
   */
  static async getSuccessStories(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/report/success-stories`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * toggle reaction
   */
  static async putReaction(reportId) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/report/${reportId}/reaction`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };

    const res = await request(options);
    return res.data;
  }

  static async getDetail(reportId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/report/${reportId}/detail`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };

    const res = await request(options);
    return res.data;
  }
}
