import { request, getHost } from './api';
import AuthService from './auth';

export default class MessageService {
  /**
   * [B] get backstage message boxes
   */
  static async getBackstageBoxes(userId, keyword, limit, anchor) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/backstage/message-box/list`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        userId,
        keyword: keyword || undefined,
        limit,
        anchor
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] get message boxes
   */
  static async getBoxes(keyword, limit, anchor) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/message-box/list`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        keyword: keyword || undefined,
        limit,
        anchor
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [B, C] get message box detail
   */
  static async getBoxDetail(boxId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/message-box/${boxId}/detail`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [B, C] get messages
   */
  static async getMessages(boxId, limit, anchor) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/message-box/${boxId}/messages`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        limit,
        anchor
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] get unread count
   */
  static async getUnreadCount() {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/message-box/unread`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] mark as read
   */
  static async markAsRead(boxId) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/message-box/${boxId}/read`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] send message
   *
   * @param {{
   *   receiverId: string,
   *   text?: string,
   *   foster?: string,
   *   images?: { size: number, name: string }[],
   *   files?: { size: number, name: string }[]
   * }} message
   * @returns
   */
  static async sendMessage(message) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/message-box/message`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: message
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] upsert message box
   */
  static async upsertBox(receiverId) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/message-box/upsert`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        receiverId
      }
    };
    const res = await request(options);
    return res.data;
  }

  static async getUploadUrls(signal, userId, files) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/message-box/files`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      signal,
      data: {
        receiverId: userId,
        files
      }
    };
    const res = await request(options);
    return res.data;
  }
}
