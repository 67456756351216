import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Input, Button, Select } from 'antd';
import { Helmet } from 'react-helmet';

import Loading from 'src/components/Loading';
import ReactionRow from 'src/components/ReactionRow';
import withRouter from 'src/components/withRouter';
import MemberOnlyModal from 'src/components/MemberOnlyModal';
import { parseDate } from 'src/utils';
import { ColorationTxt } from 'src/constants';
import SuccessStoriesVM from './viewModel';
import styles from './styles.module.scss';
import Main from './Main';

@observer
class SuccessStories extends React.Component {
  constructor() {
    super();
    this.vm = new SuccessStoriesVM();
  }

  componentDidMount() {
    this.vm.didMount(this.props.router);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>幸福回報</title>
          <link rel="canonical" href="https://meowohana.com/success-stories" />
        </Helmet>

        <div className={styles.search_wrapper}>
          <div className={styles.search}>
            <div className={styles.search_item}>
              <div className={styles.search_item_title}>關鍵字：</div>
              <Input
                value={this.vm.keyword}
                onChange={this.vm.onKeywordChange}
                placeholder="請輸入貓咪名字"
                className={styles.search_item_input}
              />
            </div>
            <div className={styles.search_item}>
              <div className={styles.search_item_title}>花色：</div>
              <Select
                mode="multiple"
                showSearch={false}
                allowClear
                placeholder="請選擇"
                value={this.vm.coloration}
                onChange={this.vm.onColorationChange}
                className={styles.search_item_input}
                options={Object.keys(ColorationTxt).map((key) => ({
                  value: key,
                  label: ColorationTxt[key]
                }))}
              />
            </div>
            <Button onClick={this.vm.onSearch} className={styles.search_btn}>
              套用篩選
            </Button>
          </div>
        </div>


        {this.vm.isPageLoading
          ? <Loading />
          : (
            <Main
              onNextPage={this.vm.query}
              groups={this.vm.groups}
              openModal={this.vm.openModal}
              onReaction={this.vm.onReaction}
            />
          )}


        <Modal
          open={this.vm.isModalShow}
          footer={null}
          width="min(600px, 90vw)"
          centered
          onCancel={this.vm.closeModal}
        >
          <div className={styles.modal}>
            <div className={styles.name}>{this.vm.modalData?.name ?? ''}</div>
            <div className={styles.content}>
              <img className={styles.img} src={this.vm.modalData?.image} alt="cat" />
              <ReactionRow
                item={this.vm.modalData}
                sharePath={`/success-stories?id=${this.vm.modalData?.id}`}
                onReaction={this.vm.onReaction}
              />
              <div className={styles.content}>{this.vm.modalData?.content ?? ''}</div>
              <div className={styles.createAt}>{`幸福回報時間：${parseDate(this.vm.modalData?.createAt, '-')}`}</div>
            </div>
          </div>
        </Modal>

        <MemberOnlyModal
          open={this.vm.isMemberOnlyModalShow}
          onClose={this.vm.closeMemberOnlyModal}
        />
      </>
    );
  }
}

SuccessStories.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(SuccessStories);
