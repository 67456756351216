import dayjs from 'dayjs';
import { message } from 'antd';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import FosterService from 'src/services/foster';
import ReportService from 'src/services/report';
import { FosterStatus } from 'src/constants';
import infoStore from 'src/stores/infoStore';
import { readFileAsUrl } from 'src/utils';

export default class ReportVM {
  fosterId = null;
  profile = null;
  limit = 30;
  anchor = null;
  isLoading = false;

  @observable foster = null;
  @observable adopter = null;
  @observable isCreateModalShow = false;
  @observable isDataModalShow = false;
  @observable isMemberOnlyModalShow = false;
  @observable modalData = null;
  @observable content = undefined;
  @observable image = undefined;
  @observable imageUrl = undefined;
  @observable list = [];
  @observable isCreating = false;
  @observable isPageLoading = false;
  @observable hasPermission = false;

  constructor(fosterId, profile) {
    makeObservable(this);
    this.fosterId = fosterId;
    this.profile = profile;
  }

  @computed get isEnded() {
    return this.foster?.status === FosterStatus.Ended;
  }

  didMount = async (router) => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      await this.getFoster();
      await this.getList(true);
    } catch (err) {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    } finally {
      runInAction(() => { this.isPageLoading = false; });
    }
  };

  getFoster = async () => {
    const foster = await FosterService.getFosterById(this.fosterId);

    runInAction(() => {
      this.hasPermission = this.profile.isAdmin
        || this.profile.id === foster?.adopter.id
        || this.profile.id === foster?.fosterPerson.id;

      if (this.hasPermission) {
        this.foster = foster;
      }
    });
  };

  getList = async (reset = false) => {
    if (
      !this.hasPermission
        || this.isLoading
        || (!reset && !this.anchor)
    ) {
      return;
    }

    try {
      this.isLoading = true;

      const { list, anchor } = await ReportService.getList(this.fosterId, {
        anchor: reset ? null : this.anchor,
        limit: this.limit
      });

      runInAction(() => {
        this.anchor = anchor;
        this.list = reset ? list : this.list.concat(list);
      });

    } catch {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    } finally {
      this.isLoading = false;
    }
  };

  @computed get isLate() {
    if (!this.foster || !this.foster.needReport || this.reportFrequency === 0) return false;
    return dayjs().isAfter(dayjs(this.foster.nextReportingAt));
  }

  @computed get reportFrequency() {
    if (!this.foster || !this.foster.adoptAt) return '';
    const observerPeriod = dayjs(this.foster.adoptAt).add(this.foster.observationDuration, 'M').endOf('D');
    const frequency = dayjs().isBefore(observerPeriod) ? this.foster.observationFrequency : this.foster.stabilityFrequency;
    return frequency;
  }


  createReport = async () => {
    try {
      if (!this.content || !this.image) {
        message.open({
          type: 'error',
          content: '留言及圖片必填'
        });
        return;
      }

      if (this.isCreating) return;
      runInAction(() => { this.isCreating = true; });

      const result = await ReportService.create(this.fosterId, {
        content: this.content,
        image: this.image.originFileObj
      });

      runInAction(() => {
        this.isCreating = false;
        this.list = [result].concat(this.list);
      });

      this.closeCreateModal();

    } catch (err) {
      runInAction(() => {
        this.isCreating = false;
      });
      this.closeCreateModal();

      let _message = '';
      switch (err?.response?.status) {
        case 400:
          _message = '操作失敗！留言及圖片必填';
          break;
        case 401:
          _message = '操作失敗！您尚未登入';
          break;
        case 403:
          _message = '操作失敗！您沒有權限';
          break;
        case 404:
          _message = '操作失敗！找不到此貓咪';
          break;
        case 408:
          _message = '操作失敗！照片檔案過大';
          break;
        default:
          _message = '發生錯誤';
      }
      infoStore.show({ message: _message, type: 'warn' });
    }
  };

  @action openCreateModal = () => {
    this.isCreateModalShow = true;
  };
  @action closeCreateModal = () => {
    this.isCreateModalShow = false;
    this.content = undefined;
    this.image = undefined;
    this.imageUrl = undefined;
  };
  @action openDataModal = (data) => {
    this.isDataModalShow = true;
    this.modalData = data;
  };
  @action closeDataModal = () => {
    this.isDataModalShow = false;
    this.modalData = null;
  };
  @action closeMemberOnlyModal = () => {
    this.isMemberOnlyModalShow = false;
  };
  @action onMessageChange = (e) => {
    this.content = e.target.value;
  };
  handleUploadChange = async ({ file }) => {
    const url = await readFileAsUrl(file.originFileObj);
    runInAction(() => {
      this.image = file;
      this.imageUrl = url;
    });
  };
  @action onReaction = async (ev, id) => {
    try {
      ev.stopPropagation();

      const isLike = await ReportService.putReaction(id);
      const found = this.list.find((i) => i.id === id);

      runInAction(() => {
        if (found) {
          found.isLike = isLike;
          found.reactions = isLike ? found.reactions + 1 : found.reactions - 1;
        }

        if (this.modalData?.id === id) {
          if (found) {
            this.modalData = found;
          } else {
            this.modalData.isLike = isLike;
            this.modalData.reactions = isLike ? this.modalData.reactions + 1 : this.modalData.reactions - 1;
          }
        }
      });
    } catch (err) {
      if (`${err.response?.status}`.charAt(0) === '4') {
        runInAction(() => { this.isMemberOnlyModalShow = true; });
      } else {
        message.error('發生錯誤，請稍後再試');
      }
    }
  };
}
