import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import ReactionFlag from 'src/components/ReactionFlag';

import styles from './styles.module.scss';

const GridCard = observer((props) => {
  const { item, onReaction } = props;

  return (
    <div className={cn(styles.imgCard, 'grid-card')} style={{ '--src': `url(${item.image})` }}>
      <div className={styles.caption}>
        <div className={styles.textBox}>
          <h4 className={styles.title}>{ item.name }</h4>
          <div className={styles.content}>{ item.content }</div>
        </div>
      </div>
      <ReactionFlag
        className={styles.reaction}
        item={item}
        onReaction={onReaction}
      />
    </div>
  );
});

GridCard.propTypes = {
  item: PropTypes.object.isRequired,
  onReaction: PropTypes.func.isRequired
};

export default GridCard;
