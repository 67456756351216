import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import EditorContentService from 'src/services/editorContent';
import infoStore from 'src/stores/infoStore';
import { EditorContentType } from 'src/constants';

export default class HelpVM {
  type = EditorContentType.Help;
  content = null;
  @observable isPageLoading = false;
  @observable isLoaded = false;

  constructor() {
    makeObservable(this);
  }

  didMount = async () => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      const content = await EditorContentService.get(this.type);

      runInAction(() => {
        this.isPageLoading = false;
        this.isLoaded = true;
        this.content = content || undefined;
      });

    } catch (err) {
      runInAction(() => { this.isPageLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };
}
