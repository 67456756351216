import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';
import CatPaw from '../CatPaw';

function PictureSet(props) {
  const {
    isImage3to2 = false,
    url
  } = props;

  const [selectedImg, setSelectedImg] = useState([]);

  useEffect(() => {
    setSelectedImg(url[0]);
  }, [url]);

  const renderButton = (imgUrl) => (
    <div onClick={() => setSelectedImg(imgUrl)}>
      <img className={styles.img} src={imgUrl} alt="cat" />
    </div>
  );

  return (
    <>

      <img
        className={styles.img}
        src={selectedImg}
        alt="cat"
        style={{ aspectRatio: isImage3to2 ? '3 / 2' : '1 / 1' }}
      />

      <div className={styles.spacer} />

      <div className={styles.row}>
        {[0, 1, 2, 3, 4].map((index) => {
          const imgUrl = url[index];
          return (
            <div
              className={cn(styles.thumbnail, { [styles.selected]: selectedImg === imgUrl })}
              key={imgUrl ?? index}
            >
              {imgUrl ? renderButton(imgUrl) : <CatPaw />}
            </div>
          );
        })}
      </div>

    </>
  );
}

PictureSet.propTypes = {
  url: PropTypes.array.isRequired,
  isImage3to2: PropTypes.bool
};

export default PictureSet;
