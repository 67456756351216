import React, { useRef, useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const VerificationInput = forwardRef(
  ({ value, onChange = () => {} }, ref) => {

    const length = 6;
    const validChars = '0-9';
    const [localValue, setLocalValue] = useState('');
    const [isActive, setActive] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
      inputRef.current.focus();
    }, []);

    const handleClick = () => {
      inputRef.current.focus();
    };

    const getValue = () => {
      return value ?? localValue;
    };

    // do not allow to change cursor position
    const handleKeyDown = (event) => {
      if (
        ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)
      ) {
        event.preventDefault();
      }
    };

    const handleInputChange = (event) => {
      const newInputVal = event.target.value.replace(/\s/g, '');

      if (RegExp(`^[${validChars}]{0,${length}}$`).test(newInputVal)) {
        if (onChange) {
          onChange?.(newInputVal);
        }
        setLocalValue(newInputVal);
      }
    };


    const isCharacterSelected = (i) => {
      const _value = getValue();
      return (
        (_value.length === i || (_value.length === i + 1 && length === i + 1))
        && isActive
      );
    };

    const isCharacterInactive = (i) => getValue().length < i;

    return (
      <div
        className={styles.vi__container}
        onClick={() => inputRef.current.focus()}
      >
        <input
          aria-label="verification input"
          spellCheck={false}
          value={getValue()}
          onChange={handleInputChange}
          ref={(node) => {
            inputRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              // eslint-disable-next-line no-param-reassign
              ref.current = node;
            }
          }}
          className={styles.vi}
          onKeyDown={handleKeyDown}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          onSelect={(e) => {
            const val = e.target.value;
            e.target.setSelectionRange(val.length, val.length);
          }}
        />
        {[...Array(length)].map((_, i) => (
          <div
              // eslint-disable-next-line react/no-array-index-key
            key={i}
            onClick={handleClick}
            className={classNames(
              styles.vi__character,
              {
                [styles.vi__character_selected]: isCharacterSelected(i),
                [styles.vi__character_inactive]: isCharacterInactive(i)
              }
            )}
          >
            {getValue()[i] || ''}
          </div>
        ))}
      </div>
    );
  }
);

VerificationInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default VerificationInput;
