import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import FosterService from 'src/services/foster';
import infoStore from 'src/stores/infoStore';

export default class CatListVM {
  limit = 50;
  anchor = null;

  @observable isPageLoading = false;
  @observable list = [];
  @observable isLoading = false;
  @observable region = [];
  @observable coloration = [];
  @observable gender = undefined;
  @observable keyword = undefined;
  minAge = undefined;
  maxAge = undefined;

  constructor() {
    makeObservable(this);
  }

  @computed get hasCat() {
    return this.list.length > 0;
  }

  didMount = async () => {
    try {
      runInAction(() => {
        this.isPageLoading = true;
        this.isLoading = true;
      });

      const { list, anchor } = await FosterService.getFrontendList({
        limit: this.limit,
        anchor: this.anchor
      });

      runInAction(() => {
        this.list = list;
        this.anchor = anchor;
        this.isLoading = false;
        this.isPageLoading = false;
      });

    } catch {
      runInAction(() => {
        this.isLoading = false;
        this.isPageLoading = false;
      });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };


  onSearch = async () => {
    try {
      if (this.isLoading) return;

      runInAction(() => {
        this.isLoading = true;
        this.isPageLoading = true;
      });

      const { list, anchor } = await FosterService.getFrontendList({
        anchor: null,
        limit: this.limit,
        region: this.region,
        coloration: this.coloration,
        gender: this.gender,
        keyword: this.keyword,
        minAge: this.minAge,
        maxAge: this.maxAge
      });

      runInAction(() => {
        this.isLoading = false;
        this.isPageLoading = false;
        this.list = list;
        this.anchor = anchor;
      });

    } catch {
      runInAction(() => {
        this.isLoading = false;
        this.isPageLoading = false;
      });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };


  onNextPage = async () => {
    if (this.isLoading || !this.anchor) return;

    try {
      runInAction(() => { this.isLoading = true; });

      const { list, anchor } = await FosterService.getFrontendList({
        anchor: this.anchor,
        limit: this.limit,
        region: this.region,
        coloration: this.coloration,
        gender: this.gender,
        keyword: this.keyword,
        minAge: this.minAge,
        maxAge: this.maxAge
      });

      runInAction(() => {
        this.list = this.list.concat(list);
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch {
      runInAction(() => { this.isLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };



  @action onRegionChange = (value) => {
    this.region = value;
  };
  @action onColorationChange = (value) => {
    this.coloration = value;
  };
  @action onRenderChange = (value) => {
    this.gender = value;
  };
  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
  };
  @action onAgeChange = (e) => {
    switch (e) {
      case '0':
        this.minAge = 0;
        this.maxAge = 1;
        break;
      case '1':
        this.minAge = 1;
        this.maxAge = 3;
        break;
      case '3':
        this.minAge = 3;
        this.maxAge = 5;
        break;
      case '5':
        this.minAge = 5;
        this.maxAge = 8;
        break;
      case '8':
        this.minAge = 8;
        break;
      default:
        this.minAge = undefined;
        this.maxAge = undefined;
    }
  };
}
