import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { ApplicationStatus } from 'src/constants';
import CommentService from 'src/services/comment';
import infoStore from 'src/stores/infoStore';

export default class CommentReplyVM {
  commentPairId;
  commentId;
  @observable isPageLoading = false;
  @observable isLoading = false;
  @observable commentPair = null;
  @observable comment = null;
  @observable content = undefined;
  @observable isModalOpen = false;
  @observable participant = new Map();
  @observable reply = {
    list: [],
    anchor: null,
    limit: 20
  };

  constructor({ commentPairId, commentId }) {
    makeObservable(this);

    this.commentPairId = commentPairId;
    this.commentId = commentId;
  }

  @computed get tagTxt() {
    switch (this.commentPair?.application?.status) {
      case ApplicationStatus.Matched:
        return '媒合成功';
      case ApplicationStatus.CancelAdoption:
        return '已退養';
      case ApplicationStatus.CancelFoster:
        return '取消出養';
      default:
        return '';
    }
  }

  @computed get isBlueTag() {
    return this.commentPair?.application?.status === ApplicationStatus.Matched;
  }

  @computed get hasReply() {
    return this.reply.list.length > 0;
  }

  canReply(userId) {
    if (!this.commentPair) return false;
    return userId === this.commentPair.creator.id || userId === this.commentPair.receiver.id;
  }


  didMount = async () => {
    try {
      runInAction(() => {
        this.isLoading = true;
        this.isPageLoading = true;
      });

      const commentPair = await CommentService.getCommentById(this.commentPairId);
      const reply = await CommentService.getReplyByComment({
        commentPairId: this.commentPairId,
        commentId: this.commentId,
        limit: this.reply.limit
      });

      runInAction(() => {
        this.commentPair = commentPair;
        this.reply.list = reply.list;
        this.reply.anchor = reply.anchor;
        this.participant.set(commentPair.creator.id, commentPair.creator.nickname);
        this.participant.set(commentPair.receiver.id, commentPair.receiver.nickname);
        this.isLoading = false;
        this.isPageLoading = false;

        if (commentPair.matchedComment?._id === this.commentId) {
          this.comment = commentPair.matchedComment;
        }
        if (commentPair.canceledComment?._id === this.commentId) {
          this.comment = commentPair.canceledComment;
        }
      });

    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.isPageLoading = false;
      });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };


  onNextPage = async () => {
    if (this.isLoading || !this.reply.anchor) return;

    try {
      runInAction(() => { this.isLoading = true; });

      const reply = await CommentService.getReplyByComment({
        commentPairId: this.commentPairId,
        commentId: this.commentId,
        limit: this.reply.limit,
        anchor: this.reply.anchor
      });

      runInAction(() => {
        this.reply.anchor = reply.anchor;
        this.reply.list = this.reply.list.concat(reply.list);
        this.isLoading = false;
      });

    } catch {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };


  createReply = async () => {
    if (!this.content || this.content.length === 0) {
      this.closeModal();
      return;
    }

    try {
      const newReply = await CommentService.replyComment({
        commentPairId: this.commentPairId,
        commentId: this.commentId,
        content: this.content
      });

      runInAction(() => {
        this.reply.list = [newReply].concat(this.reply.list);
      });


    } catch {
      infoStore.show({ message: '發生錯誤，無法新增留言', type: 'warn' });
    } finally {
      this.closeModal();
    }
  };


  @action onContentChnage = (e) => {
    this.content = e.target.value;
  };

  @action openModal = () => {
    this.content = undefined;
    this.isModalOpen = true;
  };

  @action closeModal = () => {
    this.content = undefined;
    this.isModalOpen = false;
  };
}
