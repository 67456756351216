import { makeObservable, runInAction } from 'mobx';

import AntdTablePager from 'src/models/AntdTablePager';
import RaffleService from 'src/services/raffle';
import errorStore from 'src/stores/errorStore';
import { backstageConfirmStore } from 'src/stores/confirmStore';
import { downloadFile } from 'src/utils';

export default class RaffleListViewModel {
  pager;

  constructor() {
    makeObservable(this);

    this.pager = new AntdTablePager(this.query, 20);
  }

  didMount = async () => {
    await this.pager.next(true);
  };

  query = async () => {
    try {
      const { list, anchor } = await RaffleService.getList({ isBackstage: true });
      return { list, anchor };

    } catch (err) {
      errorStore.show('發生錯誤，無法取得資訊');
      return { list: [], anchor: null };
    }
  };

  toggle = async (data, isShow) => {
    try {
      await RaffleService.toggleIsShow(data.id, isShow);

      this.pager.update(data.id, { isShow });
    } catch (err) {
      errorStore.show('發生錯誤，無法更新獎勵活動裝態');
    }
  };


  delete = async (id) => {
    backstageConfirmStore.show({
      message: '確定刪除？',
      callBack: async () => {
        try {
          await RaffleService.delete(id);
          this.pager.remove(id);
        } catch {
          errorStore.show('發生錯誤，無法刪除獎勵活動');
        }
      }
    });
  };


  download = async (id) => {
    let list = [];
    let anchor = null;

    try {
      do {
        // eslint-disable-next-line no-await-in-loop
        const res = await RaffleService.download(id, { limit: 300, anchor });
        list = list.concat(res.list);
        anchor = res.anchor;

      } while (anchor);

    } catch {
      errorStore.show('發生錯誤，無法下載兌換名單');
      return;
    }


    const result = [];

    list.forEach((r) => {
      for (let i = 0; i < r.count; i += 1) {
        result.push({
          姓名: r.name,
          暱稱: r.nickname,
          '手機號碼(帳號)': r.phone,
          email: r.email
        });
      }
    });

    const raffle = this.pager.list.find((item) => item.id === id);
    downloadFile(result, `${raffle.title}兌換名單`);
  };

}
