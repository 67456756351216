import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import ReportService from 'src/services/report';
import infoStore from 'src/stores/infoStore';

export default class SuccessStoriesVM {
  anchor = null;
  limit = 45;
  isLoading = false;

  @observable isPageLoading = false;
  @observable list = [];
  @observable modalData = null;
  @observable isModalShow = null;
  @observable keyword = null;
  @observable coloration = [];
  @observable isMemberOnlyModalShow = false;

  @computed get groups() {
    const length = this.list.length;
    const groupSize = 9;
    const result = [];

    for (let i = 0; i < length; i += groupSize) {
      result.push(this.list.slice(i, i + groupSize));
    }

    return result;
  }

  constructor() {
    makeObservable(this);
  }

  didMount = async (router) => {
    await Promise.all([
      this.autoPopup(router),
      this.query(true)
    ]);
  };

  autoPopup = async (router) => {
    try {
      const [search] = router.searchParams;
      const id = search.get('id');
      if (!id) { return; }

      const detail = await ReportService.getDetail(id);
      this.openModal(detail);
    } catch (err) {
      // ignore
    }
  };

  onSearch = () => this.query(true);

  query = async (reset = false) => {
    if (
      this.isPageLoading
        || this.isLoading
        || (!reset && !this.anchor)
    ) {
      return;
    }

    try {
      this.isLoading = true;
      if (reset) {
        runInAction(() => { this.isPageLoading = true; });
      }

      const { list, anchor } = await ReportService.getSuccessStories({
        limit: this.limit,
        anchor: reset ? null : this.anchor,
        keyword: this.keyword,
        coloration: this.coloration
      });

      runInAction(() => {
        this.anchor = anchor;
        this.list = reset ? list : this.list.concat(list);
      });
    } catch (err) {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    } finally {
      this.isLoading = false;
      runInAction(() => { this.isPageLoading = false; });
    }
  };

  @action openModal = (data) => {
    this.isModalShow = true;
    this.modalData = data;
  };

  @action closeModal = () => {
    this.isModalShow = false;
    this.modalData = null;
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
  };

  @action onColorationChange = (value) => {
    this.coloration = value;
  };

  @action closeMemberOnlyModal = () => {
    this.isMemberOnlyModalShow = false;
  };

  @action onReaction = async (ev, id) => {
    try {
      ev.stopPropagation();

      const isLike = await ReportService.putReaction(id);
      const found = this.list.find((i) => i.id === id);

      runInAction(() => {
        if (found) {
          found.isLike = isLike;
          found.reactions = isLike ? found.reactions + 1 : found.reactions - 1;
        }

        if (this.modalData?.id === id) {
          if (found) {
            this.modalData = found;
          } else {
            this.modalData.isLike = isLike;
            this.modalData.reactions = isLike ? this.modalData.reactions + 1 : this.modalData.reactions - 1;
          }
        }
      });
    } catch (err) {
      if (`${err.response?.status}`.charAt(0) === '4') {
        runInAction(() => { this.isMemberOnlyModalShow = true; });
      } else {
        message.error('發生錯誤，請稍後再試');
      }
    }
  };
}
