import React, { lazy, Suspense } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';

import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import MemberOnlyModal from 'src/components/MemberOnlyModal';
import PictureSet from 'src/components/PictureSet';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import { Router } from 'src/constants';
import { parseDate } from 'src/utils';
import RaffleEventVM from './viewModel';

import styles from './styles.module.scss';

const Editor = lazy(() => import('src/components/Editor'));

@observer
class RaffleEvent extends React.Component {
  constructor(props) {
    super(props);
    const raffleEventId = this.props.router.params.raffleEventId;
    this.vm = new RaffleEventVM(raffleEventId);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate() {
    this.vm.bindProfile(this.props.profile);
  }

  renderHeader() {
    return (
      <>
        <div className={styles.subTitle}>
          {`活動編號：${this.vm.raffleEvent?.serialNumber ?? ''}`}
        </div>
        <h1 className={styles.title}>
          {this.vm.raffleEvent?.title ?? ''}
        </h1>
        <div className={styles.subTitle}>
          {`報名時間：${parseDate(this.vm.raffleEvent?.startAt, '', true)} - ${parseDate(this.vm.raffleEvent?.endAt, '', true)}`}
        </div>
      </>
    );
  }

  renderFooter() {
    if (!this.vm.raffleEvent) return <div />;

    const now = dayjs();
    if (now.isBefore(dayjs(this.vm.raffleEvent.startAt))) return <div className={styles.tagEventNotStart}>尚未開始</div>;
    if (now.isAfter(dayjs(this.vm.raffleEvent.endAt))) return <div className={styles.tagEventEnded}>已截止</div>;
    return (
      <>
        <Button
          className={styles.btn}
          onClick={() => {
            if (this.props.profile) {
              this.vm.redeemRaffleTicket();
            } else {
              this.vm.showModal();
            }
          }}
        >
          我要抽獎
          <RightCircleFilled />
        </Button>

        {this.props.profile && (
          <div className={styles.ticket}>{`（已投入 ${this.vm.raffleTicketCount} 張抽獎券）`}</div>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Breadcrumb
          items={[
            { title: '最新活動', link: Router.Client.EventList },
            { title: '活動詳情' }
          ]}
        />

        {this.vm.isPageLoading
          ? <Loading />
          : (
            <div className={styles.main}>
              <div className={styles.box}>

                <div className={styles.leftCol}>
                  <div className={styles.headerBox}>{this.renderHeader()}</div>
                  <PictureSet url={this.vm.raffleEvent?.images ?? []} isImage3to2 />
                </div>

                <div className={styles.rightCol}>
                  <div className={styles.headerBox}>{this.renderHeader()}</div>

                  <div>
                    {
                  this.vm.raffleEvent && (
                    <Suspense>
                      <Editor
                        editable={false}
                        onChange={() => {}}
                        initialEditorState={this.vm.raffleEvent.editorContent}
                      />
                    </Suspense>
                  )
                }
                  </div>

                  <div className={styles.footerWrapper}>
                    <div className={styles.footerContent}>
                      {this.renderFooter()}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )}

        <MemberOnlyModal
          open={this.vm.isModalOpen}
          onClose={this.vm.closeModal}
        />
      </>
    );
  }
}

RaffleEvent.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object
};

export default withRouter(withProfile(RaffleEvent, false));
