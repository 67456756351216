import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import NewsService from 'src/services/news';
import RaffleService from 'src/services/raffle';
import infoStore from 'src/stores/infoStore';

export default class raffleEventsVM {
  @observable activeTab = 'news';
  @observable isPageLoading = false;
  @observable news = {
    list: [],
    anchor: null,
    limit: 20,
    isLoading: false
  };

  @observable raffleEvent = {
    list: [],
    anchor: null,
    limit: 20,
    isLoading: false
  };

  constructor() {
    makeObservable(this);
  }

  didMount = async (router) => {
    try {
      runInAction(() => {
        this.isPageLoading = true;
        this.news.isLoading = true;
        this.raffleEvent.isLoading = true;

        const tab = router.searchParams[0].get('tab');
        this.activeTab = ['news', 'campaigns'].includes(tab) ? tab : 'news';
      });

      const [news, raffleEvent] = await Promise.all([
        NewsService.getList({ limit: this.news.limit }),
        RaffleService.getList({ limit: this.raffleEvent.limit })
      ]);

      runInAction(() => {
        this.isPageLoading = false;
        this.news.isLoading = false;
        this.raffleEvent.isLoading = false;

        this.news.list = news.list;
        this.news.anchor = news.anchor;
        this.raffleEvent.list = raffleEvent.list;
        this.raffleEvent.anchor = raffleEvent.anchor;
      });

    } catch {
      runInAction(() => {
        this.isPageLoading = false;
        this.news.isLoading = false;
        this.raffleEvent.isLoading = false;
      });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };


  onNewsNextPage = async () => {
    if (this.news.isLoading || !this.news.anchor) return;

    try {
      runInAction(() => { this.news.isLoading = true; });

      const news = await NewsService.getList({
        limit: this.news.limit,
        anchor: this.news.anchor
      });

      runInAction(() => {
        this.news.isLoading = false;
        this.news.list = this.news.list.concat(news.list);
        this.news.anchor = news.anchor;
      });

    } catch {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }

  };


  onRaffleEventNextPage = async () => {
    if (this.raffleEvent.isLoading || !this.raffleEvent.anchor) return;

    try {
      runInAction(() => { this.raffleEvent.isLoading = true; });

      const raffleEvent = await RaffleService.getList({
        limit: this.raffleEvent.limit,
        anchor: this.raffleEvent.anchor
      });

      runInAction(() => {
        this.raffleEvent.isLoading = false;
        this.raffleEvent.list = this.raffleEvent.list.concat(raffleEvent.list);
        this.raffleEvent.anchor = raffleEvent.anchor;
      });

    } catch {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }

  };



  @action onTabChange = (e) => {
    this.activeTab = e;
  };
}
