import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import RaffleService from 'src/services/raffle';
import infoStore from 'src/stores/infoStore';

export default class UserEventVM {
  @observable activeTab = 'event';
  @observable event = {
    list: [],
    anchor: null,
    isLoading: false,
    limit: 40,
    isLoaded: false
  };
  @observable ticket = {
    list: [],
    anchor: null,
    isLoading: false,
    limit: 100,
    isLoaded: false
  };

  constructor() {
    makeObservable(this);
  }

  didMount = async () => {
    this.loadData('event');
  };


  loadData = async (key) => {
    let getter;
    switch (key) {
      case 'ticket':
        getter = RaffleService.getMyTicketRecord;
        break;
      case 'event':
        getter = RaffleService.getMyEventRecord;
        break;
      default:
        console.error('invalid key');
        throw new Error();
    }
    try {
      const result = await getter({ limit: this[key].limit });

      runInAction(() => {
        this[key].list = result.list;
        this[key].anchor = result.anchor;
        this[key].isLoading = false;
        this[key].isLoaded = true;
      });

    } catch (err) {
      infoStore.show({ message: '無法取得資訊', type: 'warn' });
    }
  };


  onTicketNextPage = async () => {
    if (this.ticket.isLoading || !this.ticket.anchor) return;

    try {
      runInAction(() => { this.ticket.isLoading = true; });

      const { list, anchor } = await RaffleService.getMyTicketRecord({
        anchor: this.ticket.anchor,
        limit: this.ticket.limit
      });

      runInAction(() => {
        this.ticket.list = this.ticket.list.concat(list);
        this.ticket.anchor = anchor;
        this.ticket.isLoading = false;
      });

    } catch {
      infoStore.show({ message: '無法取得獲取紀錄', type: 'warn' });
      runInAction(() => { this.ticket.isLoading = false; });
    }
  };

  onEventNextPage = async () => {
    if (this.event.isLoading || !this.event.anchor) return;

    try {
      runInAction(() => { this.event.isLoading = true; });

      const { list, anchor } = await RaffleService.getMyEventRecord({
        anchor: this.event.anchor,
        limit: this.event.limit
      });

      runInAction(() => {
        this.event.list = this.event.list.concat(list);
        this.event.anchor = anchor;
        this.event.isLoading = false;
      });

    } catch {
      runInAction(() => { this.event.isLoading = false; });
      infoStore.show({ message: '無法取得抽獎紀錄', type: 'warn' });
    }
  };


  @action onTabChange = (key) => {
    this.activeTab = key;

    if (!this[key].isLoaded) {
      this.loadData(key);
    }
  };
}
