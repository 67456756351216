import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Collapse } from 'antd';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { AppStateContext } from 'src/stores';
import CompletenessService from 'src/services/completeness';
import { parseDate } from 'src/utils';
import {
  Router,
  ColorationTxt,
  GenderTxt,
  AidsTestTxT,
  VaccineTxt,
  DewormerTxt,
  BehaviorTxt,
  NotificationDeadlineTxt,
  ObservationDurationTxt,
  ObservationFrequencyTxt,
  StabilityFrequencyTxt,
  trialPeriodTxt
} from 'src/constants';
import heartSvg from 'src/assets/heart.svg';
import MemberOnlyModal from 'src/components/MemberOnlyModal';
import ContractPDF from 'src/components/ContractPDF';
import Breadcrumb from 'src/components/Breadcrumb';
import GridTable from 'src/components/GridTable';
import Completeness from 'src/components/Completeness';
import PictureSet from 'src/components/PictureSet';
import Loading from 'src/components/Loading';
import ShareButton from 'src/components/ShareButton';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import FosterPerson from './FosterPerson';
import CatItemVM from './viewModel';
import styles from './styles.module.scss';

@observer
class CatItemPage extends React.Component {
  constructor(props) {
    super(props);
    const fosterId = this.props.router.params.fosterId;
    this.vm = new CatItemVM(fosterId);
  }

  componentDidMount() {
    this.vm.didMount(this.props.router);
  }

  renderBoolean(v, truthy = '是', falsy = '否') {
    const isBoolean = v === true || v === false || v instanceof Boolean;
    if (isBoolean) return v ? truthy : falsy;
    return '-';
  }

  render() {
    const foster = this.vm.foster;
    const isMobile = this.context.state.isMobile;

    return (
      <>
        <Breadcrumb
          items={[
            { title: '貓咪列表', link: Router.Client.CatList },
            { title: foster?.name ?? '' }
          ]}
        />

        {this.vm.isPageLoading
          ? <Loading />
          : (
            <div className={styles.main}>
              <div className={styles.box}>

                <div className={styles.leftCol}>
                  <div className={styles.updateAt}>{`最近更新時間：${parseDate(foster?.updateAt, '-', true)}`}</div>
                  <h1 className={styles.name}>{foster?.name ?? '-'}</h1>
                  <Completeness percentage={this.vm.completeness.all} />
                  <PictureSet url={foster?.images ?? []} />
                  <div style={{ marginBottom: 30 }} />
                </div>

                <div className={styles.rightCol}>
                  <div className={styles.btnBox}>
                    {
                      isMobile ? (
                        <PDFDownloadLink
                          document={<ContractPDF foster={foster} profile={this.props.profile} />}
                          fileName="出認養合約.pdf"
                        >
                          <Button className={styles.btn1}>查看認養合約</Button>
                        </PDFDownloadLink>
                      ) : (
                        <Link to={`/preview/${this.vm.fosterId}`} target="_blank">
                          <Button className={styles.btn1}>查看認養合約</Button>
                        </Link>
                      )
                    }
                    {
                      foster?.inContract ? (
                        <div className={styles.inContractBtn}>簽約中，無法申請認養！</div>
                      ) : (
                        <Button
                          className={styles.btn2}
                          onClick={() => {
                            if (this.props.profile) {
                              this.props.router.navigate(`/cats/${this.vm.fosterId}/apply`);
                            } else {
                              this.vm.showModal();
                            }
                          }}
                        >
                          <img src={heartSvg} alt="" style={{ marginRight: 5 }} />
                          我想認養
                        </Button>
                      )
                    }
                    <ShareButton path={`/cats/${this.vm.fosterId}`} />
                  </div>
                  <Collapse
                    className={styles.collapse}
                    defaultActiveKey={isMobile ? undefined : ['1']}
                    ghost
                    items={[{
                      key: '1',
                      label: <div className={styles.label}>{foster?.title ?? '無標題'}</div>,
                      extra: (
                        <div
                          className={styles.extra}
                          style={{ color: CompletenessService.getColor(this.vm.completeness.partOne) }}
                        >
                          {`完整度 ${this.vm.completeness.partOne}%`}
                        </div>),
                      children: (
                        <div>
                          <GridTable
                            shortList={[
                              { key: '性別', value: GenderTxt[foster?.gender] ?? '-' },
                              { key: '可能出生日期', value: parseDate(foster?.birthday, '-', true) }
                            ]}
                            longList={[
                              { key: '花色', value: foster?.coloration.map((c) => ColorationTxt[c]).join(', ') },
                              { key: '簡介', value: foster?.introduction ?? '-' }
                            ]}
                          />
                        </div>
                      )
                    }]}
                  />
                  <Collapse
                    className={styles.collapse}
                    defaultActiveKey={isMobile ? undefined : ['1']}
                    ghost
                    items={[{
                      key: '1',
                      label: <div className={styles.label}>檢疫資料</div>,
                      extra: (
                        <div
                          className={styles.extra}
                          style={{ color: CompletenessService.getColor(this.vm.completeness.partTwo) }}
                        >
                          {`完整度 ${this.vm.completeness.partTwo}%`}
                        </div>
                      ),
                      children: (
                        <div>
                          <GridTable
                            shortList={[
                              { key: '晶片號碼', value: foster?.chipNumber ?? '-' },
                              { key: '是否結紮', value: this.renderBoolean(foster?.isNeutered) },
                              { key: '愛滋白血\n二合一快篩', value: AidsTestTxT[foster?.AIDStest] ?? '-' },
                              { key: '預防針第一劑', value: VaccineTxt[foster?.firstVaccine] ?? '-' },
                              { key: '預防針第二劑', value: VaccineTxt[foster?.secondVaccine] ?? '-' },
                              { key: '狂犬疫苗', value: this.renderBoolean(foster?.rabiesVaccine, '有施打', '未施打') },
                              { key: '基本血檢', value: this.renderBoolean(foster?.hasBloodTest, '有做血檢', '沒有做') },
                              { key: '驅蟲蚤第一次', value: DewormerTxt[foster?.firstDewormer] ?? '-' }
                            ]}
                            longList={[
                              { key: '驅蟲蚤第二次', value: DewormerTxt[foster?.secondDewormer] ?? '-' },
                              { key: '行為評估', value: foster?.behaviorAssessment?.map((v) => <div className={styles.tag}>{BehaviorTxt[v]}</div>) },
                              { key: '動物健康狀態', value: foster?.healthCondition ?? '-' }
                            ]}
                          />
                        </div>
                      )
                    }]}
                  />
                  <Collapse
                    className={styles.collapse}
                    defaultActiveKey={isMobile ? undefined : ['1']}
                    ghost
                    items={[{
                      key: '1',
                      label: <div className={styles.label}>出養條件</div>,
                      extra: (
                        <div
                          className={styles.extra}
                          style={{ color: CompletenessService.getColor(this.vm.completeness.partThree) }}
                        >
                          {`完整度 ${this.vm.completeness.partThree}%`}
                        </div>
                      ),
                      children: (
                        <div>
                          <GridTable
                            shortList={[
                              { key: '可否再送養', value: this.renderBoolean(foster?.canRehome, '可', '不可') },
                              { key: '死亡或遺失\n是否需通知送養人', value: NotificationDeadlineTxt[foster?.deathOrLossNotification] ?? '-' },
                              { key: '死亡或遺失\n是否需付無限責任', value: this.renderBoolean(foster?.deathOrLossUnlimitedLiability) },
                              { key: '適應期', value: trialPeriodTxt[foster?.trialPeriod] },
                              { key: '結紮時間限定', value: foster?.needNeutering ? parseDate(foster?.neuteringDeadLine, '-', true) : '不限定' },
                              { key: '是否需要家訪', value: this.renderBoolean(foster?.needHomeVisit, '需要', '不需') },
                              !foster?.needReport ? { key: '門窗防護', value: this.renderBoolean(foster?.needWindowSecurity, '需要', '不需') } : null,
                              !foster?.needReport ? { key: '幸福回報', value: '不需' } : null
                            ].filter((e) => !!e)}
                            longList={foster?.needReport ? [
                              { key: '門窗防護', value: this.renderBoolean(foster?.needWindowSecurity, '需要', '不需') },
                              { key: '幸福回報',
                                value: (
                                  <div className={styles.report}>
                                    <div>
                                      <span className={styles.tag}>觀察期時長</span>
                                      <span className={styles.tagValue}>{ObservationDurationTxt[foster?.observationDuration] ?? '-'}</span>
                                    </div>
                                    <div>
                                      <span className={styles.tag}>觀察期回報頻率</span>
                                      <span className={styles.tagValue}>{ObservationFrequencyTxt[foster?.observationFrequency] ?? '-'}</span>
                                    </div>
                                    <div>
                                      <span className={styles.tag}>穩定期回報頻率</span>
                                      <span className={styles.tagValue}>{StabilityFrequencyTxt[foster?.stabilityFrequency] ?? '-'}</span>
                                    </div>
                                  </div>
                                )
                              }
                            ] : undefined}
                          />
                        </div>
                      )
                    }]}
                  />
                  <FosterPerson
                    info={this.vm.fosterPerson}
                    fosterId={this.vm.fosterId}
                  />
                </div>

              </div>


            </div>
          )}

        <MemberOnlyModal
          open={this.vm.isModalOpen}
          onClose={this.vm.closeModal}
        />
      </>
    );
  }
}

CatItemPage.contextType = AppStateContext;

CatItemPage.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object
};

export default withRouter(withProfile(CatItemPage, false));
