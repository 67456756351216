import React, { lazy, Suspense } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import withProfile from 'src/components/withProfile';
import FaqViewModel from './viewModel';

import styles from './styles.module.scss';

const Editor = lazy(() => import('src/components/Editor'));

@observer
class FAQ extends React.Component {
  constructor() {
    super();
    this.vm = new FaqViewModel();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.headerBox}>
          <div className={styles.header}>常見問題</div>
          <Button
            type="primary"
            onClick={this.vm.onSave}
            loading={this.vm.isSaving}
          >
            <SaveOutlined />
            儲存
          </Button>
        </div>

        {this.vm.isLoaded && (
          <div className={cn(styles.editorBox, 'editor-shell')}>
            <Suspense>
              <Editor
                initialEditorState={this.vm.content}
                onChange={this.vm.onEditorChange}
                height="calc(100vh - 130px)"
              />
            </Suspense>
          </div>
        ) }
      </div>
    );
  }
}

FAQ.propTypes = {

};

export default withProfile(FAQ, true, true);
