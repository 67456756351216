import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styles from './styles.module.scss';

export default function NoPermission(props) {
  return (
    <div className={styles.main}>
      <div className={styles.noPermission}>
        <div>Oops! 您沒有權限訪問此頁面，若有疑問請洽平臺客服</div>
        <Button className={styles.btn} onClick={props.callback}>
          我知道了
        </Button>
      </div>
    </div>
  );
}

NoPermission.propTypes = {
  callback: PropTypes.func.isRequired
};
