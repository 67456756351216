import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import RaffleService from 'src/services/raffle';
import infoStore from 'src/stores/infoStore';
import { clientConfirmStore } from 'src/stores/confirmStore';
import UserService from 'src/services/user';

export default class RaffleEventVM {
  raffleEventId;
  @observable profile;
  @observable isModalOpen = false;
  @observable isPageLoading = false;
  @observable raffleEvent;
  @observable raffleTicketCount = 0;

  constructor(raffleEventId) {
    makeObservable(this);
    this.raffleEventId = raffleEventId;
  }

  didMount = async () => {
    try {
      runInAction(() => { this.isPageLoading = true; });

      const raffleEvent = await RaffleService.getOne(this.raffleEventId);

      runInAction(() => {
        this.raffleEvent = raffleEvent;
        this.isPageLoading = false;
      });

    } catch {
      runInAction(() => { this.isPageLoading = false; });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };

  bindProfile = async (profile) => {

    if (profile && !this.profile) {
      try {
        runInAction(() => { this.isPageLoading = true; });

        const redeemTicketCount = await RaffleService.getMyRedeemTicketCountByEvent(this.raffleEventId);

        runInAction(() => {
          this.profile = profile;
          this.raffleTicketCount = redeemTicketCount;
          this.isPageLoading = false;
        });

      } catch (err) {
        runInAction(() => { this.isPageLoading = false; });
      }
    }
  };

  redeemRaffleTicket = async () => {
    if (this.profile.ticketCount === 0) {
      infoStore.show({ message: '您尚無抽獎券，無法兌換抽獎資格。於本平台認養貓咪，進行幸福回報即可取得抽獎券！', type: 'warn' });
      return;
    }

    clientConfirmStore.show({
      title: '確定要投入抽獎券嗎？',
      message: `您尚有${this.profile?.ticketCount ?? '-'}張抽獎券，使用1張兌換此活動抽獎資格嗎？`,
      callBack: async () => {
        try {
          await RaffleService.redeem(this.raffleEventId);

          runInAction(() => { this.raffleTicketCount += 1; });

          infoStore.show({
            message: '抽獎券已投入獎勵活動'
          });

          const profile = await UserService.profile();
          runInAction(() => { this.profile = profile; });

        } catch (err) {
          let message = '';
          switch (err?.response.status) {
            case 404:
              message = '抽獎活動不存在';
              break;
            case 406:
              message = '您尚無抽獎券，無法兌換抽獎資格。於本平台認養貓咪，進行幸福回報即可取得抽獎券！';
              break;
            case 409:
              message = '抽獎活動不開放參加抽獎';
              break;
            default:
              message = '發生錯誤，無法參加抽獎';
          }
          infoStore.show({ message, type: 'warn' });
        }
      }
    });
  };

  @action showModal = () => {
    this.isModalOpen = true;
  };
  @action closeModal = () => {
    this.isModalOpen = false;
  };
}
