import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Dropdown, Button, Form } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import NoPermission from 'src/components/NoPermission';
import Completeness from 'src/components/Completeness';
import TabBar from 'src/components/TabBar';
import CatPaw from 'src/components/CatPaw';
import { Router, FosterStatus, FosterStatusTxt } from 'src/constants';
import { parseDate } from 'src/utils';
import FosterItemVM from './viewModel';
import styles from './styles.module.scss';
import InfoTab from './components/InfoTab';
import ContractTab from './components/ContractTab';
import ApplicationTab from './components/ApplicationTab';


@observer
class FosterItemPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new FosterItemVM(this.props.profile);
  }

  componentDidMount() {
    const fosterId = this.props.router.params.fosterId;
    this.vm.didMount({
      fosterId,
      form: this.props.form
    });
  }

  render() {
    if (this.vm.isPageLoading || !this.vm.hasPermission) {
      return (
        <>
          {this.vm.isPageLoading && <Loading />}
          {!this.vm.isPageLoading && <NoPermission callback={() => this.props.router.navigate(Router.Client.Home)} />}
        </>
      );
    }

    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '出養管理', link: Router.Client.FosterList },
            { title: '出養案件管理' }
          ]}
        />

        <div className={styles.main}>
          <div className={styles.box}>

            <div className={styles.leftCol}>
              <div className={styles.imgBox}>
                {this.vm.foster?.images?.[0]
                  ? <img className={styles.img} src={this.vm.foster?.images?.[0]} alt="cat" />
                  : <CatPaw />}
              </div>

              <div className={styles.completeness}>
                <Completeness percentage={this.vm.completeness.all} />
              </div>

              <div className={styles.meta}>
                <div className={styles.name}>{this.vm.foster?.name}</div>
                <div>{`認養申請：${this.vm.foster?.applicationCount ?? '0'}筆`}</div>
                <div>{`案件編號：${this.vm.foster?.serialNumber ?? ''}`}</div>
                <div className={styles.primary}>{`最近更新時間：${parseDate(this.vm.foster?.updateAt, '-', true)}`}</div>

                {
                  this.vm.isEnd ? (
                    <div className={styles.btn} style={{ boxSizing: 'border-box' }}>
                      {FosterStatusTxt[this.vm.foster?.status] ?? FosterStatusTxt.Unavailable}
                    </div>
                  ) : null
                }

                {
                  !this.vm.isEnd && this.vm.activeTab === 'info' ? (
                    <Dropdown
                      placement="bottomLeft"
                      menu={{
                        items: [
                          { key: FosterStatus.Available, label: '上架', onClick: this.vm.updateFosterStatus },
                          { key: FosterStatus.Unavailable, label: '下架', onClick: this.vm.updateFosterStatus }
                        ]
                      }}
                    >
                      <Button className={styles.btn}>
                        {FosterStatusTxt[this.vm.foster?.status] ?? FosterStatusTxt.Unavailable}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  ) : null
                }

              </div>
            </div>

            <div className={styles.rightCol}>
              <TabBar
                activeTab={this.vm.activeTab}
                onChange={this.vm.onTabChange}
                items={[
                  { key: this.vm.tabKey.info, label: '出養資訊' },
                  { key: this.vm.tabKey.contract, label: '出認養合約' },
                  { key: this.vm.tabKey.applications, label: '申請認養列表' }
                ]}
              />

              {this.vm.activeTab === this.vm.tabKey.info && (
              <InfoTab form={this.props.form} vm={this.vm} />
              )}


              {this.vm.activeTab === this.vm.tabKey.contract && (
              <ContractTab vm={this.vm} profile={this.props.profile} />
              )}


              {this.vm.activeTab === this.vm.tabKey.applications && (
              <ApplicationTab vm={this.vm} profile={this.props.profile} />
              )}
            </div>

          </div>
        </div>
      </>
    );
  }
}

FosterItemPage.propTypes = {
  router: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const FosterItemHOC = (props) => {
  const [form] = Form.useForm();

  return (
    <FosterItemPage
      form={form}
      {...props}
    />
  );
};

export default withRouter(withProfile(FosterItemHOC, true));
