/* eslint-disable jsx-a11y/control-has-associated-label */

import { PropTypes } from 'mobx-react';
import { Button } from 'antd';
import cn from 'classnames';

import { ReactComponent as ShareIcon } from 'src/assets/share.svg';

import styles from './styles.module.scss';

function ShareButton(props) {
  const {
    path = '',
    className,
    iconClassName,
    ...ps
  } = props;

  const url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}${path}`;

  return (
    <a
      target="_blank"
      href={url}
      rel="noreferrer"
    >
      <Button
        type="primary"
        size="large"
        {...ps}
        className={cn(styles.button, className)}
        icon={<ShareIcon className={cn(styles.icon, iconClassName)} />}
      />
    </a>
  );
}

ShareButton.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  path: PropTypes.string
};

export default ShareButton;
