import { makeObservable, observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import UserService from 'src/services/user';
import infoStore from 'src/stores/infoStore';
import { Router, VerificationType } from 'src/constants';

export default class ProfileVM {
  form;
  router;
  avatar;
  defaultAvatar;
  @observable isMounted = false;

  constructor(form, router) {
    makeObservable(this);
    this.form = form;
    this.router = router;
  }

  didMount = async () => {
    try {
      const profile = await UserService.profile();
      this.form.setFieldsValue({
        phone: profile.phone,
        name: profile.name,
        idNumber: profile.idNumber,
        nickname: profile.nickname,
        region: profile.region,
        email: profile.email,
        contactNumber: profile.contactNumber ?? '',
        selfIntro: profile.selfIntro ?? ''
      });
      runInAction(() => {
        this.defaultAvatar = profile.avatar
          ? [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: profile.avatar
          }]
          : [];
        this.isMounted = true;
      });

    } catch {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };


  update = async () => {
    try {
      await this.form.validateFields();
    } catch {
      message.open({ type: 'error', content: '必填欄位未填' });
      return;
    }

    try {
      const profile = this.form.getFieldsValue();

      await UserService.updateMyProfile({
        nickname: profile.nickname,
        region: profile.region,
        email: profile.email,
        contactNumber: profile.contactNumber,
        selfIntro: profile.selfIntro,
        avatar: this.avatar
      });
      message.open({ type: 'success', content: '帳號已更新' });

    } catch {
      infoStore.show({ message: '發生錯誤，無法更新帳號資訊', type: 'warn' });
    }
  };

  logout = async () => {
    await UserService.logout();
    this.router.navigate(Router.Auth.Login);
  };

  resetPwd = async () => {
    this.router.navigate(Router.Client.RestPwd);
  };

  @action handleUploadChange = ({ file }) => {
    this.avatar = file.originFileObj;
  };
}
