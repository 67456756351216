import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Input } from 'antd';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import { Router, VerificationType } from 'src/constants';
import LoginVM from './viewModel';

import styles from './styles.module.scss';

@observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new LoginVM(this.props.router, this.props.profile);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate() {
    this.vm.checkProfile(this.props.profile);
  }

  render() {
    return (
      <div className={styles.main}>

        <div className={styles.box}>

          <div className={styles.content}>
            <h1 className={styles.title}>會員登入</h1>

            <div className={styles.row}>
              <div className={styles.field}>手機號碼</div>
              <Input
                placeholder="請輸入手機號碼"
                value={this.vm.phone}
                onChange={this.vm.onPhoneChange}
              />
            </div>

            <div className={styles.row}>
              <div className={styles.field}>密碼</div>
              <Input.Password
                placeholder="請輸入密碼"
                value={this.vm.password}
                onChange={this.vm.onPasswordChange}
              />
            </div>

            <Link
              className={cn(styles.link, styles.alignRight)}
              to={`${Router.Auth.Verify}?type=${VerificationType.RestPwd}`}
            >
              忘記密碼？
            </Link>

            <Button
              className={styles.primaryBtn}
              onClick={this.vm.singin}
            >
              登入

            </Button>
            <Button
              className={styles.secondaryBrn}
              href={`${Router.Auth.Verify}?type=${VerificationType.Register}`}
            >
              我是新朋友，前往註冊

            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object
};

export default withRouter(withProfile(Login, false));
