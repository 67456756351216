import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import UploadWithCrop from 'src/components/UploadWithCrop';
import { Button, Select, Form, Input, Checkbox } from 'antd';

import { passwordValidator } from 'src/utils';
import withRouter from 'src/components/withRouter';
import { TaiwanCountyTxt, VerificationType, Router } from 'src/constants';
import RegisterVM from './viewModel';

import styles from './styles.module.scss';

@observer
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new RegisterVM(this.props.form, this.props.router);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.box}>
          <Form
            form={this.vm.form}
            layout="vertical"
          >
            <Form.Item
              label="手機號碼(未來將作為帳號登入使用)"
              name="phone"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="密碼"
              name="password"
              rules={[
                { required: true, message: '密碼必填' },
                () => ({
                  validator(_, value) {
                    if (value && passwordValidator(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('密碼至少八碼，英文字母及數字至少各一碼'));
                  }
                })
              ]}
            >
              <Input placeholder="請輸入8-16位密碼，須為英文、數字組合" />
            </Form.Item>
            <Form.Item
              label="密碼確認"
              name="passwordConfirm"
              dependencies={['password']}
              rules={[
                { required: true, message: '請再一次輸入密碼' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('與密碼不符'));
                  }
                })
              ]}
            >
              <Input placeholder="請輸入8-16位密碼，須為英文、數字組合" />
            </Form.Item>
            <Form.Item
              label={(
                <div>
                  <div>姓名／組織名稱</div>
                  <div className={styles.extra}>註冊後無法修改，且會顯示於出養／認養合約中，若希望提高出養／認養機率，建議填寫正式名稱。</div>
                </div>
              )}
              name="name"
              rules={[{ required: true, message: '姓名／組織名稱必填' }]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label={(
                <div>
                  <div>身份證字號／護照號碼／統一編號</div>
                  <div className={styles.extra}>註冊後無法修改，請協助填寫正確資料。</div>
                </div>
              )}
              name="idNumber"
              rules={[{ required: true, message: '身份證字號／護照號碼／統一編號必填' }]}
            >
              <Input />
            </Form.Item>
            <div className={styles.fieldGap}>個人大頭貼</div>
            <UploadWithCrop
              listType="picture-card"
              maxCount={1}
              onChange={this.vm.handleUploadChange}
            />
            <div className={styles.formGap} />
            <Form.Item
              label={(
                <div>
                  <div>暱稱</div>
                  <div className={styles.extra}>將顯示在您刊登的出養資料，或者送出認養的申請訊息中。若您並非代表個人送養/認養，可填寫方便大眾辨識的組織/中途名稱。</div>
                </div>
              )}
              name="nickname"
              rules={[{ required: true, message: '暱稱必填' }]}
            >
              <Input placeholder="請輸入暱稱" maxLength={20} />
            </Form.Item>
            <Form.Item
              label="地區"
              name="region"
              rules={[{ required: true, message: '地區必填' }]}
            >
              <Select
                placeholder="請選擇所在地區"
                options={
                Object.keys(TaiwanCountyTxt).map((TaiwanCounty) => (
                  { value: TaiwanCounty, label: TaiwanCountyTxt[TaiwanCounty] }
                ))
                }
              />
            </Form.Item>
            <Form.Item
              label={(
                <div>
                  <div>email</div>
                  <div className={styles.extra}>本平臺具 email 提醒通知功能，請確認 email 正確性，避免遺漏重要訊息。</div>
                </div>
              )}
              name="email"
              rules={[{ required: true, message: 'email必填' }]}
            >
              <Input placeholder="請輸入email" />
            </Form.Item>
            <Form.Item
              label="備用聯絡電話"
              name="contactNumber"
            >
              <Input placeholder="請輸入聯絡電話" />
            </Form.Item>
          </Form>


          <div className={styles.bottom}>
            <Checkbox
              checked={this.vm.isChecked}
              onChange={this.vm.onCheckChange}
            >
              <span className={styles.text}>我已閱讀並同意</span>
              <a className={styles.link} href="/privacy-policy" target="_blank">
                喵加人服務條款
              </a>
            </Checkbox>

            <Button
              className={styles.primaryBtn}
              onClick={this.vm.register}
              loading={this.vm.isLoading}
            >
              完成註冊
            </Button>
          </div>


        </div>
      </div>
    );
  }
}

Register.propTypes = {
  form: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};


const RegisterHOC = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const phone = props.router?.location?.state?.phone ?? null;
    if (!phone) {
      props.router.navigate(`${Router.Auth.Verify}?type=${VerificationType.Register}`);
    }
  }, [props.router]);

  return (
    <Register
      form={form}
      {...props}
    />
  );
};

RegisterHOC.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(RegisterHOC);
