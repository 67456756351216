/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Input, Select } from 'antd';

import CommentInputVM from './viewModel';
import styles from './styles.module.scss';

@observer
class CommentInput extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CommentInputVM(props.onClick);
  }

  cancelFoster = [
    '未依約提供乾淨健康的飲食',
    '未依約提供貓咪適當的醫療',
    '未依約提供貓咪合理的活動空間',
    '未依約定期幸福回報',
    '違反合約規定及其它'
  ];

  cancelAdoption = [
    '無法負擔貓咪的照護費用',
    '個人/家庭因素無法照顧貓咪',
    '與出養人溝通認知有落差',
    '違反合約規定及其它'
  ];

  deathReport = [
    '急性疾病',
    '慢性疾病',
    '腫瘤',
    '猝死',
    '意外',
    '老死',
    '其它'
  ];

  text() {
    switch (this.props.type) {
      case 'cancelFoster':
        return '取消出養';
      case 'cancelAdoption':
        return '取消認養';
      case 'deathReport':
        return '死亡';
      default:
        return '';
    }
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.title}>{`${this.text()}原因`}</div>
        <Select
          placeholder="請選擇"
          className={styles.select}
          onChange={this.vm.onSelect}
          options={this[this.props.type].map((c) => ({ label: c, value: c }))}
        />
        <Input.TextArea
          placeholder={`說明${this.text()}原因`}
          onChange={this.vm.onChange}
          maxLength={100}
          showCount
        />

        <Button className={styles.btn} onClick={this.vm.onClick}>送出</Button>
      </div>
    );
  }
}

CommentInput.propTypes = {
  type: PropTypes.oneOf(['cancelFoster', 'cancelAdoption', 'deathReport']).isRequired,
  onClick: PropTypes.func.isRequired
};

export default CommentInput;
