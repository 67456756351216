import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tabs } from 'antd';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import { Router } from 'src/constants';

import Profile from './components/Profile';
import Application from './components/Application';
import Comment from './components/Comment';
import Message from './components/Message';
import VM from './viewModel';
import styles from './styles.module.scss';

@observer
class UserProfilePage extends React.Component {
  constructor(props) {
    super(props);

    const { userId } = this.props.router.params;
    this.vm = new VM(userId);
  }

  componentDidMount() {

  }

  renderBreadcrumbItem = (item) => {
    return item.url ? (
      <Link to={item.url}>{ item.title }</Link>
    ) : (<span>{ item.title }</span>);
  };

  render() {
    const { userId } = this.props.router.params;

    return (
      <div className={styles.main}>
        <div className={styles.header}>
          <Breadcrumb
            items={[
              { title: '會員列表', url: Router.Backstage.User },
              { title: '會員詳情頁' }
            ]}
            itemRender={this.renderBreadcrumbItem}
          />
          <div className={styles.title}>{this.vm.user?.nickname}</div>
        </div>

        <Tabs
          className={styles.tabs}
          defaultActiveKey="1"
          centered
          items={[
            {
              key: '1',
              label: '會員資料',
              children: <Profile vm={this.vm} />
            },
            {
              key: '2',
              label: '認養申請',
              children: <Application vm={this.vm} />
            },
            {
              key: '3',
              label: '評價',
              children: <Comment vm={this.vm} />
            },
            {
              key: '4',
              label: '留言板',
              children: <Message userId={userId} />
            }
          ]}
        />
      </div>
    );
  }
}

UserProfilePage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(withProfile(UserProfilePage, true, true));
