import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function Cancel(props) {
  return (
    <>
      <div className={styles.header}>{`${props.title}原因`}</div>
      <div className={styles.box}>
        <div className={styles.title}>{props.cancelOption}</div>
        <div className={styles.content}>{props.cancelDescription}</div>
      </div>
    </>
  );
}

Cancel.propTypes = {
  title: PropTypes.string.isRequired,
  cancelOption: PropTypes.string.isRequired,
  cancelDescription: PropTypes.string.isRequired
};

export default Cancel;
