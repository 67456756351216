import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import Breadcrumb from 'src/components/Breadcrumb';
import TabBar from 'src/components/TabBar';
import { Router, FosterStatus, FosterStatusTxt } from 'src/constants';
import FosterListVM from './viewModel';
import TabContent from './TabContent';
import styles from './styles.module.scss';

@observer
class FosterListPage extends React.Component {
  constructor() {
    super();
    this.vm = new FosterListVM();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '出養管理' }
          ]}
        />
        <div className={styles.main}>
          <div className={styles.box}>
            <TabBar
              onChange={this.vm.onTabChange}
              activeTab={this.vm.activeTab}
              items={[
                { key: FosterStatus.Unavailable, label: FosterStatusTxt.Unavailable },
                { key: FosterStatus.Available, label: FosterStatusTxt.Available },
                { key: FosterStatus.Adopted, label: FosterStatusTxt.Adopted }
              ]}
            />

            {
              [
                FosterStatus.Unavailable,
                FosterStatus.Available,
                FosterStatus.Adopted
              ].map((status) => {
                const key = status.toLowerCase();
                return (
                  <div key={status}>
                    {this.vm.activeTab === FosterStatus[status] && (
                      <div style={{ position: 'relative' }}>

                        <div className={styles.searchBox}>
                          <Input
                            placeholder="請輸入貓咪名字"
                            className={styles.search}
                            onChange={(e) => this.vm.onKeywordChange(e, FosterStatus[status])}
                            value={this.vm[key].keyword}
                          />
                          <Button className={styles.searchBtn} onClick={() => this.vm.onSearch(FosterStatus[status])}>搜尋</Button>
                        </div>

                        {this.vm[key].list.length
                          ? <TabContent vm={this.vm} status={FosterStatus[status]} />
                          : (
                            <div className={styles.noCatBox}>
                              <div className={styles.noCat}>{this.vm[key].isLoaded ? '目前沒有資料' : ''}</div>
                            </div>
                          )}

                        {(status === FosterStatus.Unavailable || status === FosterStatus.Available) && (
                        <Button
                          className={styles.floatBtn}
                          onClick={() => this.props.router.navigate(Router.Client.CreateFosterItem)}
                        >
                          刊登出養貓咪
                        </Button>
                        ) }
                      </div>
                    )}
                  </div>
                );
              })
            }
          </div>
        </div>
      </>
    );
  }
}

FosterListPage.propTypes = {
  profile: PropTypes.object,
  router: PropTypes.object.isRequired
};

export default withRouter(withProfile(FosterListPage, true));
