import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';

import { ReactComponent as XIcon } from 'src/assets/x.svg';
import useDidMount from 'src/hooks/useDidMount';

import styles from './styles.module.scss';

const FosterCard = observer((props) => {
  const {
    className,
    foster,
    onClear = () => {}
  } = props;

  useDidMount(foster.fetch);

  const isError = foster.status === 'error';

  const content = (
    <>
      <img
        className={clsx(foster.loading && styles.opacity20)}
        src={foster.data?.images[0]}
        alt=""
      />
      <div className={clsx(styles.info, foster.loading && styles.opacity20)}>
        <div className={clsx(styles.name, isError && styles.failed)}>
          { foster.data?.name?.length ? `貓咪：${foster.data?.name}` : '未知貓咪 ：(' }
        </div>
        <div className={clsx(styles.text, isError && styles.failed)}>
          { isError ? '無法取得相關資訊' : '查看出養詳情 >' }
        </div>
      </div>
      {
        foster.clearable && (
          <Button
            className={styles.clear}
            onClick={onClear}
            icon={<XIcon />}
            ghost
          />
        )
      }
      <Spin
        className={styles.spin}
        spinning={foster.loading}
      />
    </>
  );

  return foster.linkable && foster.status === 'done' ? (
    <Link
      className={clsx(styles.card, styles.link, foster.clearable && styles.clearable, className)}
      to={`/cats/${foster.id}`}
    >
      { content }
    </Link>
  ) : (
    <div className={clsx(styles.card, foster.clearable && styles.clearable, className)}>
      { content }
    </div>
  );
});

FosterCard.propTypes = {
  className: PropTypes.string,
  foster: PropTypes.object,
  onClear: PropTypes.func
};

export default FosterCard;
