import { request, getHost } from './api';
import AuthService from './auth';

export default class UserService {

  /**
   * [C] 登入
   */
  static async login(phone, password) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/login`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      data: {
        phone,
        password
      }
    };
    const res = await request(options);
    AuthService.setToken(res.data);
  }

  /**
   * [C] 登出
   */
  static async logout() {
    AuthService.removeToken();
  }

  static async profile() {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user/profile`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [B]
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   */
  static async getList(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user/list`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C]
   * @param {Object} data
   * @param {string} data.nickname
   * @param {string} data.region
   * @param {string} data.email
   * @param {string} data.contactNumber
   * @param {string} data.selfIntro
   * @param {string} data.avatar - file
   */
  static async updateMyProfile(data) {
    const formData = new FormData();
    formData.append('nickname', data.nickname);
    formData.append('region', data.region);
    formData.append('email', data.email);
    formData.append('contactNumber', data.contactNumber);
    formData.append('selfIntro', data.selfIntro);
    formData.append('avatar', data.avatar);

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/profile`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: formData
    };

    const res = await request(options);
    return res.data;
  }

  /**
   * [B]
   * @param {Object} data
   * @param {boolean} data.isAdmin
   * @param {string} data.name
   * @param {string} data.nickname
   * @param {string} data.region
   * @param {string} data.email
   * @param {string} data.contactNumber
   * @param {string} data.idNumber
   * @param {string} data.selfIntro
   */
  static async updateUserProfile(userId, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/${userId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }


  /**
   * [B, C]
   */
  static async getUserById(userId, isIncludeIdNumber) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user/${userId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        isIncludeIdNumber
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 取得申請認養人 profile + application count by status
   */
  static async getApplicant(userId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user/${userId}/applicant`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 取得驗證碼
   */
  static async getVerificationCode(phone, type) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/get-verification-code`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      data: {
        phone,
        type
      }
    };
    return request(options);
  }


  /**
   * [C] 驗證驗證碼
   */
  static async verifySmsCode(phone, type, code) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/verify`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      data: {
        phone,
        type,
        code
      }
    };
    const res = await request(options);
    return res.data;
  }


  /**
   * [C] 重設密碼(未登入，簡訊認證)
   */
  static async resetPasswordWithSms(token, password) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/reset-pwd-sms`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      data: {
        token,
        password
      }
    };
    return request(options);
  }

  /**
   * [C] 重設密碼(已登入)
   */
  static async resetPassword(password) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/reset-pwd`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        password
      }
    };
    return request(options);
  }


  /**
   * [C] 註冊
   */
  static async register(req) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/register`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache'
      },
      data: req
    };
    const res = await request(options);
    return res.data;
  }
}
