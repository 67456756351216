import { message } from 'antd';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import EditorContentService from 'src/services/editorContent';
import errorStore from 'src/stores/errorStore';
import { getEditorContent } from 'src/utils';
import { EditorContentType } from 'src/constants';

export default class HelpViewModel {
  type = EditorContentType.Help;
  content = null;
  @observable isLoaded = false;
  @observable isSaving = false;

  constructor() {
    makeObservable(this);
  }

  didMount = async () => {
    try {
      const content = await EditorContentService.get(this.type);
      this.content = content || undefined;
      runInAction(() => { this.isLoaded = true; });
    } catch {
      errorStore.show('發生錯誤，無法取得資訊');
    }
  };

  onEditorChange = (state) => {
    const { plainTextContent, editorContent } = getEditorContent(state);
    this.content = editorContent;
  };

  onSave = async () => {
    try {
      runInAction(() => { this.isSaving = true; });
      await EditorContentService.update(this.type, this.content);

      message.open({
        type: 'success',
        content: '幫助中心已更新'
      });
    } catch {
      errorStore.show('發生錯誤，無法更新幫助中心');
    } finally {
      runInAction(() => { this.isSaving = false; });
    }
  };
}
